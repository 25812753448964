import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { modes } from '@/utils/TModes';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';
import { namespace } from '@/store/index.definition';
import { Router } from '@/utils/router';

const ModalCallUp = () => import(/* webpackChunkName: "shoppingBag" */ './modals/ShoppingBagCallUp.vue');
const ModalCustomer = () => import(/* webpackChunkName: "shoppingBag" */ './modals/CustomerAdd.vue');
const ModalSalesEdit = () => import(/* webpackChunkName: "shoppingBag" */ './modals/SalesEdit.vue');
const ModalSendMail = () => import(/* webpackChunkName: "shoppingBag" */ './modals/SendMail.vue');
const ModalPayment = () => import(/* webpackChunkName: "shoppingBag" */ './modals/Payment.vue');
const Show = () => import(/* webpackChunkName: "shoppingBag" */ './ShoppingBagShow.vue');

/* MODALS */
export function shoppingBagSendMail(locale): IRouteConfig {
  return {
    component: ModalSendMail,
    meta: {
      label: String(i18n(locale).tc('common.property.send')),
      module: namespace.shoppingBag,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: 'shoppingBagSendMail',
    path: ':shoppingBagId/send-mail',
    props: (route) => ({
      shoppingBagId: route.params.shoppingBagId,
      routeBack: {
        name: 'shoppingBagShow',
      },
      showModal: true,
    }),
  };
};

export function customerRoute(locale): IRouteConfig {
  return {
    component: ModalCustomer,
    meta: {
      label: String(i18n(locale).tc('shoppingBag.property.selectCustomer')),
      module: namespace.shoppingBag,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: 'selectCustomer',
    path: 'select-customer',
    props: (route) => ({
      shoppingBagId: route.query.shoppingBagId,
      routeBack: {
        name: 'shoppingBagShow',
      },
      showModal: true,
    }),
  };
};

export function paymentRoute(locale): IRouteConfig {
  return {
    component: ModalPayment,
    meta: {
      label: String(i18n(locale).tc('shoppingBag.action.pay')),
      module: namespace.shoppingBag,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: 'payment',
    path: 'payment',
    props: (route) => ({
      shoppingBagId: route.query.shoppingBagId,
      routeBack: {
        name: 'shoppingBagShow',
      },
      showModal: true,
    }),
  };
};

export function shoppingBagCallUpRouteEdit(locale): IRouteConfig {
  return {
    component: ModalCallUp,
    meta: {
      label: `${i18n(locale).tc('sales.property.callUp')} ${i18n(locale).tc('shoppingBag.model', 1)}`,
      module: namespace.shoppingBag,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: 'shoppingBagCallUp',
    path: 'call-up',
    props: (route) => ({
      routeBack: {
        name: 'shoppingBagShow',
      },
      shoppingBagId: (route.query.shoppingBagId) ? route.query.shoppingBagId : '',
      showModal: true,
    }),
  };
};

export function shoppingBagSalesRouteEdit(locale): IRouteConfig {
  return {
    component: ModalSalesEdit,
    meta: {
      label: String(i18n(locale).tc('product.model', 1)),
      module: namespace.shoppingBag,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `shoppingBagSales${modes.EDIT}`,
    path: 'edit-sale/:productId',
    props: (route) => ({
      ID: route.params.productId,
      mode: modes.EDIT,
      routeBack: {
        name: 'shoppingBagShow',
      },
      shoppingBagId: route.params.shoppingBagId,
      showModal: true,
    }),
  };
};

export function shoppingBagSalesRouteNew(locale): IRouteConfig {
  return {
    beforeEnter: async(to, from, next) => {
      const product = MProduct.find(to.params.productId);

      to.params.ID = await MSales._create(
        to.params.shoppingBagId,
        {
          Quantity: '1',
          Discount: '0',
          ProductID: to.params.productId,
          Price: product.Price,
          SinglePrice: product.Price,
        },
      );

      next();
    },
    component: ModalSalesEdit,
    meta: {
      label: String(i18n(locale).tc('shoppingBag.model', 1)),
      module: namespace.shoppingBag,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `shoppingBagSales${modes.NEW}`,
    path: 'new-sale',
    props: (route) => ({
      ID: route.params.ID,
      mode: modes.NEW,
      routeBack: {
        name: 'shoppingBagShow',
      },
      showModal: true,
    }),
  };
};

/* PAGES */
export function shoppingBagRouteShow(locale): IRouteConfig {
  return {
    children: [
      shoppingBagSendMail(locale),
      customerRoute(locale),
      paymentRoute(locale),
      shoppingBagSalesRouteNew(locale),
      shoppingBagSalesRouteEdit(locale),
      shoppingBagCallUpRouteEdit(locale),
    ],
    component: Show,
    icon: {
      iconName: 'cash-desk',
      iconFamily: '',
    },
    meta: {
      label: String(i18n(locale).tc('shoppingBag.cashDesk', 1)),
      navItem: 'primary',
    },
    name: 'shoppingBagShow',
    navItem: true,
    path: '/shopping-bag',
    props: (route) => ({
      shoppingBagId: (route.query.shoppingBagId) ? route.query.shoppingBagId : '',
      showModal: false,
    }),
  };
};
