import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { i18n } from '@/locales/i18n';
import { ITax } from '@/typings/interface/ITax';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';
import { utIsEmpty } from '@/utils/empty';

const locale = localStorage.getItem('locale');

export class MTax extends Model implements ITax {
  static entity: TModel = MODEL.tax;

  static primaryKey = 'Id';

  Id: string;

  Disabled: boolean;

  Name: string;

  Percent: string;

  get asListData() {
    return {
      name: this.Name,
      actions: this.Id,
      percent: this.Percent,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      Disabled: this.boolean(false),

      Name: this.string(String(i18n(locale).t('common.property.untitled'))),

      Percent: this.string('0'),
    };
  }

  static async _create(): Promise<string> {
    const emptyTax = MTax.hydrate();

    const { response: { data: response } } = await MTax.api().post(
      Api.ENDPOINT.tax,
      emptyTax,
      {
        save: false,
      },
    );

    MTax.insert({
      data: {
        ...emptyTax,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    MTax.delete(Id);

    MTax.api().delete(
      `${Api.ENDPOINT.tax}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MTax.api().get(
      Api.ENDPOINT.tax, {
        dataTransformer: (response) => {
          response.data.forEach(tax => {
            if (utIsEmpty(tax.PartitionKey)) {
              tax.Disabled = true;
            }
          });

          return response.data;
        },
      },
    );
  };

  static _update(tax): void {
    MTax.api().put(
      `${Api.ENDPOINT.tax}/${encodeURIComponent(tax.Id)}`,
      tax.$toJson(),
      {
        save: false,
      },
    );
  };
}
