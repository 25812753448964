import Vue from 'vue';

const EventBus = new Vue();

export namespace EventBusEvent {
  export enum CALL {
    update = 'EVENTBUS__CALL__UPDATEING',
    updateEnd = 'EVENTBUS__CALL__UPDATEING_ENDED',
  }

  export enum CONTEXTMENU {
    actionClicked = 'EVENTBUS__CONTEXTMENU__ACTION_CLICKED',
    setCustomContent = 'EVENTBUS__CONTEXTMENU__SET_CUSTOM_CONTENT',
    setIsVisible = 'EVENTBUS__CONTEXTMENU__SET_IS_VISIBLE',
    update = 'EVENTBUS__CONTEXTMENU__UPDATE',
    useDefaultContent = 'EVENTBUS__CONTEXTMENU__USE_DEFAULT_CONTENT',
    visibilityChanged = 'EVENTBUS__CONTEXTMENU__VISIBILITY_CHANGED',
  }

  export enum GRID {
    checkboxClicked = 'EVENTBUS__GRID__CHECKBOX_CLICKED',
    minus = 'EVENTBUS__GRID__MINUS_QUANTITY',
    plus = 'EVENTBUS__GRID__PLUS_QUANTITY',
    radioButtonClicked = 'EVENTBUS__GRID__RADIOBUTTON_CLICKED',
    remove = 'EVENTBUS__GRID__REMOVE_SALE'
  }

  export enum HTTP {
    abort = 'EVENTBUS__HTTP__ABORT',
    error = 'EVENTBUS__HTTP__ERROR',
    info = 'EVENTBUS__HTTP__INFO',
  }

  export enum MODAL {
    closed = 'EVENTBUS__MODAL__CLOSED',
    opened = 'EVENTBUS__MODAL__OPENED',
  }

  export enum MODAL_ACTION {
    delete = 'EVENTBUS__MODAL_ACTION__DELETE',
  }

  export enum NOTIFICATION {
    sent = 'EVENTBUS__NOTIFICATION__SENT',
  }

  export enum NAVIGATION {
    beforeNavigate = 'EVENTBUS__NAVIGATION__BEFORE_NAVIGATE',
  }

  export enum SALES {
    expanded = 'EVENTBUS__SALES__EXPANDED',
    notExpanded = 'EVENTBUS__SALES__NOTEXPANDED',
  }
}

export { EventBus };
