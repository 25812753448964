import { Route } from 'vue-router';

export const mixinModal = {
  props: {
    routeBack: {
      type: Object,

      default: function() {
        return {
          name: '',
        } as Route;
      },
    },

    showModal: {
      type: Boolean,
      default: false,
    },
  },
};
