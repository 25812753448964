import logger from '@/utils/logger';

const loadImage = (element: Element) => {
  const imageElement: HTMLImageElement = Array
    .from(element.children)
    .find(
      element => element.nodeName === 'IMG',
    ) as HTMLImageElement;

  if (imageElement) {
    imageElement.addEventListener(
      'load',
      () => {
        element.setAttribute('is-loaded', 'true');
      },
    );

    imageElement.addEventListener(
      'error',
      () => {
        logger.warn('Image Load Warning');
      },
    );

    imageElement.src = imageElement.dataset.url;

    imageElement.alt = imageElement.dataset.alt;
  }
};

export const directiveLazyLoad = {
  inserted: (element: Element) => {
    const handleIntersect = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage(element);

          observer.unobserve(element);
        }
      });
    };

    const createObserver = () => {
      const options: IntersectionObserverInit = {
        root: null,
        threshold: 0.0,
      };

      const observer = new IntersectionObserver(handleIntersect, options);

      observer.observe(element);
    };

    if (window.IntersectionObserver) {
      createObserver();
    } else {
      loadImage(element);
    }
  },

  componentUpdated: (element: Element) => {
    loadImage(element);
  },
};
