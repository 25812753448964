import axios from 'axios';
import { EventBus, EventBusEvent } from '@/utils/eventBus';

const http = axios.create({
  baseURL: process.env.VUE_APP_WEB_API_URL,
});

export enum HTTP {
  OK = 200,
  NO_CONTENT = 204,
  NOT_AUTHORIZED = 401,
  NOT_FOUND = 404,
  NOT_ACCEPTABLE = 406,
  CONFLICT = 409,
  PRECONDITION_FAILED = 412,
  UNPROCESSABLE_ENTITY = 422,
  INTERNAL_SERVER_ERROR = 500,
}

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (HTTP.NOT_AUTHORIZED === error.response.status) {
      localStorage.removeItem('farmin_token');

      localStorage.removeItem('farmin_refresh_token');

      window.location.href = '/';
    }

    if (HTTP.UNPROCESSABLE_ENTITY === error.response.status) {
      EventBus.$emit(EventBusEvent.HTTP.abort);

      return Promise.reject(error);
    }

    EventBus.$emit(EventBusEvent.HTTP.error, error);

    return Promise.reject(error);
  },
);

export { http };
