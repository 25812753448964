import {
  isDate,
  isEmpty,
  isFunction,
  isNil,
  isNumber,
  isObject,
  isString,
  isSymbol,
} from 'lodash';

const utIsEmpty = (value: any): boolean => {
  if (isNil(value)) { // nil = undefined | null
    return true;
  }

  if (isNumber(value)) { // NaN is of type Number
    return false;
  }

  if (isString(value)) {
    if (value.length === 0) {
      return true;
    }
  }

  if (isDate(value)) {
    return false;
  }

  if (isSymbol(value)) {
    return false;
  }

  if (isFunction(value)) {
    return false;
  }

  if (isObject(value)) {
    if (isEmpty(value)) {
      return true;
    } else {
      let empty = true;

      for (const key of Object.keys(value)) {
        if (!utIsEmpty(value[key])) {
          empty = false;

          break;
        }
      }

      return empty;
    }
  }

  return false;
};

export { utIsEmpty };
