import Vue from 'vue';
import VueRouter from 'vue-router';
import { EventBusEvent } from '@/utils/eventBus';
import { http } from '@/http';
import { routeList } from '@/routes';
import { Router } from '@/utils/router';
import { scrollContentToTop } from '@/utils/scrolling';

Vue.use(VueRouter);

let entryUrl = null;

// Workaround to remove NavigationDuplicated error
// https://github.com/vuejs/vue-router/issues/2881
const originalPush = VueRouter.prototype.push;

// @ts-ignore
VueRouter.prototype.push = function push(
  location: any,
  onResolve: any,
  onReject: any,
): Promise<any> {
  if (onResolve || onReject) {
    return originalPush
      .call(
        this,
        location,
        onResolve,
        onReject,
      );
  }

  return originalPush
    .call(
      this,
      location,
    ).catch(
      exception => exception,
    );
};

const router = (locale) => {
  const router = new VueRouter({
    mode: 'history',
    routes: routeList(locale),
  });

  router.beforeEach(async(to, from, next) => {
    Vue.prototype.$bus.$emit(EventBusEvent.NAVIGATION.beforeNavigate);

    if (from.meta.module !== to.meta.module) {
      scrollContentToTop();
    } else {
      if (from.meta.type !== to.meta.type) {
        if (
          from.meta.type !== Router.ROUTE_TYPE.MODAL &&
          to.meta.type !== Router.ROUTE_TYPE.MODAL
        ) {
          scrollContentToTop();
        }
      } else {
        if (
          from.meta.type !== Router.ROUTE_TYPE.TAB &&
          to.meta.type !== Router.ROUTE_TYPE.TAB
        ) {
          scrollContentToTop();
        }
      }
    }

    if (entryUrl) {
      const url = entryUrl;

      entryUrl = null;

      return next(url);
    } else {
      return next();
    }
  });

  return router;
};

export { router };
