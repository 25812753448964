import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { i18n } from '@/locales/i18n';
import { IAccountNumber } from '@/typings/interface/IAccountNumber';
import { MODEL, TModel } from '@/utils/models';
import { Model } from '@vuex-orm/core';

const locale = localStorage.getItem('locale');

export class MAccountNumber extends Model implements IAccountNumber {
  static entity: TModel = MODEL.accountNumber;

  static primaryKey = 'Id';

  Id: string;

  Accounting: string;

  AccountingNumber: number;

  AccountType: string;

  Disabled: boolean;

  Name: string;

  Tax: number;

  TaxType: string;

  get asJson() {
    return {
      actions: this.Id,
      id: this.Id,
      accounting: this.Accounting,
      accountingNumber: this.AccountingNumber,
      accountType: this.AccountType,
      name: `${this.AccountingNumber} ${this.Name}`,
      tax: `${this.Tax} %`,
      taxType: this.TaxType,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      Accounting: this.string(''),

      AccountingNumber: this.number(null),

      AccountType: this.string(''),

      Disabled: this.boolean(false),

      Name: this.string(i18n(locale).tc('common.property.untitled')),

      Tax: this.number(0),

      TaxType: this.string(''),
    };
  };

  static async _create(): Promise<string> {
    const emptyAccountNumber = MAccountNumber.hydrate();

    const { response: { data: response } } = await MAccountNumber.api().post(
      Api.ENDPOINT.accountNumber,
      emptyAccountNumber,
      {
        save: false,
      },
    );

    MAccountNumber.insert({
      data: {
        ...emptyAccountNumber,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    MAccountNumber.delete(Id);

    MAccountNumber.api().delete(
      `${Api.ENDPOINT.accountNumber}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MAccountNumber.api().get(
      Api.ENDPOINT.accountNumber,
    );
  };

  static _update(accountNumber): void {
    MAccountNumber.api().put(
      `${Api.ENDPOINT.accountNumber}/${encodeURIComponent(accountNumber.Id)}`,
      accountNumber.$toJson(),
      {
        save: false,
      },
    );
  };
}
