import { i18n } from '@/locales/i18n';

const locale = localStorage.getItem('locale');

export enum PAYMENTMETHOD {
  CASH = 'cash',
  CREDITCARD = 'creditcard',
  DEBITCARD = 'debitcard',
  TRANSACTION = 'transaction',
}

export const paymentMethodMap = new Map([
  [
    PAYMENTMETHOD.CASH,
    {
      displayName: String(i18n(locale).t(`invoice.type.${PAYMENTMETHOD.CASH}`)),
      type: PAYMENTMETHOD.CASH,
    },
  ],
  [
    PAYMENTMETHOD.CREDITCARD,
    {
      displayName: String(i18n(locale).t(`invoice.type.${PAYMENTMETHOD.CREDITCARD}`)),
      type: PAYMENTMETHOD.CREDITCARD,
    },
  ],

  [
    PAYMENTMETHOD.DEBITCARD,
    {
      displayName: String(i18n(locale).t(`invoice.type.${PAYMENTMETHOD.DEBITCARD}`)),
      type: PAYMENTMETHOD.DEBITCARD,
    },
  ],
  [
    PAYMENTMETHOD.TRANSACTION,
    {
      displayName: String(i18n(locale).t(`invoice.type.${PAYMENTMETHOD.TRANSACTION}`)),
      type: PAYMENTMETHOD.TRANSACTION,
    },
  ],
]);

export const paymentMethodList = Array.from(paymentMethodMap.values());
