import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { entityTypeMap } from '@/utils/entity';
import { http } from '@/http';
import { i18n } from '@/locales/i18n';
import { IProduct } from '@/typings/interface/IProduct';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';
import { PIC_SIZE } from '@/utils/imageSize';
import { utIsEmpty } from '@/utils/empty';

const locale = localStorage.getItem('locale');

export class MProduct extends Model implements IProduct {
  static entity: TModel = MODEL.product;

  static primaryKey = 'Id';

  Id: string;

  AccountNumberId: string;

  ArticleNumber: string;

  CategoryId: string;

  EAN: string;

  Entity: string;

  Generalized: boolean;

  ImageAltText: string;

  ImageUrl: string;

  Incomplete: boolean;

  Name: string;

  Price: string;

  TaxId: string;

  Active: boolean;

  get asListData() {
    return {
      name: this.Name,
      price: this.formatedPrice,
      unit: entityTypeMap.get(this.Entity)?.displayName,
      actions: this.Id,
    };
  }

  get expandedName(): String {
    return `${this.ArticleNumber} - ${this.Name}`;
  }

  get showPlaceholder(): Boolean {
    return this.ImageUrl === '';
  }

  get formatedPrice(): string {
    return `€ ${(Number(this.Price)).toFixed(2)}`;
  }

  productImageURL(
    imageSize: PIC_SIZE = PIC_SIZE.L192,
  ) {
    if (!this.ImageUrl) {
      return '';
    }

    return `${Api.HOST}/api/${this.ImageUrl}?size=${imageSize}`;
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      AccountNumberId: this.attr(''),

      ArticleNumber: this.string(''),

      Name: this.string(String(i18n(locale).t('common.property.untitled'))),

      CategoryId: this.string(''),

      Entity: this.string(''),

      EAN: this.attr(''),

      Generalized: this.attr(false),

      ImageAltText: this.attr(''),

      ImageUrl: this.attr(''),

      Incomplete: this.boolean(true),

      Price: this.string('0.00'),

      TaxId: this.string(''),

      Active: this.boolean(true),
    };
  }

  static async _create(): Promise<string> {
    const emptyProduct = MProduct.hydrate();

    const { response: { data: response } } = await MProduct.api().post(
      Api.ENDPOINT.product,
      emptyProduct,
      {
        save: false,
      },
    );

    MProduct.insert({
      data: {
        ...emptyProduct,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    MProduct.delete(Id);

    MProduct.api().delete(
      `${Api.ENDPOINT.product}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MProduct.api().get(
      Api.ENDPOINT.product,
    );
  };

  static _update(product): void {
    MProduct.api().put(
      `${Api.ENDPOINT.product}/${encodeURIComponent(product.Id)}`,
      product.$toJson(),
    );
  };

  static async _deleteImage(productID): Promise<any> {
    const product = MProduct.find(productID);

    const { response: { status } } = await MProduct.api().delete(
      `${Api.HOST}/api/${product.ImageUrl}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );

    if (status === 204) {
      MProduct.update({
        where: productID,
        data: {
          ImageAltText: '',
          ImageUrl: '',
        },
      });
    }
  };

  static async _uploadImage(file: File, productID): Promise<any> {
    const product = MProduct.find(productID);

    const formData = new FormData();

    formData.append(
      'file',
      file,
      file.name,
    );

    if (!utIsEmpty(product.ImageUrl)) {
      await MProduct._deleteImage(productID);
    }

    const { response: { data: newProduct } } = await MProduct.api().post(
      `${Api.ENDPOINT.product}/${encodeURIComponent(productID)}/image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': http.defaults.headers.common.Authorization,
        },

        save: false,
      },
    );

    await MProduct.update({
      data: {
        Id: productID,
        ImageUrl: newProduct.ImageUrl,
        ImageAltText: newProduct.ImageAltText,
      },
    });
  };
}
