import { common } from '@/locales/en/partials/_common';

export const hardwareType = {
  action: {
    add: `${common.action.add} Hardware Type`,
    addChild: `${common.action.add} Sub-Hardware Type`,
  },

  alert: {
    removeAttribute: 'Removing the attribute "{attributeName}" from this hardware type will affect all sub-types and hardware instances.',
    inUse: 'This hardware type is still in use in {alertResponse}. It cannot be deleted.',
    updateAttribute: 'Changing attribute values in this hardware type will override existing values in sub-types and hardware instances.',
  },

  addedBy: 'Not added | Added by',

  model: 'Hardware Type | Hardware Types',

  noResult: 'You have not created any hardware types yet',

  origin: 'Origin | Origins',

  requiredBy: 'Not required | Required by',

  setBy: 'Not set | Set by',
};
