export const delivery = {
  action: {
    add: 'Lieferschein hinzufügen',
    new: 'Neuer Lieferschein',
  },

  model: 'Lieferschein | Lieferscheine',

  property: {
    arranger: 'Bearbeiter',
    date: 'Datum',
    number: 'Nr.',
    signature: 'Unterschrift',
  },

  text: {
    deliveryReceived: 'Lieferung erhalten am',
  },
};
