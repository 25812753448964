import { i18n } from '@/locales/i18n';

const locale = localStorage.getItem('locale');

export enum ENTITY {
  BOTTLE = 'bottle',
  GRAM = 'gram',
  HOUR = 'hour',
  LITRE = 'litre',
  MILLIMETER = 'millimeter',
  PIECE = 'piece',
}

export const entityTypeMap = new Map([
  [
    String(ENTITY.BOTTLE),
    {
      displayName: String(i18n(locale).t(`entityType.type.${ENTITY.BOTTLE}`)),
      type: ENTITY.BOTTLE,
    },
  ],

  [
    String(ENTITY.GRAM),
    {
      displayName: String(i18n(locale).t(`entityType.type.${ENTITY.GRAM}`)),
      type: ENTITY.GRAM,
    },
  ],

  [
    String(ENTITY.HOUR),
    {
      displayName: String(i18n(locale).tc(`entityType.type.${ENTITY.HOUR}`, 1)),
      type: ENTITY.HOUR,
    },
  ],

  [
    String(ENTITY.LITRE),
    {
      displayName: String(i18n(locale).t(`entityType.type.${ENTITY.LITRE}`)),
      type: ENTITY.LITRE,
    },
  ],

  [
    String(ENTITY.MILLIMETER),
    {
      displayName: String(i18n(locale).t(`entityType.type.${ENTITY.MILLIMETER}`)),
      type: ENTITY.MILLIMETER,
    },
  ],

  [
    String(ENTITY.PIECE),
    {
      displayName: String(i18n(locale).tc(`entityType.type.${ENTITY.PIECE}`, 1)),
      type: ENTITY.PIECE,
    },
  ],
]);

export const entityTypeList = Array.from(entityTypeMap.values());

export enum DISPLAY_TYPE{
  LIST = 'list',
  GRID = 'grid',
}

export const displayTypeMap = new Map([
  [
    DISPLAY_TYPE.LIST,
    {
      displayName: String(i18n(locale).t(`displayType.type.${DISPLAY_TYPE.LIST}`)),
      type: DISPLAY_TYPE.LIST,
    },
  ],

  [
    DISPLAY_TYPE.GRID,
    {
      displayName: String(i18n(locale).t(`displayType.type.${DISPLAY_TYPE.GRID}`)),
      type: DISPLAY_TYPE.GRID,
    },
  ],
]);

export const displayTypeList = Array.from(displayTypeMap.values());

