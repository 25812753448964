import { common } from '@/locales/en/partials/_common';

export const category = {
  model: 'Category | Categories',

  action: {
    add: `${common.action.add} Category`,
    delete: `${common.action.delete} Category`,
  },

  alert: {
    delete: 'Do you want to delete the category "{categoryName}"?',
  },

  message: {
    noResult: 'No Categories found!',
  },

  property: {
    name: 'Category Name',
  },
};
