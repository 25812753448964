import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { INotification } from '@/typings/interface/INotification';
import { MODEL, TModel } from '@/utils/models';
import { Model } from '@vuex-orm/core';

export class MNotification extends Model implements INotification {
  static entity: TModel = MODEL.notification;

  static primaryKey = 'Id';

  Id: string;

  Content: string;

  Message: string;

  New: boolean;

  Path: string;

  Type: string;

  get asListData() {
    return {
      class: '',
      content: this.Content,
      message: this.Message,
      new: this.New,
      path: this.Path,
      type: this.Type,
      actions: this.Id,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      Content: this.string(''),

      Message: this.string(''),

      New: this.boolean(true),

      Path: this.string(''),

      Type: this.string(''),
    };
  }

  static async _create(notification: INotification): Promise<string> {
    const newNotification = MNotification.hydrate(notification);

    const { response: { data: response } } = await MNotification.api().post(
      Api.ENDPOINT.notification,
      notification,
      {
        save: false,
      },
    );

    MNotification.insert({
      data: {
        ...newNotification,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static async _fetch(): Promise<any> {
    await MNotification.deleteAll();

    await MNotification.api().get(
      `${Api.ENDPOINT.notification}`,
    );
  };

  static _update(notification: MNotification): void {
    MNotification.api().put(
      `${Api.ENDPOINT.notification}/${encodeURIComponent(notification.Id)}`,
      notification.$toJson(),
      {
        save: false,
      },
    );
  };
}
