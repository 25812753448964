export const profile = {
  image: {
    alt: 'Benutzer Profilbild',
    upload: 'Profilbild hochladen',
  },

  logout: 'Logout',

  model: 'Profil | Profile',

  username: 'Username',
};
