






























import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import Vue, { VueConstructor } from 'vue';
import { isEqual as _isEqual, isNil as _isNil } from 'lodash';
import { mixinComponentUID } from '@/components/mixins/componentUID';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseButtonText',

  components: {
    BaseSvgIcon,
  },

  mixins: [
    mixinComponentUID,
  ],

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    double: {
      type: Boolean,
      default: false,
    },

    download: {
      type: [
        String,
        null,
      ],
      default: null,
    },

    href: {
      type: [
        String,
        null,
      ],
      default: null,
    },

    iconName: {
      type: String,
      default: '',
    },

    iconType: {
      type: String,
      default: '',
    },

    tabIndex: {
      type: Number,
      default: 0,
    },

    variant: {
      type: String,
      default: 'tertiary',
    },
  },

  computed: {
    classList() {
      return {
        'button': !this.$slots.line,
        [`button--${this.variant}`]: !this.$slots.line,
        'button__double': this.double,
        'button-line': this.$slots.line,
        [`button-line--${this.variant}`]: this.$slots.line,
      };
    },

    component() {
      if (!_isNil(this.href)) {
        return 'a';
      }

      return 'button';
    },
  },

  methods: {
    onKeyUp(event): void {
      if (_isEqual(event.keyCode, 13)) {
        this.$refs[this.componentUID].blur();
      }
    },
  },

  mounted(): void {
    if (this.$slots.line) {
      if (this.$slots.default) {
        this.$logger.error(
          'The "line" slot shouldn\'t be used in combination with the default slot.',
        );
      }
    }
  },
});
