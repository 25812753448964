import { isNil as _isNil } from 'lodash';
import { AxiosConfig } from '@/http/axiosConfig';
import { MUTATION } from '@/typings/interface/IRootState';
import { MutationTree } from 'vuex';
import { RootState } from '@/store/';

const mutations = <MutationTree<RootState>> {
  [MUTATION.setUserInfo](
    state: RootState,
    user: any,
  ) {
    if (_isNil(process.env.VUE_APP_DEFAULT_ORGANIZATION_ID)) {
      AxiosConfig.baseURL = `/api/${process.env.VUE_APP_WEB_API_URL}/${user.active_organization}`;

      state.default.ID = user.active_organization;
    }

    state.default.userID = user.sub;
  },
};

export { mutations };
