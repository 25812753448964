import { common } from '@/locales/en/partials/_common';

export const batch = {
  action: {
    add: `${common.action.add} Batch`,
    new: `${common.action.new} Batch`,
  },

  alert: {
    delete: 'Do you want to delete batch "{batchNumber}"?',
  },

  model: 'Batch | Batches',

  noResult: 'You have not created any batches yet',

  property: {
    batchNumber: 'Batch Number',
    date: 'Date',
    entity: 'Entity',
    productName: 'Product Name',
    quantity: 'Quantity',
  },
};
