










































































































































































import BaseButtonIcon from '@/components/base/button/BaseButtonIcon.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import BaseTooltip from '@/components/base/BaseTooltip.vue';
import Vue, { VueConstructor } from 'vue';
import { isEqual as _isEqual } from 'lodash';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { mixinComponentUID } from '@/components/mixins/componentUID';
import { MSettings } from '@/models/MSettings';
import { RouteConfig } from 'vue-router';
import { routeList } from '@/routes';
import { Router } from '@/utils/router';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'Navigation',

  components: {
    BaseButtonIcon,
    BaseRow,
    BaseSvgIcon,
    BaseTooltip,
  },

  mixins: [
    mixinComponentUID,
  ],

  data() {
    return {
      collapsed: false,
      locale: 'en',
      NAVIGATION: Router.NAVIGATION,
      routeList,
      utIsEmpty,
    };
  },

  computed: {
    classes(): object {
      return {
        'navigation--collapsed': this.collapsed,
      };
    },
  },

  methods: {
    collapse(): void {
      this.collapsed = !this.collapsed;

      if (this.collapsed) {
        localStorage.setItem('navigation', 'collapsed');
      } else {
        localStorage.setItem('navigation', '');
      }

      this.$emit('collapse');
    },

    getClass(route: IRouteConfig): string {
      if (route.heading) {
        return 'navigation-list__title';
      }

      return 'navigation-list__item';
    },

    getRouteName(route: IRouteConfig | RouteConfig): string {
      return route.meta?.label ? route.meta.label : '';
    },

    hasChildren(route: IRouteConfig): boolean {
      return !!(route.children && route.children.length > 0);
    },

    hasEqualNavItem(route, navItem): boolean {
      return _isEqual(route.meta.navItem, navItem);
    },

    isHeading(route: IRouteConfig): boolean {
      return route.heading ? route.heading : false;
    },

    isNavItem(route: IRouteConfig): boolean {
      return route.navItem ? route.navItem : false;
    },
  },

  beforeMount(): void {
    const collapsed = localStorage.getItem('navigation');

    if (collapsed === 'collapsed') {
      this.collapsed = true;
    }

    this.locale = MSettings.query().first().Language;
  },
});
