import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { modes } from '@/utils/TModes';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';
import { namespace } from '@/store/index.definition';
import { Router } from '@/utils/router';

const ModalCustomer = () => import(/* webpackChunkName: "shoppingBag" */ './modals/CustomerAdd.vue');
const ModalSalesEdit = () => import(/* webpackChunkName: "shoppingBag" */ './modals/SalesEdit.vue');
const Show = () => import(/* webpackChunkName: "shoppingBag" */ './DeliveryBagShow.vue');

/* MODALS */
export function customerRoute(locale): IRouteConfig {
  return {
    component: ModalCustomer,
    meta: {
      label: String(i18n(locale).tc('shoppingBag.property.selectCustomer')),
      module: namespace.delivery,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: 'selectDeliveryCustomer',
    path: 'select-delivery-customer',
    props: (route) => ({
      deliveryBagId: route.query.deliveryBagId,
      routeBack: {
        name: 'deliveryBagShow',
      },
      showModal: true,
    }),
  };
};

export function deliveryBagSalesRouteEdit(locale): IRouteConfig {
  return {
    component: ModalSalesEdit,
    meta: {
      label: String(i18n(locale).tc('product.model', 1)),
      module: namespace.delivery,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `deliveryBagSales${modes.EDIT}`,
    path: 'edit-sale/:productId',
    props: (route) => ({
      ID: route.params.productId,
      mode: modes.EDIT,
      routeBack: {
        name: 'deliveryBagShow',
      },
      deliveryBagId: route.params.deliveryBagId,
      showModal: true,
    }),
  };
};

export function deliveryBagSalesRouteNew(locale): IRouteConfig {
  return {
    beforeEnter: async(to, from, next) => {
      const product = MProduct.find(to.params.productId);

      to.params.ID = await MSales._create(
        to.params.deliveryBagId,
        {
          Discount: '0',
          ProductID: to.params.productId,
          Price: product.Price,
          SinglePrice: product.Price,
          Quantity: '1',
        },
      );

      next();
    },
    component: ModalSalesEdit,
    meta: {
      label: String(i18n(locale).tc('delivery.model', 1)),
      module: namespace.delivery,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `deliveryBagSales${modes.NEW}`,
    path: 'new-sale',
    props: (route) => ({
      ID: route.params.ID,
      mode: modes.NEW,
      routeBack: {
        name: 'deliveryBagShow',
      },
      showModal: true,
    }),
  };
};

/* PAGES */
export function deliveryBagRouteShow(locale): IRouteConfig {
  return {
    children: [
      customerRoute(locale),
      deliveryBagSalesRouteNew(locale),
      deliveryBagSalesRouteEdit(locale),
    ],
    component: Show,
    icon: {
      iconName: 'delivery-slip',
      iconFamily: '',
    },
    meta: {
      label: String(i18n(locale).tc('delivery.model', 1)),
      navItem: 'primary',
    },
    name: 'deliveryBagShow',
    navItem: true,
    path: '/delivery-bag/',
    props: (route) => ({
      deliveryBagId: (route.query.deliveryBagId) ? route.query.deliveryBagId : '',
      showModal: false,
    }),
  };
};
