import deLocale from 'date-fns/locale/de';
import format from 'date-fns/format';
import { isUndefined as _isUndefined } from 'lodash';
import { IAddress, initAddress } from '@/typings/interface/IAddress';
import { utIsEmpty } from '@/utils/empty';

const joinNonEmpty = (
  seperator,
  valueList,
) => {
  return valueList
    .map(
      item => item.trim(),
    ).filter(
      item => !utIsEmpty(item),
    ).join(seperator);
};

const formatDate = (date: string): string => {
  let options = null;
  const lang = navigator.language;

  if (lang.indexOf('de') !== -1) {
    options = {
      locale: deLocale,
    };
  }

  return format(new Date(date), 'dd MMM yyyy', options);
};

const formatDateTime = (date: string): string => {
  let options = null;
  const lang = navigator.language;

  if (lang.indexOf('de') !== -1) {
    options = {
      locale: deLocale,
    };
  }

  return format(new Date(date), 'dd.MM.yyyy, HH:mm', options);
};

const formatFileName = (fileName: string): string => {
  if (_isUndefined(fileName)) {
    return '';
  }

  return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
};

const formatFileSize = (fileSize: number): string => {
  if (_isUndefined(fileSize)) {
    throw new Error('fileSize undefiened');
  }

  const base = 1024;

  const exponent = (fileSize === 0) ? 0 : Math.floor(Math.log(fileSize) / Math.log(base));
  const formattedSize = fileSize / Math.pow(base, exponent);

  const fileSizeUnitList = [
    'B',
    'kB',
    'MB',
    'GB',
    'TB',
  ];

  return `${(formattedSize).toFixed(2)} ${fileSizeUnitList[exponent]}`;
};

const formatNodeSequence = (title: string | Array<string>): string => {
  if (_isUndefined(title)) {
    return '';
  }

  if (!Array.isArray(title)) {
    title = title.split(',');
  }

  const spacer = ' › ';

  title = title.join(spacer);

  return title;
};

export {
  joinNonEmpty,
  formatDate,
  formatDateTime,
  formatFileName,
  formatFileSize,
  formatNodeSequence,
};
