import messages from '@/locales/messages';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export function i18n(locale: string = 'en'): VueI18n {
  return new VueI18n({
    locale: locale,
    messages,
    silentFallbackWarn: true,
  });
}
