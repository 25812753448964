import ChangePassword from '@/views/profile/modals/ChangePassword.vue';
import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { modes } from '@/utils/TModes';
import { namespace } from '@/store/index.definition';
import { Router } from '@/utils/router';
import { VIEW } from '@/utils/views';

const Profile = () => import(/* webpackChunkName: "profile" */ './Profile.vue');
const TabOrganization = () => import(/* webpackChunkName: "profile" */ './tabs/TabOrganization.vue');
const TabProfile = () => import(/* webpackChunkName: "profile" */ './tabs/TabProfile.vue');
const TabSettings = () => import(/* webpackChunkName: "profile" */ './tabs/TabSettings.vue');

/* TABS */
export function organizationTabOrganization(locale): IRouteConfig {
  return {
    component: TabOrganization,
    icon: {
      iconFamily: '',
      iconName: 'company',
    },
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: i18n(locale).tc('organization.tab.organization'),
      module: namespace.organization,
      type: Router.ROUTE_TYPE.TAB,
    },
    name: `organization-${VIEW.ORGANIZATION}`,
    navItem: true,
    path: '/profile/organization',
    props: {
      heading: 'organization.tab.organization',
      mode: modes.EDIT,
    },
  };
}

export function organizationTabProfile(locale): IRouteConfig {
  return {
    component: TabProfile,
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: String(i18n(locale).tc('organization.tab.profile')),
      module: namespace.organization,
      type: Router.ROUTE_TYPE.TAB,
    },
    name: `organization-${VIEW.PROFILE}`,
    path: '/profile/account',
    props: {
      heading: 'organization.tab.profile',
      mode: modes.EDIT,
    },
  };
}

export function organizationTabSettings(locale): IRouteConfig {
  return {
    component: TabSettings,
    icon: {
      iconFamily: 'fontawesome',
      iconName: 'settings',
    },
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: String(i18n(locale).tc('organization.tab.settings')),
      module: namespace.organization,
      type: Router.ROUTE_TYPE.TAB,
    },
    name: `organization-${VIEW.SETTINGS}`,
    navItem: true,
    path: '/profile/settings',
    props: {
      heading: 'organization.tab.settings',
      mode: modes.EDIT,
    },
  };
}

/* PAGES */
export function userChangePassword(locale): IRouteConfig {
  return {
    component: ChangePassword,
    meta: {
      label: String(i18n(locale).tc('user.password.action.change', 1)),
      module: namespace.user,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: 'changePassword',
    path: '/profile/change-password',
    props: {
      routeBack: {
        name: `organization-${VIEW.PROFILE}`,
      },
      showModal: true,
    },
  };
};

export function profileRouteShow(locale): IRouteConfig {
  return {
    children: [
      organizationTabProfile(locale),
      organizationTabOrganization(locale),
      organizationTabSettings(locale),
      userChangePassword(locale),
    ],
    component: Profile,
    meta: {
      label: String(i18n(locale).tc('profile.model', 1)),
      module: namespace.user,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'profile',
    path: '/profile',
    props: (route) => ({
      mode: modes.EDIT,
    }),
  };
};

export function settingsRouteShow(locale): IRouteConfig {
  return {
    ...profileRouteShow(locale),
    heading: true,
    meta: {
      label: String(i18n(locale).tc('settings.model', 2)),
      module: namespace.user,
      type: Router.ROUTE_TYPE.PAGE,
    },
    navItem: true,
  };
};
