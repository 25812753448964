export type TModes =
  'append' |
  'debug' |
  'edit' |
  'new' |
  'show';

const modes: { [key: string]: TModes } = {
  APPEND: 'append',
  DEBUG: 'debug',
  EDIT: 'edit',
  NEW: 'new',
  SHOW: 'show',
};

Object.freeze(modes);

export { modes };
