import logger from '../utils/logger';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/';

const actions: ActionTree<RootState, any> = {
  async abort(context) {
    await context.dispatch('STOP_LOADING');
  },

  async error(context, error) {
    await context.dispatch('STOP_LOADING');

    logger.error(error);
  },

  async info(context, error) {
    await context.dispatch('STOP_LOADING');

    logger.info(`${error.request.status}: ${error.response.data}`);
  },
};

export { actions };
