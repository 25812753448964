







































import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import Vue, { VueConstructor } from 'vue';
import { SELECTIONSTATE } from '@/utils/states';

/**
 * Checkboxes to check the boxes. Or uncheck them.
 */
export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseCheckbox',

  components: {
    BaseSvgIcon,
  },

  model: {
    event: 'change:checkbox-selection',
    prop: 'hasState',
  },

  props: {
    /**
     * Defines if checkbox is disabled
    */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Defines the initial state -'UNCHECKED'
     * other states 'INDETERMINATE' 'CHECKED'
     */
    hasState: {
      type: String,
      default: SELECTIONSTATE.UNCHECKED,
    },

    /**
     * Defines if only the box is clickable, or box and description
     */
    onlyBoxIsClickable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      focused: false,
      state: this.hasState,
    };
  },

  computed: {
    boxClasses() {
      return {
        'checkbox__box--checked': this.state === SELECTIONSTATE.CHECKED,
        'checkbox__box--focused': this.focused,
        'checkbox__box--indeterminate': this.state === SELECTIONSTATE.INDETERMINATE,
      };
    },

    descriptionClasses() {
      return {
        'checkbox__description--not-clickable': this.onlyBoxIsClickable,
      };
    },

    iconName() {
      let iconName = '';

      if (this.state === SELECTIONSTATE.INDETERMINATE) {
        iconName = 'indeterminate';
      }

      if (this.state === SELECTIONSTATE.CHECKED) {
        iconName = 'tick';
      }

      return iconName;
    },

    labelClasses() {
      return {
        'checkbox--disabled': this.disabled,
      };
    },

    showIcon() {
      return this.state !== SELECTIONSTATE.UNCHECKED;
    },
  },

  watch: {
    focused() {
      this.$emit(
        'changed-checkbox-focus',
        this.focused,
      );
    },

    hasState() {
      this.state = this.hasState;
    },
  },

  methods: {
    onBoxClick() {
      this.toggle();
    },

    onDescriptionClick() {
      if (this.onlyBoxIsClickable) {
        return false;
      }

      this.toggle();
    },

    toggle() {
      let newState = SELECTIONSTATE.UNCHECKED;

      if (!this.disabled) {
        if (
          this.state === SELECTIONSTATE.UNCHECKED ||
        this.state === SELECTIONSTATE.INDETERMINATE
        ) {
          newState = SELECTIONSTATE.CHECKED;
        }

        this.$refs.input.state = newState;

        this.state = newState;

        this.$emit(
          'change:checkbox-selection',
          newState,
        );
      }
    },
  },
});
