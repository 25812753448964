import { accountNumberRouteShow } from '@/views/definitions/accountNumber/routes';
import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { categoryRouteShow } from '@/views/definitions/category/routes';
import { customerRouteShow } from '@/views/definitions/customer/routes';
import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { productRouteShow } from '@/views/definitions/product/routes';
import { taxRouteShow } from '@/views/definitions/tax/routes';

const Definitions = () => import(/* webpackChunkName: "definition" */ './Definitions.vue');

export function definitionRouteShow(locale): IRouteConfig {
  return {
    children: [
      accountNumberRouteShow(locale),
      categoryRouteShow(locale),
      customerRouteShow(locale),
      productRouteShow(locale),
      taxRouteShow(locale),
    ],
    component: Definitions,
    heading: true,
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: String(i18n(locale).tc('definition.model', 2)),
    },
    name: 'definitions',
    navItem: true,
    path: '/definitions',
  };
};
