/**
 * Web Font Loader takes care of Peacemakers font loadingStoreList.
 * For full documentation, see: https://github.com/typekit/webfontloader
 */
import WebFont from 'webfontloader';

const loadWebfont = () => {
  WebFont.load({
    active: () => {
      localStorage.setItem('fonts', 'true');
    },

    custom: {
      families: [
        'HCo Whitney SSm:n4,i4,n5,i5,n6,i6,n7,i7',
      ],
      urls: [
        '/webfonts/webfonts.css',
      ],
    },
  });
};

export { loadWebfont };
