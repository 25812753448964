export const product = {
  model: 'Produkt | Produkte',

  action: {
    add: 'Produkt hinzufügen',
    delete: 'Produkt löschen',
  },

  alert: {
    delete: 'Willst du das Produkt "{productName}" wirklich löschen?',
  },

  message: {
    empty: 'Keine Produkte erstellt.',
  },

  property: {
    articleNumber: 'Artikel-Nr.',
    category: 'Kategorie',
    ean: 'EAN/BarCode',
    unit: 'Einheit',
    name: 'Produktname',
    pending: 'Bild ladet...',
    price: 'Preis',
    tax: 'Steuer',
  },
};
