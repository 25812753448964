import Vue from 'vue';
import {
  debounce as _debounce,
  isUndefined as _isUndefined,
  throttle as _throttle,
} from 'lodash';
import { DEBOUNCE_WAIT_RESIZE, THROTTLE_WAIT_SCROLL } from '@/utils/globals';
import { DIMENSION } from '@/utils/dimensions';
import { IDropdownPositionOptions } from '@/typings/interface/IDropdownPositionOptions';
import { POSITION } from '@/utils/positions';
import { space } from '@/assets/styles/base/variables/space/space';
import { utIsEmpty } from '@/utils/empty';

let debouncedPositionListMethod;
let throttledPositionListOnScrollMethod;

export const mixinDropdownPositioning = {
  props: {
    anchorData: {
      type: Array,
      default() {
        return [
          POSITION.LEFT,
        ];
      },
    },

    minHeight: {
      type: Number,
      default: 3.5 * 40, // 40 = listItemHeight
    },
  },

  data() {
    return {
      containerElement: null,

      listElement: null,

      listElementHeight: null,

      modalReference: false,

      windowSize: {
        height: 0,
        width: 0,
      },
    };
  },

  methods: {
    cacheWindowSize(): void {
      this.windowSize.height = window.innerHeight;

      this.windowSize.width = window.innerWidth;
    },

    checkTriggerVisibility(): void {
      if (!this.modalReference) {
        this.triggerIsVisible = true;

        return;
      }

      const modalContainerPositionData = this.modalReference.$el.querySelector('.modal-container').getBoundingClientRect();

      const selfPositionData = this.$el.getBoundingClientRect();

      if (selfPositionData.top + selfPositionData.height < modalContainerPositionData.top) {
        this.triggerIsVisible = false;

        return;
      }

      if (selfPositionData.bottom - selfPositionData.height > modalContainerPositionData.bottom) {
        this.triggerIsVisible = false;

        return;
      }

      this.triggerIsVisible = true;
    },

    cleanUp(): void {
      const dropdownWormholeExitElement: HTMLElement = document.getElementById('dropdown-wormhole-exit');

      if (dropdownWormholeExitElement) {
        if (this.listElement) {
          dropdownWormholeExitElement.removeChild(this.listElement);
        }
      }

      window.removeEventListener(
        'resize',
        debouncedPositionListMethod,
      );

      document.removeEventListener(
        'scroll',
        throttledPositionListOnScrollMethod,
        true,
      );
    },

    decoupleList(container: HTMLElement, list: HTMLElement, height: number = null): void {
      this.containerElement = container;

      this.listElement = list;

      if (!utIsEmpty(height)) {
        this.listElementHeight = height;
      }

      document.getElementById('dropdown-wormhole-exit').appendChild(this.listElement);

      throttledPositionListOnScrollMethod = _throttle(
        this.positionListOnScroll,
        THROTTLE_WAIT_SCROLL,
      );

      document.addEventListener(
        'scroll',
        throttledPositionListOnScrollMethod,
        true,
      );

      this.positionList();
    },

    async fitInViewport(anchor: HTMLElement, target: HTMLElement, options: IDropdownPositionOptions): Promise<any> {
      const anchorBoundingRectangleData = anchor.getBoundingClientRect();

      target.style.minWidth = `${anchorBoundingRectangleData.width}px`;

      target.style.overflowY = 'auto';

      let targetBoundingRectangleData = target.getBoundingClientRect();

      if (
        targetBoundingRectangleData.width === 0 ||
        targetBoundingRectangleData.height === 0
      ) {
        targetBoundingRectangleData = this.getElementRect(target);
      }

      // This does things like aligning the coordinates and/or certain dimensions of the anchor and target
      // e.g. target.style.left = anchor.left + 'px';
      if (options.anchorData) {
        options.anchorData.forEach(
          (anchorDataEntry: string) => {
            if (anchorDataEntry === POSITION.CENTER) {
              target.style[POSITION.LEFT.toLowerCase()] = (
                anchorBoundingRectangleData[POSITION.LEFT.toLowerCase()] -
                (
                  (
                    targetBoundingRectangleData.width - anchorBoundingRectangleData.width
                  ) / 2
                ) + 'px'
              );
            } else if (anchorDataEntry === POSITION.RIGHT) {
              target.style[POSITION.LEFT.toLowerCase()] = (
                anchorBoundingRectangleData[POSITION.LEFT.toLowerCase()] -
                (
                  targetBoundingRectangleData.width - anchorBoundingRectangleData.width
                ) + 'px'
              );
            }

            if (anchorDataEntry === POSITION.TOP) {
              target.style[POSITION.TOP.toLowerCase()] = (
                anchorBoundingRectangleData[POSITION.TOP.toLowerCase()] -
                (
                  targetBoundingRectangleData.height + space.xxxSmall
                ) + 'px'
              );
            }

            if (
              anchorDataEntry === DIMENSION.HEIGHT ||
              anchorDataEntry === DIMENSION.WIDTH ||
              anchorDataEntry === POSITION.LEFT
            ) {
              target.style[anchorDataEntry.toLowerCase()] = anchorBoundingRectangleData[anchorDataEntry.toLowerCase()] + 'px';
            }
          },
        );
      }

      const clientHeight = (targetBoundingRectangleData.height === 0) ? target.clientHeight : targetBoundingRectangleData.height;
      const spaceAbove = this.getSpaceAbove(anchorBoundingRectangleData) - space.base;
      const spaceBelow = this.getSpaceBelow(anchorBoundingRectangleData) - space.base;
      let top, targetHeight;

      // Hitting the bottom edge
      if (spaceBelow < options.minHeight) {
        const maxHeight = this.setSizeForDropup(anchorBoundingRectangleData, target);

        if (!utIsEmpty(this.listElementHeight)) {
          targetHeight = (this.listElementHeight > maxHeight) ? maxHeight : this.listElementHeight;
        } else {
          targetHeight = clientHeight;
        }

        if (spaceAbove <= targetHeight) {
          top = 0;
        } else {
          top = (anchorBoundingRectangleData.top - targetHeight - space.small);
        }

        target.style.top = top + 'px';
      } else {
        const maxHeight = this.setSizeForDropdown(anchorBoundingRectangleData, target);

        if (!utIsEmpty(this.listElementHeight)) {
          targetHeight = (this.listElementHeight > maxHeight) ? maxHeight : this.listElementHeight;
        } else {
          targetHeight = clientHeight;
        }

        target.style.top = (anchorBoundingRectangleData.top + anchorBoundingRectangleData.height) + 'px';
      }
    },

    getElementRect(target: HTMLElement): Object {
      return {
        bottom: Number(target.style[POSITION.BOTTOM.toLowerCase()].replace('px', '')),
        height: Number(target.style[DIMENSION.HEIGHT.toLowerCase()].replace('px', '')),
        left: Number(target.style[POSITION.LEFT.toLowerCase()].replace('px', '')),
        right: Number(target.style[POSITION.RIGHT.toLowerCase()].replace('px', '')),
        top: Number(target.style[POSITION.TOP.toLowerCase()].replace('px', '')),
        width: Number(target.style[DIMENSION.WIDTH.toLowerCase()].replace('px', '')),
      };
    },

    getModalReference(context: Vue = this): boolean | Vue {
      if (context.$parent === undefined) {
        return false;
      }

      if (context.$el.classList.contains('modal')) {
        return context;
      }

      return this.getModalReference(context.$parent);
    },

    getSpaceBelow(referenceClientRect: ClientRect): number {
      return this.windowSize.height - referenceClientRect.top - referenceClientRect.height;
    },

    getSpaceAbove(referenceClientRect: ClientRect): number {
      return referenceClientRect.top;
    },

    positionListOnScroll(): void {
      this.checkTriggerVisibility();

      if (!this.triggerIsVisible) {
        this.hideList();

        return;
      }

      this.positionList();
    },

    positionList(container = null): void {
      if (this.isOpen !== true) {
        return;
      }

      this.cacheWindowSize();
      let containerElement = this.containerElement;

      if (
        container !== null &&
        Object.prototype.toString.call(container) !== '[object Event]'
      ) {
        containerElement = container;
      }

      if (this.customAnchor !== undefined) {
        containerElement = this.customAnchor;
      }

      this.fitInViewport(
        containerElement,
        this.listElement,
        {
          anchorData: this.anchorData,
          minHeight: this.minHeight,
        },
      );
    },

    setSizeForDropdown(containerPositionData: ClientRect, list: HTMLElement): number {
      const spaceBelow = this.getSpaceBelow(containerPositionData) - space.base;

      list.style.maxHeight = spaceBelow + 'px';

      return spaceBelow;
    },

    setSizeForDropup(containerPositionData: ClientRect, list: HTMLElement): number {
      const spaceAbove = this.getSpaceAbove(containerPositionData) - space.base;

      list.style.maxHeight = spaceAbove + 'px';

      return spaceAbove;
    },
  },

  mounted(): void {
    this.modalReference = this.getModalReference();

    debouncedPositionListMethod = _debounce(
      this.positionList,
      DEBOUNCE_WAIT_RESIZE,
    );

    window.addEventListener(
      'resize',
      debouncedPositionListMethod,
    );

    if (_isUndefined(this.hideList) && !this.modalReference) {
      this.$logger.warn(
        '[Dropdown Positioning Mixin] The `hideList` method is missing in your component. ' +
        'If you want to hide it when your trigger scrolls out of view, please write the logic ' +
        'for that so this mixin can make use of it.',
      );
    }
  },

  beforeDestroy(): void {
    this.cleanUp();
  },
};
