export const nonHardwareProduct = {
  model: 'Non-Hardware Product | Non-Hardware Products',

  message: {
    empty: 'No non-hardware product added yet',
    noResult: 'No non-hardware product found',
    notPlanned: 'No non-hardware products are planned at this point in time.',
  },

  sendTo: {
    all: 'to all Sales Locations in campaign',
    specific: 'to only specific Sales Locations in campaign',
  },
};
