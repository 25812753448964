export const earning = {
  model: 'Erlös | Erlöse',

  message: {
    noResult: 'Keine Erlöse hinzugefügt',
  },

  property: {
    accountNumber: 'Kontonummer',
    bankCash: 'Bank/Kassa',
    date: 'Buchungsdatum',
    description: 'Beschreibung',
    grossAmount: 'Brutto',
    invoiceNumber: 'Rechnungs-Nr.',
    name: 'Organization',
    netAmount: 'Netto',
    reversal: 'Storno',
    slip: 'Beleg',
    slipRange: 'Beleg-Nr.',
    tax: '%',
    taxAmount: 'Steuer',
  },

  exportPdf: {
    accountNumber: 'Konto',
    bankCash: 'Bank',
    date: 'Datum',
    description: 'Beschreibung',
    grossAmount: 'Brutto',
    invoice: 'Rechnung',
    netAmount: 'Netto',
    slip: 'Beleg',
    tax: '%',
    taxAmount: 'Steuer',
  },

  slipRange: {
    BK: 'BK',
    CS: 'KA',
    IRO: 'FA',
    EE: 'EA',
  },
};
