class Logger {
  showLog = process.env.NODE_ENV === 'development';

  public error(output: string) {
    if (this.showLog) {
      /* eslint-disable no-console */
      console.error(output);
      /* eslint-enable no-console */
    }
  }

  public info(output: string) {
    if (this.showLog) {
      /* eslint-disable no-console */
      console.info(output);
      /* eslint-enable no-console */
    }
  }

  public log(output: string) {
    if (this.showLog) {
      /* eslint-disable no-console */
      console.log(output);
      /* eslint-enable no-console */
    }
  }

  public warn(output: string) {
    if (this.showLog) {
      /* eslint-disable no-console */
      console.warn(output);
      /* eslint-enable no-console */
    }
  }
}

export default new Logger();
