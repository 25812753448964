export const organization = {
  model: 'Organization | Organizations',

  property: {
    address: 'Address',
    email: 'Email Address',
    endText: 'Invoice End Text',
    generalized: 'Generalized',
    images: 'Organization Image | Organization Images',
    invoiceAddress: 'Invoice Address',
    language: 'Language',
    logo: 'Logo',
    name: 'Organization Name',
    owner: 'Owner',
    phone: 'Phone Number',
    productGrid: 'Product Layout',
    sameAsAddress: 'Same as Organization Address',
    stationery: 'Stationery',
    stationeryUsePrint: 'Print Stationery',
    uid: 'VAT Number',
    website: 'Website',
  },

  tab: {
    organization: 'Organization',
    profile: 'User Account',
    settings: 'Settings',
  },
};
