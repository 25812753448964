import { common } from '@/locales/en/partials/_common';

export const batch = {
  action: {
    add: 'Charge hinzufügen',
    new: 'Neue Charge',
  },

  alert: {
    delete: 'Willst du die Charge "{batchNumber}" wirklich löschen?',
  },

  model: 'Charge | Chargen',

  noResult: 'Du hast noch keine Chargen hinzugefügt',

  property: {
    batchNumber: 'Chargennummer',
    date: 'Datum',
    entity: 'Einheit',
    productName: 'Produktname',
    quantity: 'Anzahl',
  },
};
