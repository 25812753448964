export const outgoing = {
  model: 'Outgoing | Outgoings',

  alert: {
    delete: 'Do you want to delete the outgoing "{name}"?',
  },

  message: {
    noResult: 'No invoice created yet.',
  },

  property: {
    account: 'Account',
    accountNumber: 'Account Number',
    bank: 'Bank/Cash',
    bankCash: 'Bank/Cash Number',
    date: 'Booking Date',
    description: 'Description',
    grossAmount: 'Gross Amount',
    invoiceNumber: 'Invoice Number',
    name: 'Organization Name',
    slip: 'Slip',
    slipRange: 'Slip Range',
    tax: 'Tax',
  },

  slipRange: {
    BK: 'BK',
    CS: 'CS',
    IRO: 'IRO',
    EE: 'EE',
  },
};
