import { common } from '@/locales/en/partials/_common';

export const customer = {
  action: {
    add: `${common.action.add} Customer`,
  },

  alert: {
    delete: 'Do you want to delete customer "{customerName}"?',
  },

  model: 'Customer | Customers',

  noResult: 'You have not created any customers yet',

  property: {
    female: 'Mrs',
    male: 'Mr',
  },

  tab: {
    attributes: 'Attributes',
    products: 'Product Prices',
  },

  table: {
    column: {
      address: 'Address',
      company: 'Company',
      customerName: 'Name',
      email: 'Email Address',
      firstName: 'Firstname',
      lastName: 'Lastname',
      uid: 'VAT Reg. No.',
      phone: 'Phone No.',
      prefixTitle: 'Title prefix',
      sex: 'Gender',
      suffixTitle: 'Title suffix',
    },
  },
};
