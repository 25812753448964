










































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import Vue, { VueConstructor } from 'vue';
import { isUndefined as _isUndefined } from 'lodash';
import { IAction } from '@/typings/interface/IAction';
import { mixinComponentUID } from '@/components/mixins/componentUID';
import { mixinDropdownPositioning } from '../mixins/dropdownPositioning';
import { POSITION } from '@/utils/positions';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseFlyOut',

  components: {
    BaseButtonText,
    BaseSvgIcon,
  },

  mixins: [
    mixinComponentUID,
    mixinDropdownPositioning,
  ],

  props: {
    actionList: {
      type: Array as () => Array<IAction>,
      default: function() {
        return [];
      },
    },

    anchorData: {
      type: Array,
      default: function() {
        return [
          POSITION.RIGHT,
        ];
      },
    },

    buttonText: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: 'FlyOut',
    },

    constantWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      trigger: null,
    };
  },

  computed: {
    hasMarker(): boolean {
      let hasMarker = false;

      this.actionList.forEach(action => {
        if (!_isUndefined(action.checked)) {
          hasMarker = true;
        }
      });

      return hasMarker;
    },
  },

  watch: {
    isOpen() {
      if (this.isOpen) {
        this.$emit('show');
      } else {
        this.$emit('hide');
      }
    },
  },

  methods: {
    clickedOutside(event): void {
      if (
        event.target === this.$el ||
        this.$el.contains(event.target)
      ) {
        return;
      }

      this.isOpen = false;
    },

    hideList(): void {
      this.isOpen = false;
    },

    iconName(icon): string {
      if (icon.iconName) {
        return icon.iconName;
      }

      return icon;
    },

    iconFamily(icon): string {
      if (icon.iconFamily) {
        return icon.iconFamily;
      }

      return '';
    },

    async setVisibility(event): Promise<any> {
      if (this.disabled) {
        return;
      }

      this.trigger = event.target;

      this.isOpen = !this.isOpen;

      await this.$nextTick();

      this.positionList();
    },
  },

  mounted(): void {
    window.addEventListener('click', this.clickedOutside);

    const container = this.$el;

    const list = this.$refs[this.name + 'Box'];

    this.decoupleList(container, list);
  },

  beforeDestroy(): void {
    window.removeEventListener('click', this.clickedOutside);
  },
});
