import uuid4 from 'uuid/v4';
import { IAddress } from '@/typings/interface/IAddress';
import { joinNonEmpty } from '@/utils/filters/format';
import { MODEL, TModel } from '@/utils/models';
import { Model } from '@vuex-orm/core';
import { utIsEmpty } from '@/utils/empty';

export class MAddress extends Model implements IAddress {
  static entity: TModel = MODEL.address;

  static primaryKey = 'Id';

  Id: string;

  AddressLine1: string;

  AddressLine2: string;

  City: string;

  Country: string;

  OwnerID: string;

  Zipcode: string;

  get asJson(): IAddress {
    return {
      Id: this.Id,
      AddressLine1: this.AddressLine1,
      AddressLine2: this.AddressLine2,
      City: this.City,
      Country: this.Country,
      Zipcode: this.Zipcode,
    };
  }

  get formatAddress(): string {
    const seperator = ', ';

    return joinNonEmpty(seperator, [
      `${this.AddressLine1} ${this.AddressLine2}`.trim(),
      `${this.City} ${this.Zipcode}`.trim(),
      `${this.Country}`.trim(),
    ]);
  };

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      AddressLine1: this.string(''),

      AddressLine2: this.attr(''),

      City: this.string(''),

      Country: this.string(''),

      OwnerID: this.string(''),

      Zipcode: this.string(''),
    };
  };

  static _isEmpty(address: IAddress): Boolean {
    return (
      utIsEmpty(address.AddressLine1) &&
      utIsEmpty(address.AddressLine2) &&
      utIsEmpty(address.City) &&
      utIsEmpty(address.Country) &&
      utIsEmpty(address.Zipcode)
    );
  };
}
