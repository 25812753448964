import { common } from '@/locales/en/partials/_common';

export const distributionCenter = {
  action: {
    add: `${common.action.add} Distribution Center`,
  },

  alert: {
    delete: 'Do you want to delete the distribution center "{distributionCenterName}"?',
  },

  message: {
    empty: 'No distribution center added yet',
    noResult: 'No distribution center found',
  },

  model: 'Distribution Center | Distribution Centers',

  property: {
    address: 'Distribution Center Address',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
  },
};
