





























































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import Vue, { VueConstructor } from 'vue';
import { isEqual as _isEqual } from 'lodash';
import { HTTP } from '@/http';
import { mapUserActions } from '@/store/user/user';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { User } from '@/store/user/user.store.definition';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'ChangePassword',

  components: {
    BaseButtonText,
    BaseInputField,
    BaseModal,
    BaseRow,
    LayoutHeader,
  },

  mixins: [
    mixinAlert,
    mixinModal,
  ],

  data() {
    return {
      errorMessage: '',
      hasErrors: false,
      password: {
        confirmPassword: '',
        oldPassword: '',
        password: '',
      },
    };
  },

  watch: {
    hasErrors() {
      if (this.hasErrors) {
        setTimeout(() => {
          this.hasErrors = false;

          this.errorMessage = '';
        }, 5000);
      }
    },
  },

  methods: {
    ...mapUserActions({
      changePassword: User.ACTION.changePassword,
    }),

    async changeUserPassword(): Promise<any> {
      if (
        utIsEmpty(this.password.confirmPassword) ||
        utIsEmpty(this.password.oldPassword) ||
        utIsEmpty(this.password.password)
      ) {
        this.errorMessage = String(this.$t('user.validation.allRequired'));

        this.hasErrors = true;

        return;
      }

      if (!_isEqual(this.password.password, this.password.confirmPassword)) {
        this.errorMessage = String(this.$t('user.validation.match'));

        this.hasErrors = true;

        return;
      }

      let response;

      try {
        response = await this.changePassword(this.password);

        this.notify(this.$t('user.password.notification.success'));

        this.closeModal();
      } catch (error) {
        if (error.response.status === HTTP.UNPROCESSABLE_ENTITY) {
          this.errorMessage = String(this.$t('user.message.currentPasswordError'));

          this.hasErrors = true;
        }
      }
    },

    closeModal(): void {
      this.$router.push(this.routeBack);
    },
  },
});
