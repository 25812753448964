import { actions } from './actions';
import { createNamespacedHelpers, Module } from 'vuex';
import { getters } from './getters';
import { IUserOrganization } from '@/typings/interface/IUserOrganization';
import { mutations } from './mutations';
import { namespace } from '@/store/index.definition';
import { User } from '@/store/user/user.store.definition';

class UserState implements User.State {
  constructor(user: any = {}) {
    this.activeOrganization = {
      ID: user.active_organization,
      name: user.active_organization_name,
    };

    this.email = user.email;

    this.fullName = user.name;

    this.isLoggedIn = true;

    this.loading = false;

    this.mainCountry = {
      ID: user.main_country,
      name: user.main_country_name,
    };

    this.mainOrganization = {
      ID: user.main_organization,
      name: user.main_organization_name,
    };

    this.userID = user.sub;

    this.userInfoOrganizationList = user.userInfoOrganizationList;

    this.userName = user.preferred_username;

    this.profileImageID = user.picture;
  }

  accessToken: string = '';

  activeOrganization: object = {
    ID: '',
    name: '',
  };

  email: string = '';

  fullName: string = '';

  isLoggedIn: boolean = false;

  loading: boolean = true;

  mainCountry: object = {
    ID: '',
    name: '',
  };

  mainOrganization: object = {
    ID: '',
    name: '',
  };

  profileImageID: string = '';

  profileImageName: string = '';

  userID: string = '';

  userInfoOrganizationList: Array<IUserOrganization> = [];

  userName: string = '';
}

class UserStore implements Module<User.State, any> {
  // TODO better typescript
  constructor(user: any = {}) {
    this.state = new UserState(user);
  }

  namespaced: boolean = true;

  state: User.State;

  actions = actions;

  getters = getters;

  mutations = mutations;
}

const {
  mapActions,
  mapGetters,
  mapState,
} = createNamespacedHelpers(namespace.user);

export {
  mapActions as mapUserActions,
  mapGetters as mapUserGetters,
  mapState as mapUserState,
  UserState,
  UserStore,
};
