export interface IRootState {
  default: {
    ID: string;
    userID: string;
  };
}

export enum MUTATION {
  setUserInfo = 'ROOTSTATE__SET_USER_INFO',
}
