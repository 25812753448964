import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { modes } from '@/utils/TModes';
import { MOutgoing } from '@/models/MOutgoing';
import { namespace } from '@/store/index.definition';
import { RootState } from '@/store';
import { Route } from 'vue-router';
import { Router } from '@/utils/router';
import { Store } from 'vuex';

const ModalEdit = () => import(/* webpackChunkName: "outgoing" */ './modals/OutgoingsEdit.vue');
const Show = () => import(/* webpackChunkName: "outgoing" */ './OutgoingsShow.vue');

/* MODALS */
export function outgoingsRouteEdit(locale): IRouteConfig {
  return {
    component: ModalEdit,
    meta: {
      breadCrumb: {
        getter: (store: Store<RootState>, route: Route): string => (
          MOutgoing.find(route.params.ID).Description
        ),
        type: BREADCRUMB_TYPE.GETTER,
      },
      module: namespace.outgoings,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `outgoings${modes.EDIT}`,
    path: ':ID/edit',
    props: (route) => ({
      ID: route.params.ID,
      year: route.params.year,
      mode: modes.EDIT,
      routeBack: {
        name: 'outgoings',
      },
      showModal: true,
    }),
  };
};

export function outgoingsRouteNew(locale): IRouteConfig {
  return {
    beforeEnter: async(to, from, next) => {
      to.params.ID = await MOutgoing._create();

      next();
    },
    component: ModalEdit,
    meta: {
      label: String(i18n(locale).tc('common.property.untitled')),
      module: namespace.outgoings,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `outgoings${modes.NEW}`,
    path: 'new',
    props: (route) => ({
      ID: route.params.ID,
      year: route.params.year,
      mode: modes.NEW,
      routeBack: {
        name: 'outgoings',
      },
      showModal: true,
    }),
  };
};

/* PAGES */
export function outgoingsRouteShow(locale): IRouteConfig {
  return {
    children: [
      outgoingsRouteEdit(locale),
      outgoingsRouteNew(locale),
    ],
    component: Show,
    icon: {
      iconName: 'outgoingList',
      iconFamily: '',
    },
    meta: {
      label: String(i18n(locale).tc('outgoing.model', 2)),
      module: namespace.outgoings,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'outgoings',
    navItem: true,
    path: '/accounting/outgoing',
    props: {
      showModal: false,
    },
  };
};
