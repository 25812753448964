





















import Vue from 'vue';
import { findIndex as _findIndex } from 'lodash';
import { EventBusEvent } from '@/utils/eventBus';
import { INotification } from '@/typings/interface/INotification';
import { space } from '@/assets/styles/base/variables/space/space';

export default Vue.extend({
  data() {
    return {
      notificationList: [],
    };
  },

  methods: {
    close(notification: INotification): void {
      const index = _findIndex(
        this.notificationList,
        [
          'Id',
          notification.Id,
        ],
      );

      const target = {
        element: null,
        rectangle: null,
      };

      target.element = this.$el.querySelector(`#notification-${index}`);

      target.rectangle = target.element.getBoundingClientRect();

      target.element.style.top = `${target.rectangle.top - target.rectangle.height}px`;

      target.element.style.opacity = '0';

      this.notificationList.forEach((listItem, listItemIndex) => {
        if (listItemIndex < index) {
          const listItemNotification = {
            element: null,
            rectangle: null,
          };

          listItemNotification.element = this.$el.querySelector(`#notification-${listItemIndex}`);

          listItemNotification.rectangle = listItemNotification.element.getBoundingClientRect();

          listItemNotification.element.style.top = `${listItemNotification.rectangle.top - target.rectangle.height - (space.xxxSmall * 3)}px`;
        }
      });

      setTimeout(
        () => {
          this.notificationList.splice(index, 1);
        },
        300,
      );
    },

    convertEventPayload(data: any): INotification {
      if (typeof (data) === 'string') {
        return {
          Id: (Date.now() + Math.random()).toString().split('.')[0],
          Message: data,
          Lifetime: 6000,
        };
      }

      return data;
    },

    shiftDownNotifications(amount: Number): void {
      this.notificationList.forEach((notification, index) => {
        const notificationElement = this.$el.querySelector(`#notification-${index}`);

        const notificationElementPosition = notificationElement.getBoundingClientRect();

        notificationElement.style.top = `${notificationElementPosition.top + amount}px`;
      });
    },
  },

  mounted(): void {
    this.$bus.$on(EventBusEvent.NOTIFICATION.sent, (data) => {
      const newNotification = this.convertEventPayload(data);

      const hiddenNotification = this.$el.querySelector('#hidden-notification');

      hiddenNotification.innerText = newNotification.Message;

      const hiddenNotificationRectangle = hiddenNotification.getBoundingClientRect();

      setTimeout(
        () => {
          this.shiftDownNotifications(hiddenNotificationRectangle.height + space.xxxSmall);

          this.notificationList.push(newNotification);
          if (newNotification.Lifetime !== null) {
            setTimeout(
              () => {
                this.close(newNotification);
              },
              newNotification.Lifetime,
            );
          }
        },
        250,
      );
    });
  },
});
