






































import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import Vue, { VueConstructor } from 'vue';
import { debounce as _debounce, throttle as _throttle } from 'lodash';
import { ACTION, actionMap } from '@/utils/gridActions/actionMap';
import { DEBOUNCE_WAIT_RESIZE, THROTTLE_WAIT_SCROLL } from '@/utils/globals';
import { EventBusEvent } from '@/utils/eventBus';
import {
  IBaseContextMenuAction,
  IBaseContextMenuContextData,
  IBaseContextMenuVisibility,
} from '@/typings/interface/IBaseContextMenuEventPayloads';
import { IBaseGridActionListItem } from '@/components/base/grid/typings/interface/IBaseGridActionListItem';
import { POSITION } from '@/utils/positions';
import { size } from '@/assets/styles/base/variables/size/size';
import { zIndex } from '@/assets/styles/base/variables/z-index/z-index';

interface IBaseContextMenu {
  $bus: any;
  actions: Array<any>,
  calcAndSetPosition: any,
  componentKey: number,
  contextID: string,
  contextName: string,
  debouncedCalcAndSetPositionKeepVisibilityMethod: {},
  defaultActions: Array<IBaseGridActionListItem>,
  isVisible: boolean,
  localAppContentRef: any,
  marginRight: number,
  marginTop: number,
  onForceVisibility: any,
  position: any,
  positionLeft: number,
  positionTop: number,
  previousTrigger: Element,
  setZIndex: any,
  throttledCalcAndSetPositionKeepVisibilityMethod: null,
  trigger: Element,
  withCustomContent: boolean,
  zIndex: any,
}

export default (Vue as VueConstructor<Vue & IBaseContextMenu>).extend({
  name: 'BaseContextMenu',

  components: {
    BaseSvgIcon,
  },

  props: {
    scrollingContainerRef: {
      type: Node,
      default: null,
    },
  },

  data() {
    return {
      actions: [],

      defaultActions: [
        actionMap.get(ACTION.ADD),
        actionMap.get(ACTION.EDIT),
        actionMap.get(ACTION.DELETE),
      ],

      componentKey: 0,
      contextID: '',
      contextName: '',
      debouncedCalcAndSetPositionKeepVisibilityMethod: null,
      isVisible: false,
      localAppContentRef: null,
      marginRight: 8,
      marginTop: 8,
      position: POSITION.LEFT,
      positionLeft: 0,
      positionTop: 0,
      previousTrigger: null,
      throttledCalcAndSetPositionKeepVisibilityMethod: null,
      trigger: null,
      withCustomContent: false,
      zIndex: 0,
    };
  },

  watch: {
    scrollingContainerRef(newRef) {
      if (newRef !== null) {
        this.localAppContentRef = newRef;

        this.throttledCalcAndSetPositionKeepVisibilityMethod = _throttle(
          this.calcAndSetPositionKeepVisibility,
          THROTTLE_WAIT_SCROLL,
        );

        this.localAppContentRef.addEventListener(
          'scroll',
          this.throttledCalcAndSetPositionKeepVisibilityMethod,
        );

        this.$once('hook:beforeDestroy', () => {
          this.localAppContentRef.removeEventListener(
            'scroll',
            this.throttledCalcAndSetPositionKeepVisibilityMethod,
          );
        });
      }
    },
  },

  methods: {
    async calcAndSetPosition(visibilityOverride = false): Promise<any> {
      if (!visibilityOverride) {
        if (this.previousTrigger !== this.trigger) {
          this.isVisible = true;
        } else {
          this.isVisible = !this.isVisible;
        }

        if (!this.isVisible) {
          this.withCustomContent = false;

          this.componentKey++;
        }

        // visibility event
        const visibility: IBaseContextMenuVisibility = {
          contextName: this.contextName,
          ID: this.contextID,
          visible: this.isVisible,
        };

        this.$bus.$emit(
          EventBusEvent.CONTEXTMENU.visibilityChanged,
          visibility,
        );
      }

      await this.$nextTick();

      if (this.isVisible && this.$refs.actionList !== undefined) {
        // position.BOTTOM
        const triggerBoundingClientRect = this.trigger.getBoundingClientRect();

        const actionListRef: any = this.$refs.actionList;

        switch (this.position) {
          case POSITION.BOTTOM:
            this.positionLeft = (
              triggerBoundingClientRect.left -
              ((actionListRef.clientWidth - this.trigger.getBoundingClientRect().width) / 2)
            );

            this.positionTop = triggerBoundingClientRect.bottom + this.marginTop;

            break;

          case POSITION.BOTTOM_LEFT:
            this.positionLeft = triggerBoundingClientRect.left -
              ((actionListRef.clientWidth - triggerBoundingClientRect.width));

            this.positionTop = triggerBoundingClientRect.bottom + this.marginTop;

            break;

          case POSITION.LEFT:
            this.positionLeft = (
              triggerBoundingClientRect.left -
              actionListRef.clientWidth -
              this.marginRight
            );

            this.positionTop = triggerBoundingClientRect.top - (
              (actionListRef.clientHeight / 2) - (size.buttonHeight / 2)
            );

            break;
        }
      }

      this.previousTrigger = this.trigger;
    },

    async calcAndSetPositionKeepVisibility(): Promise<any> {
      return this.calcAndSetPosition(true);
    },

    clickEvent(actionEvent: string, actionName: string): void {
      // fallback
      if (actionEvent === undefined) {
        actionEvent = actionName;
      }

      const actionObject: IBaseContextMenuAction = {
        name: actionEvent,
        contextName: this.contextName,
        ID: this.contextID,
      };

      this.$bus.$emit(
        EventBusEvent.CONTEXTMENU.actionClicked,
        actionObject,
      );
    },

    setZIndex(index: string = null): void {
      if (index) {
        this.zIndex = index + zIndex.dropdown;
      }
    },

    onBeforeNavigate(): void {
      this.onForceVisibility(false);
    },

    async onContextMenuTriggerEvent(
      contextData: IBaseContextMenuContextData,
    ): Promise<any> {
      if (contextData.actions === undefined) {
        this.actions = this.defaultActions;
      } else {
        this.actions = contextData.actions;
      }

      this.contextID = contextData.ID;

      this.contextName = contextData.name;

      if (contextData.position !== undefined) {
        this.position = contextData.position;
      }

      this.trigger = contextData.trigger as Element;

      await this.calcAndSetPosition(false);

      if (contextData.zIndex !== undefined) {
        this.setZIndex(contextData.zIndex);
      }
    },

    onForceVisibility(value: boolean): void {
      this.isVisible = value;
    },

    async setCustomContent(component): Promise<any> {
      this.withCustomContent = true;

      await this.$nextTick();

      component.$mount(this.$refs.contextContent);
    },

    useDefaultContent(isDefault: boolean = true): void {
      if (this.withCustomContent !== isDefault) {
        this.withCustomContent = isDefault;

        this.position = POSITION.LEFT;

        this.componentKey++;
      }
    },
  },

  beforeMount(): void {
    this.$bus.$on(
      EventBusEvent.CONTEXTMENU.setCustomContent,
      this.setCustomContent,
    );

    this.$bus.$on(
      EventBusEvent.CONTEXTMENU.useDefaultContent,
      this.useDefaultContent,
    );

    this.$bus.$on(
      EventBusEvent.CONTEXTMENU.setIsVisible,
      this.onForceVisibility,
    );

    this.$bus.$on(
      EventBusEvent.CONTEXTMENU.update,
      this.onContextMenuTriggerEvent,
    );

    this.$bus.$on(
      EventBusEvent.NAVIGATION.beforeNavigate,
      this.onBeforeNavigate,
    );

    this.debouncedCalcAndSetPositionKeepVisibilityMethod = _debounce(
      this.calcAndSetPositionKeepVisibility,
      DEBOUNCE_WAIT_RESIZE,
    );

    window.addEventListener(
      'resize',
      this.debouncedCalcAndSetPositionKeepVisibilityMethod,
    );
  },

  beforeDestroy(): void {
    this.$bus.$off(
      EventBusEvent.CONTEXTMENU.setCustomContent,
      this.setCustomContent,
    );

    this.$bus.$off(
      EventBusEvent.CONTEXTMENU.useDefaultContent,
      this.useDefaultContent,
    );

    this.$bus.$off(
      EventBusEvent.CONTEXTMENU.setIsVisible,
      this.onForceVisibility,
    );

    this.$bus.$off(
      EventBusEvent.CONTEXTMENU.update,
      this.onContextMenuTriggerEvent,
    );

    this.$bus.$off(
      EventBusEvent.NAVIGATION.beforeNavigate,
      this.onBeforeNavigate,
    );

    window.removeEventListener(
      'resize',
      this.debouncedCalcAndSetPositionKeepVisibilityMethod,
    );
  },
});
