













































import BaseHeading from '@/components/base/BaseHeading.vue';
import BaseLoader from '@/components/base/BaseLoader.vue';
import { utIsEmpty } from '@/utils/empty';

export default {
  name: 'LayoutHeader',

  components: {
    BaseHeading,
    BaseLoader,
  },

  model: {
    event: 'update',
    prop: 'heading',
  },

  props: {
    heading: {
      type: String,
      default: '',
    },

    isEditable: {
      type: Boolean,
      default: false,
    },

    level: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classList(): object {
      return {
        [`header--${this.level}`]: !utIsEmpty(this.level),
        'header--with-image': this.hasImage,
      };
    },

    hasImage(): boolean {
      return this.$slots.image;
    },
  },
};
