import { i18n } from '@/locales/i18n';
import { IBaseGridActionListItem } from '@/components/base/grid/typings/interface/IBaseGridActionListItem';

enum ACTION {
  ADD = 'add',
  BON = 'bon',
  CANCEL = 'cancel',
  CONTEXT_MENU = 'contextMenu',
  CREATE_LINK = 'createLink',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
  EDIT = 'edit',
  PDF = 'pdf',
  RANK_DOWN = 'rankDown',
  RANK_UP = 'rankUp',
  REPLACE = 'replace',
  REVERSAL = 'reversal',
}

const locale = localStorage.getItem('locale');

const actionMap = new Map<string, IBaseGridActionListItem>([
  [
    ACTION.ADD,
    {
      event: 'add',
      label: String(i18n(locale).t('common.action.add')),
      name: 'add',
    },
  ],

  [
    ACTION.BON,
    {
      event: 'bon',
      label: String(i18n(locale).t('common.action.bon')),
      name: 'print',
    },
  ],

  [
    ACTION.CANCEL,
    {
      event: 'cancel',
      label: String(i18n(locale).t('common.action.cancel')),
      name: 'reversal',
    },
  ],

  [
    ACTION.CONTEXT_MENU,
    {
      contextMenuTrigger: true,
      name: 'more',
      tooltip: 'common.action.openContextMenu',
    },
  ],

  [
    ACTION.CREATE_LINK,
    {
      event: 'add',
      icon: 'link',
      label: String(i18n(locale).t('common.link.create')),
      name: 'add',
    },
  ],

  [
    ACTION.DELETE,
    {
      event: 'delete',
      icon: 'trashcan',
      label: String(i18n(locale).t('common.action.delete')),
      name: 'delete',
    },
  ],

  [
    ACTION.DUPLICATE,
    {
      event: 'duplicate',
      icon: 'square-on-dashed-square',
      label: String(i18n(locale).t('common.action.duplicate')),
      name: 'duplicate',
    },
  ],

  [
    ACTION.EDIT,
    {
      event: 'edit',
      icon: 'edit',
      label: String(i18n(locale).t('common.action.edit')),
      name: 'edit',
    },
  ],

  [
    ACTION.PDF,
    {
      event: 'pdf',
      label: String(i18n(locale).t('common.action.pdf')),
      name: 'pdf',
    },
  ],

  [
    ACTION.RANK_DOWN,
    {
      event: 'rank:lower',
      icon: 'arrow-down-line',
      label: String(i18n(locale).t('common.action.rankLower')),
      name: 'rank:lower',
    },
  ],

  [
    ACTION.RANK_UP,
    {
      event: 'rank:higher',
      icon: 'arrow-up-line',
      label: String(i18n(locale).t('common.action.rankHigher')),
      name: 'rank:higher',
    },
  ],

  [
    ACTION.REPLACE,
    {
      event: 'replace',
      icon: 'arrow-counterclockwise',
      name: 'replace',
      tooltip: 'common.action.replace',
    },
  ],

  [
    ACTION.REVERSAL,
    {
      event: 'reversal',
      label: String(i18n(locale).t('common.action.reversal')),
      name: 'pdf',
    },
  ],
]);

export {
  ACTION,
  actionMap,
};
