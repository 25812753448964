import { HTTP, http } from '@/http';

export const authorize = async(): Promise<any> => {
  /* if (process.env.NODE_ENV === 'development') {
    const { data: token } = await http.post(
      '/login',
      {
        username: process.env.VUE_APP_USERNAME,
        password: process.env.VUE_APP_PASSWORD,
      },
    );

    return token;
  }*/

  const token = localStorage.getItem('farmin_token') || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InpvdG1pYyIsImlhdCI6MTY2NDk1Njc3NywiZXhwIjoxNjY1MDE0Mzc3fQ.TpWSbBwj5Mdz4DViXDqRMe3BMiT_ZA5y5tVhTXxB320';
  const refreshToken = localStorage.getItem('farmin_refresh_token') || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InpvdG1pYyIsImlhdCI6MTY2NDk1Njc3N30.zmSEj4y4X1E3gEgKZEOGokdoaFtmr1bH6dH2tZjnAvA';

  if (token && refreshToken) {
    /* const { status } = await http.get(
      `${process.env.VUE_APP_AUTHORITY_URL}/user/check-token`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      },
    );

    if (status !== HTTP.OK) {
      return {
        token: '', status: status,
      };
    } */

    const status = HTTP.OK;

    return {
      token, status,
    };
  }

  return { };
};
