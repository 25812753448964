import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { http } from '@/http';
import { IUser } from '@/typings/interface/IUser';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';
import { PIC_SIZE } from '@/utils/imageSize';
import { utIsEmpty } from '@/utils/empty';

export class MUser extends Model implements IUser {
  static entity: TModel = MODEL.user;

  static primaryKey = 'Id';

  Id: string;

  ActiveOrganization: {
    ID: string,
    Name: string,
  };

  Email: string;

  FullName: string;

  IsLoggedIn: boolean;

  MainCountry: {
    ID: string,
    Name: string,
  };

  MainOrganization: {
    ID: string,
    Name: string,
  };

  ProfileImageID: string;

  ProfileImageLoading: boolean;

  ProfileImageName: string;

  UserName: string;

  userImageURL(
    imageSize: PIC_SIZE = PIC_SIZE.L192,
  ) {
    if (!this.ProfileImageID) {
      return '';
    }

    const fileExtension = this.ProfileImageName.split('.')[1];

    return `${Api.HOST}${Api.ENDPOINT.organization}/identity/${this.Id}/image/${this.ProfileImageID}/${fileExtension}?size=${imageSize}`;
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      ActiveOrganization: this.attr(null),

      Email: this.attr(''),

      FullName: this.attr(''),

      IsLoggedIn: this.boolean(false),

      MainCountry: this.attr(null),

      MainOrganization: this.attr(null),

      ProfileImageID: this.attr(''),

      ProfileImageLoading: this.boolean(false),

      ProfileImageName: this.attr(''),

      UserName: this.attr(''),
    };
  }

  static async _loadUser(): Promise<any> {
    /* const { response: { data: response } } = await MUser.api().get(
      `${Api.HOST}/account/userinfo`,
      {
        save: false,
      },
    );
    const user = MUser.hydrate(
      {
        ActiveOrganization: {
          ID: response.active_organization,
          Name: response.active_organization_name,
        },
        Email: response.email,
        FullName: response.name,
        IsLoggedIn: true,
        MainCountry: {
          ID: response.active_country,
          Name: response.active_country_name,
        },
        MainOrganization: {
          ID: response.main_organization,
          Name: response.main_organization_name,
        },
        ProfileImageID: response.picture,
        ProfileImageName: response.picture_name,
        Id: response.sub,
        UserName: response.preferred_username,
      },
    );*/

    const user = MUser.hydrate(
      {
        ActiveOrganization: {
          ID: 'fb2b3ab2-041d-47f9-a903-96f4726a827f',
          Name: 'Gemüsehof Zotter',
        },
        Email: 'michael@gemuese-zotter.at',
        FullName: 'Michael Zotter',
        IsLoggedIn: true,
        MainCountry: {
          ID: 'AUT',
          Name: 'Austria',
        },
        MainOrganization: {
          ID: 'fb2b3ab2-041d-47f9-a903-96f4726a827f',
          Name: 'Gemüsehof Zotter',
        },
        ProfileImageID: '',
        ProfileImageName: '',
        Id: '6fb982d4-e2f6-4a58-7454-08d82e6a5585',
        UserName: 'michael',
      },
    );

    MUser.insert({
      data: user,
    });

    return user;
  };

  static async _changePassword(password: string, userId: string): Promise<any> {
    const { response: { data: response } } = await MUser.api().post(
      `${Api.ENDPOINT.user}/usermanagement/${userId}/changepassword`,
      password,
      {
        save: false,
      },
    );

    return response;
  };

  static async _removeProfileImage(): Promise<any> {
    const user = MUser.query().first();

    MUser.update({
      Id: user.Id,
      ProfileImageLoading: true,
    });

    const fileExtension = user.ProfileImageName.split('.')[1];

    const response = await http.delete(
      `${Api.ENDPOINT.organization}/identity/${user.Id}/image/${user.ProfileImageID}/${fileExtension}`,
    );

    if (response.status === 204) {
      MUser.update(
        {
          Id: user.Id,
          ProfileImageID: '',
          ProfileImageName: '',
        },
      );
    }

    await MUser.api().delete(
      `${Api.ENDPOINT.user}/usermanagement/${user.Id}/DeleteProfileImage`,
      {
        save: false,
      },
    );

    MUser.update({
      Id: user.Id,
      ProfileImageLoading: false,
    });

    return response;
  };

  static async _setProfileImage(file: File): Promise<any> {
    const user = MUser.query().first();

    if (!utIsEmpty(user.ProfileImageID)) {
      await MUser._removeProfileImage();
    }

    MUser.update({
      Id: user.Id,
      ProfileImageLoading: true,
    });

    const formData = new FormData();

    formData.append(
      'file',
      file,
      file.name,
    );

    const response = await http.post(
      `${Api.ENDPOINT.organization}/identity/${user.Id}/image`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    if (response.status === 200) {
      MUser.update(
        {
          Id: user.Id,
          ProfileImageID: response.data.fileID,
          ProfileImageName: file.name,
        },
      );
    }

    const { response: { data } } = await MUser.api().put(
      `${Api.ENDPOINT.user}/usermanagement/${user.Id}/UpdateProfileImage?newImageID=${response.data.fileID}&newFileName=${file.name}`,
      null,
      {
        save: false,
      },
    );

    MUser.update({
      Id: user.Id,
      ProfileImageLoading: false,
    });

    return response;
  };
}
