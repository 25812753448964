import uuid4 from 'uuid/v4';
import { isEqual as _isEqual } from 'lodash';
import { Api } from '@/store/endpoints';
import { format } from 'date-fns';
import { formatDate } from '@/utils/filters/format';
import { i18n } from '@/locales/i18n';
import { IEarning } from '@/typings/interface/IEarning';
import { IInvoice } from '@/typings/interface/IInvoice';
import { MAccountNumber } from '@/models/MAccountNumber';
import { MCustomer } from '@/models/MCustomer';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { MEarning } from '@/models/MEarning';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';
import { MShoppingBag } from '@/models/MShoppingBag';
import { MTax } from './MTax';
import { PAYMENTMETHOD } from '@/utils/paymentMethod';
import { slipRangeList } from '@/utils/outgoing';
import { utIsEmpty } from '@/utils/empty';

const locale = localStorage.getItem('locale');

export class MInvoice extends Model implements IInvoice {
  static entity: TModel = MODEL.invoice;

  static primaryKey = 'Id';

  Id: string;

  AdditionalText: string;

  CancelInvoiceDate: string;

  CancelInvoiceNumber: string;

  CustomerID: string;

  Date: string;

  DatePaid: string;

  DeliveryInvoice: boolean;

  InvoiceNumber: string;

  PaymentMethod: string;

  Sales: Array<MSales>;

  get asListData() {
    const customer = MCustomer.find(this.CustomerID);

    const isReversal = MInvoice
      .query()
      .where('CancelInvoiceNumber', this.InvoiceNumber)
      .first();

    return {
      additionalText: this.AdditionalText,
      customer: customer,
      customerName: (customer) ? customer.name : '',
      delivery: this.DeliveryInvoice ? i18n(locale).t('common.property.yes') : i18n(locale).t('common.property.no'),
      invoiceNumber: this.InvoiceNumber,
      reversal: isReversal ? i18n(locale).t('common.property.yes') : i18n(locale).t('common.property.no'),
      canceled: (!isReversal && !utIsEmpty(this.CancelInvoiceNumber)) ? i18n(locale).t('invoice.property.canceled') : '',
      datePaid: (this.DatePaid) ? formatDate(this.DatePaid) : '',
      actions: this.Id,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      AdditionalText: this.string(''),

      CancelInvoiceDate: this.string(''),

      CancelInvoiceNumber: this.string(''),

      CustomerID: this.string(''),

      Date: this.string(''),

      DatePaid: this.attr(''),

      DeliveryInvoice: this.attr(false),

      InvoiceNumber: this.string(''),

      PaymentMethod: this.attr(''),

      Sales: this.hasMany(
        MSales,
        'InvoiceID',
      ),
    };
  }

  static async createInvoice(
    shoppingBag: MShoppingBag,
    additionalText: string = '',
    paymentMethod: string = PAYMENTMETHOD.CASH,
  ): Promise<any> {
    const ongoingNumber = await MInvoice._getOngoingNumber();

    const invoice = MInvoice.hydrate({
      AdditionalText: additionalText,
      CustomerID: shoppingBag.CustomerID,
      Date: new Date().toISOString(),
      InvoiceNumber: ongoingNumber,
      PaymentMethod: paymentMethod,
    });

    await MInvoice.insert({
      data: invoice,
    });

    await Promise.all(shoppingBag.Sales.map(async(sale) => {
      await MSales.update({
        where: sale.Id,
        data: {
          ...sale,
          InvoiceID: invoice.Id,
        },
      });
    }));

    return MInvoice.query().withAllRecursive().find(invoice.Id);
  };

  static async _create(
    shoppingBag: MShoppingBag,
    additionalText: string = '',
    paymentMethod: string = PAYMENTMETHOD.CASH,
  ): Promise<MInvoice> {
    const ongoingNumber = await MInvoice._getOngoingNumber();

    const datePaid = _isEqual(paymentMethod, PAYMENTMETHOD.CASH) ? new Date().toISOString() : '';

    const invoice = MInvoice.hydrate({
      AdditionalText: additionalText,
      CustomerID: shoppingBag.CustomerID,
      Date: new Date().toISOString(),
      DatePaid: datePaid,
      InvoiceNumber: ongoingNumber,
      PaymentMethod: paymentMethod,
    });

    const { response: { data: response } } = await MInvoice.api().post(
      Api.ENDPOINT.invoice,
      invoice,
      {
        save: false,
      },
    );

    MInvoice.insert({
      data: invoice,
    });

    /* const groupedSales = {};

    const year = invoice.Date.substr(0, 4);

    const oldSlipNumber = await MEarning._getLastSlipNumber(year, invoice.SlipRange);

    let slipNumber = Number(oldSlipNumber);

    shoppingBag.Sales.forEach(sale => {
      const product = MProduct.find(sale.ProductID);

      const accountNumber = MAccountNumber.find(product.AccountNumberId);

      const tax = MTax.find(product.TaxId);

      if (groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] === undefined) {
        slipNumber += 1;

        groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] = {
          Id: uuid4(),
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (paymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: new Date().toISOString(),
          Description: '',
          GrossAmount: sale.Price,
          InvoiceNumber: ongoingNumber,
          SlipNumber: slipNumber,
          SlipRange: (paymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: Number(tax.Percent),
        } as IEarning;
      } else {
        groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] = {
          Id: groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].Id,
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (paymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: new Date().toISOString(),
          Description: '',
          GrossAmount: String(Number(sale.Price) + Number(groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].GrossAmount)),
          InvoiceNumber: ongoingNumber,
          SlipNumber: groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].SlipNumber,
          SlipRange: (paymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: Number(tax.Percent),
        } as IEarning;
      }
    });*/

    await Promise.all(shoppingBag.Sales.map(async(sale) => {
      await MSales.update({
        where: sale.Id,
        data: {
          ...sale,
          InvoiceID: invoice.Id,
        },
      });

      const newSale = MSales.find(sale.Id);

      await MSales._update(newSale);
    }));

    const createdInvoice = MInvoice.query().withAllRecursive().find(invoice.Id);
    /*
    const groupedSalesValues = Object.values(groupedSales);

    if (paymentMethod !== PAYMENTMETHOD.TRANSACTION) {
      await Promise.all(groupedSalesValues.map(async(groupedSale: IEarning) => {
        await MEarning._create(groupedSale);
      }));
    }*/

    return createdInvoice;
  };

  static async _createDeliveryInvoice(
    deliveryBagList: Array<MDeliveryBag>,
    additionalText: string = '',
    ongoingNumber = 0,
  ): Promise<MInvoice> {
    const invoice = MInvoice.hydrate({
      AdditionalText: additionalText,
      CustomerID: deliveryBagList[0].CustomerID,
      Date: new Date().toISOString(),
      DatePaid: '',
      InvoiceNumber: ongoingNumber,
      PaymentMethod: PAYMENTMETHOD.TRANSACTION,
      DeliveryInvoice: true,
    });

    const { response: { data: response } } = await MInvoice.api().post(
      Api.ENDPOINT.invoice,
      invoice,
      {
        save: false,
      },
    );

    await MInvoice.insert({
      data: invoice,
    });

    await Promise.all(deliveryBagList.map(async(deliveryBag) => {
      await MDeliveryBag.update({
        where: deliveryBag.Id,
        data: {
          ...deliveryBag,
          InvoiceID: invoice.Id,
        },
      });

      const newDeliveryBag = MDeliveryBag.find(deliveryBag.Id);

      await MDeliveryBag._update(newDeliveryBag);

      await Promise.all(deliveryBag.Sales.map(async(sale) => {
        await MSales.update({
          where: sale.Id,
          data: {
            ...sale,
            InvoiceID: invoice.Id,
          },
        });

        const newSale = MSales.find(sale.Id);

        await MSales._update(newSale);
      }));
    }));

    const createdInvoice = MInvoice.query().withAllRecursive().find(invoice.Id);

    return createdInvoice;
  };

  static async _cancel(Id: string): Promise<any> {
    const invoiceToCancel = MInvoice.query().withAllRecursive().find(Id);

    const invoice = MInvoice.hydrate({
      InvoiceNumber: invoiceToCancel.CancelInvoiceNumber,
      CancelInvoiceDate: invoiceToCancel.CancelInvoiceDate,
      CancelInvoiceNumber: invoiceToCancel.CancelInvoiceNumber,
      CustomerID: invoiceToCancel.CustomerID,
      Date: invoiceToCancel.Date,
      DatePaid: '',
      PaymentMethod: invoiceToCancel.PaymentMethod,
    });

    await Promise.all(invoiceToCancel.Sales.map(async(sale) => {
      const newSale = {
        ...sale,
        Id: uuid4(),
        InvoiceID: invoice.Id,
        Price: (Number(sale.Price) * -1),
      };

      await MSales._create('', newSale);

      MSales.insert({
        data: newSale,
      });
    }));

    const { response: { data: response } } = await MInvoice.api().post(
      Api.ENDPOINT.invoice,
      invoice,
      {
        save: false,
      },
    );

    await MInvoice.insert({
      data: invoice,
    });

    const groupedSales = {};

    const year = invoice.DatePaid.substr(0, 4);

    const oldSlipNumber = await MEarning._getLastSlipNumber(year, invoice.SlipRange);

    let slipNumber = Number(oldSlipNumber);

    invoiceToCancel.Sales.forEach(sale => {
      const product = MProduct.find(sale.ProductID);

      const accountNumber = MAccountNumber.find(product.AccountNumberId);

      if (groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] === undefined) {
        slipNumber += 1;

        groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] = {
          Id: uuid4(),
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (invoiceToCancel.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: new Date().toISOString(),
          Description: String(i18n(locale).t('earning.property.reversal')),
          GrossAmount: sale.Price,
          InvoiceNumber: invoice.InvoiceNumber,
          SlipNumber: slipNumber,
          SlipRange: (invoiceToCancel.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: accountNumber.Tax,
        } as IEarning;
      } else {
        groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] = {
          Id: groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].Id,
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (invoiceToCancel.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: new Date().toISOString(),
          Description: String(i18n(locale).t('earning.property.reversal')),
          GrossAmount: String(Number(sale.Price) + Number(groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].GrossAmount)),
          InvoiceNumber: invoice.InvoiceNumber,
          SlipNumber: groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].SlipNumber,
          SlipRange: (invoiceToCancel.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: accountNumber.Tax,
        } as IEarning;
      }
    });

    return invoice.Id;
  };

  static async _cancelDeliveryInvoice(Id: string): Promise<any> {
    const invoiceToCancel = MInvoice.query().withAllRecursive().find(Id);

    const invoice = MInvoice.hydrate({
      InvoiceNumber: invoiceToCancel.CancelInvoiceNumber,
      CancelInvoiceDate: invoiceToCancel.CancelInvoiceDate,
      CancelInvoiceNumber: invoiceToCancel.CancelInvoiceNumber,
      CustomerID: invoiceToCancel.CustomerID,
      Date: invoiceToCancel.Date,
      DatePaid: '',
      DeliveryInvoice: true,
      PaymentMethod: invoiceToCancel.PaymentMethod,
    });

    await Promise.all(invoiceToCancel.Sales.map(async(sale) => {
      const newSale = {
        ...sale,
        Id: uuid4(),
        InvoiceID: invoice.Id,
        ShoppingBagID: '',
        Price: (Number(sale.Price) * -1),
      };

      await MSales._create('', newSale);

      MSales.insert({
        data: newSale,
      });
    }));

    const { response: { data: response } } = await MInvoice.api().post(
      Api.ENDPOINT.invoice,
      invoice,
      {
        save: false,
      },
    );

    MInvoice.insert({
      data: invoice,
    });

    const groupedSales = {};

    const year = invoice.DatePaid.substr(0, 4);

    const oldSlipNumber = await MEarning._getLastSlipNumber(year, invoice.SlipRange);

    let slipNumber = Number(oldSlipNumber);

    invoiceToCancel.Sales.forEach(sale => {
      const product = MProduct.find(sale.ProductID);

      const accountNumber = MAccountNumber.find(product.AccountNumberId);

      if (groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] === undefined) {
        slipNumber += 1;

        groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] = {
          Id: uuid4(),
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (invoiceToCancel.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: new Date().toISOString(),
          Description: String(i18n(locale).t('earning.property.reversal')),
          GrossAmount: sale.Price,
          InvoiceNumber: invoice.InvoiceNumber,
          SlipNumber: slipNumber,
          SlipRange: (invoiceToCancel.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: accountNumber.Tax,
        } as IEarning;
      } else {
        groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] = {
          Id: groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].Id,
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (invoiceToCancel.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: new Date().toISOString(),
          Description: String(i18n(locale).t('earning.property.reversal')),
          GrossAmount: String(Number(sale.Price) + Number(groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].GrossAmount)),
          InvoiceNumber: invoice.InvoiceNumber,
          SlipNumber: groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].SlipNumber,
          SlipRange: (invoiceToCancel.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: accountNumber.Tax,
        } as IEarning;
      }
    });

    return invoice.Id;
  };

  static async _fetch(): Promise<any> {
    await MInvoice.deleteAll();

    await MInvoice.api().get(
      `${Api.ENDPOINT.invoice}`,
    );
  };

  static async _getOngoingNumber(): Promise<any> {
    const { response: { data: ongoingNumber } } = await MInvoice.api().get(
      `${Api.ENDPOINT.invoice}/ongoingNumber`,
    );

    return ongoingNumber;
  };

  static async _update(invoice): Promise<any> {
    MInvoice.api().put(
      `${Api.ENDPOINT.invoice}/${encodeURIComponent(invoice.Id)}`,
      invoice.$toJson(),
      {
        save: false,
      },
    );

    const newInvoice = MInvoice.query().withAllRecursive().find(invoice.Id);

    const groupedSales = {};

    const year = invoice.DatePaid.substr(0, 4);

    const oldSlipNumber = await MEarning._getLastSlipNumber(year, invoice.SlipRange);

    let slipNumber = Number(oldSlipNumber);

    newInvoice.Sales.forEach(sale => {
      const product = MProduct.find(sale.ProductID);

      const accountNumber = MAccountNumber.find(product.AccountNumberId);

      if (groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] === undefined) {
        slipNumber += 1;

        groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] = {
          Id: uuid4(),
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (invoice.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: invoice.DatePaid,
          Description: '',
          GrossAmount: sale.Price,
          InvoiceNumber: invoice.InvoiceNumber,
          SlipNumber: slipNumber,
          SlipRange: (invoice.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: accountNumber.Tax,
        } as IEarning;
      } else {
        groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`] = {
          Id: groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].Id,
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (invoice.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: invoice.DatePaid,
          Description: '',
          GrossAmount: String(Number(sale.Price) + Number(groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].GrossAmount)),
          InvoiceNumber: invoice.InvoiceNumber,
          SlipNumber: groupedSales[`${accountNumber.AccountingNumber}-${accountNumber.Tax}`].SlipNumber,
          SlipRange: (invoice.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: accountNumber.Tax,
        } as IEarning;
      }
    });
  };

  static async _getInvoiceYears(): Promise<any> {
    const { response: { data: yearList } } = await MInvoice.api().get(
      `${Api.ENDPOINT.invoice}/years`,
      {
        save: false,
      },
    );

    return yearList;
  };

  static getEarningData(invoice) {
    const groupedSales = [];
    const earningList = [];
    let isBusiness = false;

    const customer = MCustomer.find(invoice.CustomerID);

    if (customer !== undefined && customer !== null) {
      if (customer.CompanyName !== '' || customer.Uid !== '') {
        isBusiness = true;
      }
    }

    invoice.Sales.forEach(sale => {
      const product = MProduct.find(sale.ProductID);

      const accountNumber = MAccountNumber.find(product.AccountNumberId);

      const tax = MTax.find(product.TaxId);

      let taxPercent = Number(tax.Percent);

      if (isBusiness && taxPercent === 10) {
        taxPercent = 13;
      }

      const exists = groupedSales.findIndex(grouped => {
        return grouped.Id === `${accountNumber.AccountingNumber}-${taxPercent}`;
      });

      if (exists > -1) {
        groupedSales[exists].GrossAmount = String(Number(sale.Price) + Number(groupedSales[exists].GrossAmount));
      } else {
        groupedSales.push({
          Id: `${accountNumber.AccountingNumber}-${taxPercent}`,
          AccountNumberID: String(accountNumber.AccountingNumber),
          BankCashID: (invoice.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? 2800
            : 2700,
          Date: invoice.DatePaid,
          Description: '',
          GrossAmount: sale.Price,
          InvoiceNumber: invoice.InvoiceNumber,
          SlipRange: (invoice.PaymentMethod === PAYMENTMETHOD.TRANSACTION)
            ? String(i18n(locale).t('earning.slipRange.BK'))
            : String(i18n(locale).t('earning.slipRange.CS')),
          Tax: taxPercent,
        } as IEarning);
      }
    });

    groupedSales.forEach((groupedSale: IEarning) => {
      const account = groupedSale.AccountNumberID;

      const accountNumber = MAccountNumber.query().where((accountNumber) => {
        return Number(accountNumber.AccountingNumber) === Number(groupedSale.AccountNumberID);
      }).get();

      const bankCash = groupedSale.BankCashID;

      let slip = groupedSale.SlipRange;

      slipRangeList.forEach(slipRange => {
        if (slipRange.Id === groupedSale.SlipRange) {
          slip = slipRange.Name;
        }
      });

      earningList.push({
        accountNumber: groupedSale.AccountNumberID,
        accountNumberName: accountNumber[0].Name,
        account: `${account}`,
        bankCash: groupedSale.BankCashID,
        bank: `${bankCash}`,
        actions: groupedSale.Id,
        description: groupedSale.Description,
        slip: `${slip}-`,
        slipRange: groupedSale.SlipRange,
        invoiceNumber: invoice.InvoiceNumber,
        tax: `${groupedSale.Tax} %`,
        date: utIsEmpty(invoice.DatePaid) ? '' : format(new Date(invoice.DatePaid), 'yyyy-MM-dd'),
        grossAmount: MSales.formatedPrice(groupedSale.GrossAmount),
        taxAmount: MSales.formatedPrice((((Number(groupedSale.GrossAmount)) / (100 + Number(groupedSale.Tax))) * Number(groupedSale.Tax))),
        netAmount: MSales.formatedPrice(((Number(groupedSale.GrossAmount)) - (((Number(groupedSale.GrossAmount)) / (100 + Number(groupedSale.Tax))) * Number(groupedSale.Tax)))),
      });
    });

    return earningList;
  }
}
