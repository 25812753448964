export enum VISUAL_SIZE {
  DEFAULT = 'visual1408l',
  L384 = 'visual384l',
  L1408 = 'visual1408l',
  ORIGINAL = 'visual'
}

export enum PIC_SIZE {
  DEFAULT = 'pics1408l',
  L384 = 'pics384l',
  L1408 = 'pics1408l',
  L192 = 'pics192l',
  ORIGINAL = 'pics'
}
