import { Module, ModuleTree } from 'vuex';
import { RootState } from '@/store/';
import { User } from '@/store/user/user.store.definition';

// TODO TF Move namespace somewhere better
export enum namespace {
  accountNumber = 'accountNumber',
  area = 'area',
  batch = 'batch',
  category = 'category',
  charge = 'charge',
  customer = 'customer',
  delivery = 'delivery',
  earnings = 'earnings',
  hardwareType = 'hardwareType',
  income = 'income',
  invoice = 'invoice',
  notification = 'notification',
  organization = 'organization',
  outgoings = 'outgoings',
  product = 'product',
  shoppingBag = 'shoppingBag',
  success = 'success',
  supplier = 'supplier',
  tax = 'tax',
  user = 'user',
}

export interface IRootModules extends ModuleTree<RootState> {
  user: Module<User.State, RootState>,
}
