import { common } from '@/locales/en/partials/_common';

export const delivery = {
  action: {
    add: `${common.action.add} Delivery`,
    new: `${common.action.new} Delivery`,
  },

  model: 'Delivery | Deliveries',

  property: {
    arranger: 'Arranger',
    date: 'Date',
    number: 'No',
    signature: 'Signature',
  },

  text: {
    deliveryReceived: 'Delivery received on',
  },
};
