export const success = {
  model: 'Erfolgsrechnung | Erfolgsrechnung',

  property: {
    accountName: 'Kontobezeichnung',
    accountingNumber: 'Code',
    income: 'Einnahmen',
    outcome: 'Ausgaben',
    success: 'Erfolg',
  },
};
