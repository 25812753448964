import { common } from '@/locales/en/partials/_common';

export const hardware = {
  action: {
    add: `${common.action.add} Hardware`,
    delete: `${common.action.delete} Hardware`,
  },

  alert: {
    delete: 'Do you want to delete hardware "{hardwareTypeName}"?',
  },

  message: {
    areaHelperText: 'No area has been added to this sales location yet.',
    empty: 'No hardware has been added yet.',
  },

  model: 'Hardware | Hardware',
};
