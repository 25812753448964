import axios from 'axios';
import Vue from 'vue';
import Vuex, { GetterTree, Store } from 'vuex';
import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import { actions } from '@/store/actions';
import { IRootModules } from '@/store/index.definition';
import { IRootState } from '@/typings/interface/IRootState';
import { mutations } from '@/store/mutations';
import { UserStore } from '@/store/user/user';
import { vuexOrmDatabase } from '@/store/orm/database';

Vue.use(Vuex);

export class RootState implements IRootState {
  default: {
    ID: string,
    userID: string,
  } = {
    ID: process.env.VUE_APP_DEFAULT_ORGANIZATION_ID,
    userID: '',
  };
}

const getters = <GetterTree<RootState, any>>{
  apiBaseUrl: state => {
    return `${process.env.VUE_APP_WEB_API_URL}/api/${encodeURIComponent(state.default.ID)}`;
  },

  apiBaseUrlV02: state => {
    return `${process.env.VUE_APP_WEB_API_URL}/api/0.2/${encodeURIComponent(state.default.ID)}`;
  },
};

const store = (axiosConfig): Store<RootState> => {
  VuexORM.use(
    VuexORMAxios,
    {
      axios,
      ...axiosConfig,
    },
  );

  const modules: IRootModules = {
    user: new UserStore(),
  };

  return new Vuex.Store<RootState>({
    actions: actions,
    getters: getters,
    modules: modules,
    mutations: mutations,
    plugins: [
      VuexORM.install(vuexOrmDatabase),
    ],
    state: new RootState(),
    strict: process.env.NODE_ENV !== 'production',
  });
};

export { store };
