import { common } from '@/locales/en/partials/_common';

export const supplier = {
  model: 'Supplier | Suppliers',

  action: {
    add: `${common.action.add} Supplier`,
    delete: `${common.action.delete} Supplier`,
  },

  alert: {
    delete: 'Do you want to delete the supplier "{supplierName}"?',
  },

  message: {
    empty: 'No supplier added yet',
    noResult: 'No supplier found',
  },

  property: {
    name: 'Supplier Name',
  },
};
