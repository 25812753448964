export const user = {
  message: {
    currentPasswordError: 'Ihr aktuelles Passwort ist falsch.',
  },

  password: {
    action: {
      change: 'Passwort ändern',
      new: 'Neues Passwort',
    },

    notification: {
      success: 'Ihr Passwort wurde erfolgreich geändert!',
    },

    properties: {
      current: 'Aktuelles Passwort',
      repeat: 'Aktuelles Passwort wiederholen',
    },
  },

  validation: {
    allRequired: 'Alle Felder sind verpflichtend.',
    match: 'Die neuen Passwörter stimmen nicht überein.',
  },
};
