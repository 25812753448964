import { common } from '@/locales/en/partials/_common';

export const category = {
  model: 'Kategorie | Kategorien',

  action: {
    add: 'Kategorie hinzufügen',
    delete: 'Kategorie löschen',
  },

  alert: {
    delete: 'Willst du die Kategorie "{categoryName}" wirklich löschen?',
  },

  message: {
    noResult: 'Es wurden keine Kategorien gefunden!',
  },

  property: {
    name: 'Kategoriename',
  },
};
