export const success = {
  model: 'Income Statement | Income Statement',

  property: {
    accountName: 'Account Description',
    accountingNumber: 'Code',
    income: 'Income',
    outcome: 'Outgoing',
    success: 'Proceeds',
  },
};
