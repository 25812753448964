














import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseImagePlaceholder',

  props: {
    width: {
      type: String,
      default: null,
    },

    height: {
      type: String,
      default: null,
    },
  },

  computed: {
    containerStyle() {
      return `width: ${this.width}; height: ${this.height};`;
    },

    imageStyle() {
      return `width: ${this.width}; height: ${this.height};`;
    },
  },
});
