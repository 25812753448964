import { accountingRouteShow } from '@/views/accounting/routes';
import { definitionRouteShow } from '@/views/definitions/routes';
import { deliveryBagRouteShow } from '@/views/deliveryBag/routes';
import { notificationRouteShow } from '@/views/notifications/routes';
import { reportsRouteShow } from './views/reports/routes';
import { RouteConfig } from 'vue-router';
import { salesRouteShow } from '@/views/sales/routes';
import { settingsRouteShow } from '@/views/profile/routes';
import { shoppingBagRouteShow } from '@/views/shoppingBag/routes';

export function routeList(locale): Array<RouteConfig> {
  return [
    {
      path: '*',
      redirect: '/shopping-bag',
    },

    {
      path: '/',
      redirect: '/shopping-bag',
    },

    shoppingBagRouteShow(locale),

    deliveryBagRouteShow(locale),

    // Sales
    salesRouteShow(locale),

    // Accounting
    accountingRouteShow(locale),

    // Definitions
    definitionRouteShow(locale),

    // Reports
    reportsRouteShow(locale),

    // Settings
    settingsRouteShow(locale),

    // Notifications
    notificationRouteShow(locale),
  ];
};
