





















































































import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import Vue, { VueConstructor } from 'vue';
import {
  isEqual as _isEqual,
  isNil as _isNil,
  isNull as _isNull,
  isNumber as _isNumber,
} from 'lodash';
import { CurrencyDirective } from 'vue-currency-input';
import { mixinComponentUID } from '@/components/mixins/componentUID';
import { SELECTIONSTATE, TSelectionState } from '@/utils/states';
import { utIsEmpty } from '@/utils/empty';

/**
 * Input Fields are used to allow users to provide text input when the expected
 * input is short. Input Field has a range of options and supports several text
 * formats including numbers. For longer input, use the `FormTextarea` element.
 */

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseInputField',

  components: {
    BaseCheckbox,
    BaseSvgIcon,
  },

  directives: {
    currency: CurrencyDirective,
  },

  mixins: [
    mixinComponentUID,
  ],

  props: {
    currency: {
      type: String,
      default: 'EUR',
    },

    decimalPlace: {
      type: [
        Number,
        String,
      ],
      default: 0,
    },

    focus: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: '',
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    label: {
      type: [
        String,
        null,
      ],
      default: null,
    },

    masked: {
      type: Boolean,
      default: false,
    },

    name: {
      type: String,
      default: '',
    },

    notice: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    showPassword: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: 'text',
    },

    value: {
      type: [
        String,
        Number,
      ],
      default: '',
    },

    variant: {
      type: String,
      default: 'standard',
    },
  },

  data() {
    return {
      animated: this.focus,
      currentValue: '',
      focused: false,
      globalOptions: {
        allowNegative: false,
        autoDecimalMode: true,
        currency: this.currency,
        locale: navigator.language,
        precision: this.decimalPlace,
      },
      showExpansion: false,
      showMask: false,
    };
  },

  computed: {
    animate(): boolean {
      if (_isNumber(this.value) && !_isNil(this.label)) {
        return true;
      }

      if (!utIsEmpty(this.value) && !_isNil(this.label)) {
        return true;
      }

      return utIsEmpty(this.value) && this.focused && !this.readonly && !_isNil(this.label);
    },

    defaultPasswordShowState(): TSelectionState {
      if (window.matchMedia('(pointer: coarse)').matches) {
        this.toggleExpansion(true);

        return SELECTIONSTATE.CHECKED;
      }

      return SELECTIONSTATE.UNCHECKED;
    },

    getMask(): object {
      if (this.masked) {
        return this.globalOptions;
      }

      return undefined;
    },

    getPlaceholder(): string {
      if (utIsEmpty(this.value) && !this.readonly &&
        ((this.focused && !_isNil(this.label)) || _isNil(this.label))) {
        return this.placeholder;
      }

      return '';
    },

    inputFieldClasses(): object {
      return {
        [`input-field--${this.variant}`]: true,
        'input-field--animated': this.animate,
        'input-field--expanded': this.isExpanded,
        'input-field--focused': this.focused,
        'input-field--invalid': this.isInvalid && !this.focused,
        'input-field--readonly': this.readonly,
        'input-field--with-icon': this.icon.length > 0 || this.readonly,
      };
    },

    isExpandable(): boolean {
      return this.isPasswordField;
    },

    isExpanded(): boolean {
      return !utIsEmpty(this.value) && this.showExpansion;
    },

    isInvalid(): boolean {
      return this.invalid;
    },

    isPasswordField(): boolean {
      return (this.type === 'password' && !this.readonly && this.showPassword);
    },

    showNotice(): boolean {
      return (!utIsEmpty(this.notice) && this.isInvalid && !this.focused);
    },

    internalValue: {
      get(): string | number {
        if (
          this.masked ||
          (this.decimalPlace && !this.masked)
        ) {
          return this.value.replace('.', ',');
        }

        return this.value;
      },

      set(value: string): void {
        if (!this.readonly) {
          let newValue = value;

          if (this.decimalPlace && !this.masked) {
            const newVal = parseFloat(value.replace(',', '.'));

            if (!isNaN(newVal)) {
              newValue = newVal.toFixed(this.decimalPlace);
            }

            if (utIsEmpty(value)) {
              newValue = '0';
            }
          }

          this.currentValue = newValue;

          if (!_isEqual(newValue, this.value) && !this.masked && !this.decimalPlace) {
            this.$emit('input', newValue);
          }
        }
      },
    },
  },

  watch: {
    focus() {
      if (this.focus) {
        this.focused = true;
      } else {
        this.focused = (this.animated)
          ? this.animate
          : this.focus;

        this.animated = false;
      }
    },

    value() {
      const expansionContent = this.$refs.expansionContent;

      if (!utIsEmpty(expansionContent)) {
        expansionContent.scrollLeft = expansionContent.scrollWidth;
      }
    },
  },

  methods: {
    autoFillStarted(event: AnimationEvent) {
      if (event.animationName.includes('autoFillStart')) {
        this.$el.classList.add('input-field--auto-filled');
      }
    },

    blur(): void {
      this.focused = (_isNull(this.focus)) ? false : this.focus;

      this.$emit('focus', false);

      if (this.masked) {
        this.$emit('input', this.currentValue.replace(',', '.'));
      }

      if (!this.masked && this.decimalPlace) {
        this.$emit('input', this.currentValue.replace(',', '.'));
      }

      this.$emit('blur', this.currentValue);
    },

    clear(): void {
      this.$emit('input', '');
    },

    setFocus(): void {
      this.focused = true;

      this.$emit('focus', true);
    },

    toggleExpansion(checkboxState: boolean): void {
      this.showExpansion = checkboxState;
    },
  },

  async mounted(): Promise<any> {
    await this.$nextTick();

    const reference: HTMLElement = this.$refs.input;

    if (reference) {
      reference.addEventListener(
        'animationstart',
        this.autoFillStarted,
      );
    }

    this.currentValue = this.value;
  },

  beforeDestroy(): void {
    const reference: HTMLElement = this.$refs.input;

    reference.removeEventListener(
      'animationstart',
      this.autoFillStarted,
    );
  },
});
