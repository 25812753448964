export const accountNumber = {
  accounting: {
    earnings: 'Einnahmen',
    expenses: 'Ausgaben',
  },

  accountType: {
    bank: 'Bank',
    cash: 'Kassa',
    effectless: 'Effectless',
    normal: 'Normal',
  },

  model: 'Kontonummer | Kontonummern',

  alert: {
    delete: 'Willst du die Kontonummer "{name}" wirklich löschen?',
  },

  noResult: 'Kein Kontonummern hinzugefügt.',

  property: {
    accounting: 'Buchaltung',
    accountingNumber: 'Kontonummer',
    accountType: 'Kontotyp',
    name: 'Beschreibung',
    tax: 'Steuer',
    taxType: 'Steuertyp',
  },

  taxType: {
    icpurchase: 'Innergemeinschaftliche Lieferung',
    normal: 'Normalkonto',
    nosales: 'Keine Umsätze',
    reversecharge: 'Reverse Charge',
  },
};
