import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { modes } from '@/utils/TModes';
import { namespace } from '@/store/index.definition';
import { Router } from '@/utils/router';
import { VIEW } from '@/utils/views';

const Show = () => import(/* webpackChunkName: "invoice" */ './InvoiceShow.vue');
const Edit = () => import(/* webpackChunkName: "invoice" */ './modals/InvoiceEdit.vue');
const SendMail = () => import(/* webpackChunkName: "invoice" */ './modals/SendMail.vue');
const TabInvoice = () => import(/* webpackChunkName: "invoice" */ './tabs/TabInvoice.vue');

/* MODALS */
export function invoiceSendMail(locale): IRouteConfig {
  return {
    component: SendMail,
    meta: {
      label: String(i18n(locale).tc('common.property.send', 1)),
      module: namespace.invoice,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `invoiceSendMail${modes.EDIT}`,
    path: ':invoiceID/sendMail',
    props: (route) => ({
      invoiceID: route.params.invoiceID,
      mode: modes.EDIT,
      routeBack: {
        name: `invoice${modes.EDIT}`,
        params: {
          invoiceID: route.params.invoiceID,
          routeBack: route.params.routeToInvoice,
        },
      },
      showModal: true,
    }),
  };
};

export function invoiceRouteEdit(locale): IRouteConfig {
  return {
    component: Edit,
    meta: {
      label: String(i18n(locale).tc('invoice.model', 1)),
      module: namespace.invoice,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `invoice-${modes.EDIT}`,
    path: ':invoiceID',
    props: (route) => ({
      invoiceID: route.params.invoiceID,
      mode: modes.EDIT,
      routeBack: {
        name: route.params.routeBack,
      },
      showModal: true,
    }),
  };
};

export function invoiceDeliverySendMail(locale): IRouteConfig {
  return {
    component: SendMail,
    meta: {
      label: String(i18n(locale).tc('common.property.send', 1)),
      module: namespace.invoice,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `invoiceDeliverySendMail${modes.EDIT}`,
    path: ':invoiceID/sendMail',
    props: (route) => ({
      invoiceID: route.params.invoiceID,
      mode: modes.EDIT,
      routeBack: {
        name: `invoice${modes.EDIT}`,
        params: {
          invoiceID: route.params.invoiceID,
          routeBack: route.params.routeToInvoice,
        },
      },
      showModal: true,
    }),
  };
};

export function invoiceDeliveryRouteEdit(locale): IRouteConfig {
  return {
    component: Edit,
    meta: {
      label: String(i18n(locale).tc('invoice.model', 1)),
      module: namespace.invoice,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `invoiceDelivery${modes.EDIT}`,
    path: ':invoiceID',
    props: (route) => ({
      invoiceID: route.params.invoiceID,
      mode: modes.EDIT,
      routeBack: {
        name: route.params.routeBack,
      },
      showModal: true,
    }),
  };
};

/* TABS */
export function tabInvoice(locale): IRouteConfig {
  return {
    children: [
      invoiceRouteEdit(locale),
      invoiceSendMail(locale),
    ],
    component: TabInvoice,
    icon: {
      iconName: 'invoice',
      iconFamily: '',
    },
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: i18n(locale).tc('invoice.tab.invoice'),
      module: namespace.invoice,
      type: Router.ROUTE_TYPE.TAB,
    },
    name: `invoice-${VIEW.INVOICE}`,
    navItem: true,
    path: '/sales/invoice/invoice',
    props: {
      heading: 'invoice.tab.invoice',
      mode: modes.EDIT,
      deliveryInvoice: false,
      showModal: false,
    },
  };
}

export function tabDeliveryInvoice(locale): IRouteConfig {
  return {
    children: [
      invoiceDeliveryRouteEdit(locale),
      invoiceDeliverySendMail(locale),
    ],
    component: TabInvoice,
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: i18n(locale).tc('invoice.tab.deliveryInvoice'),
      module: namespace.invoice,
      type: Router.ROUTE_TYPE.TAB,
    },
    name: `invoice-${VIEW.DELIVERYINVOICE}`,
    path: '/sales/invoice/delivery-invoice',
    props: {
      heading: 'invoice.tab.deliveryInvoice',
      mode: modes.EDIT,
      deliveryInvoice: true,
      showModal: false,
    },
  };
}

/* PAGES */
export function invoiceRouteShow(locale): IRouteConfig {
  return {
    children: [
      tabInvoice(locale),
      tabDeliveryInvoice(locale),
    ],
    component: Show,
    meta: {
      label: String(i18n(locale).tc('invoice.model', 2)),
      module: namespace.invoice,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'invoice',
    path: '/sales/invoice',
    props: {
      mode: modes.EDIT,
    },
  };
};
