import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { IMail } from '@/typings/interface/IMail';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';

export class MMail extends Model {
  static entity: TModel = MODEL.mail;

  static primaryKey = 'Id';

  Id: string;

  static fields() {
    return {
      Id: this.attr(() => uuid4()),
    };
  };

  static async _sendMail(mail: IMail): Promise<any> {
    const { response: { data: response } } = await MMail.api().post(
      Api.ENDPOINT.mail,
      mail,
      {
        save: false,
      },
    );
  };
}
