import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { i18n } from '@/locales/i18n';
import { incomeRouteShow } from '@/views/reports/income/routes';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { successRouteShow } from '@/views/reports/success/routes';

const Reports = () => import(/* webpackChunkName: "reports" */ './Reports.vue');

export function reportsRouteShow(locale): IRouteConfig {
  return {
    children: [
      incomeRouteShow(locale),
      successRouteShow(locale),
    ],
    component: Reports,
    heading: true,
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: String(i18n(locale).tc('reports.model', 1)),
    },
    name: 'reports',
    navItem: true,
    path: '/reports',
  };
};
