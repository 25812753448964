import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { getCurrency } from '@/utils/currency';
import { i18n } from '@/locales/i18n';
import { ISales } from '@/typings/interface/ISales';
import { MAccountNumber } from '@/models/MAccountNumber';
import { MODEL, TModel } from '@/utils/models';
import { Model } from '@vuex-orm/core';
import { MProduct } from '@/models/MProduct';
import { MSettings } from '@/models/MSettings';
import { MTax } from '@/models/MTax';

const locale = localStorage.getItem('locale');

export class MSales extends Model implements ISales {
  static entity: TModel = MODEL.sales;

  static primaryKey = 'Id';

  Id: string;

  Discount: string;

  Flat: boolean;

  InvoiceID: string;

  Price: string;

  ProductID: string;

  SinglePrice: string;

  ShoppingBagID: string;

  Quantity: number;

  get asListData() {
    let quantity = '1';
    const product = MProduct.find(this.ProductID);
    let account;

    if (product) {
      account = MAccountNumber.find(product.AccountNumberId);
    }

    const entity = String(i18n(locale).t(`entityType.unit.${product.Entity}`));

    let discountPrice = 0.00;
    let discountDifference = 0.00;
    let discountSinglePrice = 0.00;
    let discountSingleDifference = 0.00;

    if (this.ProductID) {
      const price = Number(this.SinglePrice) * this.Quantity;

      discountPrice = price / 100 * (100 - Number(this.Discount));

      discountSinglePrice = Number(this.SinglePrice) / 100 * (100 - Number(this.Discount));

      discountDifference = Number(Number(price).toFixed(2)) - Number(Number(discountPrice).toFixed(2));

      discountSingleDifference = Number(this.SinglePrice) - Number(discountSinglePrice);
    }

    if (!this.Flat) {
      quantity = (product.Entity === 'piece')
        ? String(this.Quantity).split('.')[0]
        : Number(this.Quantity)
          .toFixed(2)
          .toString()
          .replace('.', ',');
    }

    return {
      accountName: (account) ? account.Name : '',
      accountCode: (account) ? account.AccountingNumber : '',
      accountingId: (account) ? account.Id : '',
      discount: `${this.Discount} %`,
      discountDifference: discountDifference,
      discountSingleDifference: discountSingleDifference,
      discountPrice: discountPrice,
      entity: (this.Flat) ? i18n(locale).t('entityType.unit.flat') : entity,
      generalized: product.Generalized,
      flat: this.Flat,
      price: this.Price,
      productName: (this.ProductID) ? MProduct.find(this.ProductID).Name : '',
      productID: this.ProductID,
      singlePrice: (this.ProductID) ? Number(this.SinglePrice) : '',
      tax: (this.ProductID) ? MTax.find(product.TaxId).Percent : '',
      totalPrice: (this.ProductID) ? Number(this.Price) : '',
      quantity: quantity,
      actions: this.Id,
    };
  }

  static formatedPrice(price): string {
    const currency = getCurrency(MSettings.query().first().Currency);

    return parseFloat(price)
      .toFixed(2)
      .toString()
      .replace('.', ',')
      .concat(` ${currency.symbol}`);
  };

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      Discount: this.attr(''),

      Flat: this.boolean(false),

      InvoiceID: this.string(''),

      Price: this.attr(''),

      ProductID: this.string(''),

      SinglePrice: this.string(''),

      ShoppingBagID: this.string(''),

      Quantity: this.attr(0),
    };
  }

  static async _create(
    shoppingBagID: string,
    newSale: any = null,
  ): Promise<string> {
    let emptySale;

    if (newSale) {
      emptySale = MSales.hydrate(
        {
          ...newSale,
          ShoppingBagID: shoppingBagID,
        },
      );
    } else {
      emptySale = MSales.hydrate(
        {
          ShoppingBagID: shoppingBagID,
        },
      );
    }

    const { response: { data: response } } = await MSales.api().post(
      Api.ENDPOINT.sales,
      emptySale,
      {
        save: false,
      },
    );

    MSales.insert({
      data: {
        ...emptySale,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    MSales.delete(Id);

    MSales.api().delete(
      `${Api.ENDPOINT.sales}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MSales.api().get(
      Api.ENDPOINT.sales,
    );
  };

  static _update(sale): void {
    MSales.api().put(
      `${Api.ENDPOINT.sales}/${encodeURIComponent(sale.Id)}`,
      sale.$toJson(),
      {
        save: false,
      },
    );
  };
}
