export const customer = {
  action: {
    add: 'Kunden hinzufügen',
  },

  alert: {
    delete: 'Willst du den Kunden "{customerName}" wirklich löschen?',
  },

  model: 'Kunde | Kunden',

  noResult: 'Es wurden noch keine Kunden hinzugefügt',

  property: {
    female: 'Frau',
    male: 'Herr',
  },

  tab: {
    attributes: 'Attribute',
    products: 'Produktpreise',
  },

  table: {
    column: {
      address: 'Adresse',
      company: 'Firma',
      customerName: 'Name',
      email: 'Email Adresse',
      firstName: 'Name',
      lastName: 'Nachname',
      uid: 'UID Nummer',
      phone: 'Telefonnummer',
      prefixTitle: 'Titel vorangestellt',
      sex: 'Geschlecht',
      suffixTitle: 'Titel nachgestellt',
    },
  },
};
