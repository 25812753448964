export const entityType = {
  message: {
    noResult: 'No Units found',
  },

  model: 'Entity Type | Entity Types',

  type: {
    bottle: 'bottle',
    gram: 'kilogram',
    hour: 'hour | hours',
    litre: 'litre',
    millimeter: 'millimeter',
    piece: 'piece | pieces',
  },

  unit: {
    bottle: 'btl',
    flat: 'FR',
    gram: 'kg',
    hour: 'h',
    litre: 'l',
    millimeter: 'mm',
    piece: 'pcs',
  },
};
