import { batchRouteShow } from '@/views/accounting/batch/routes';
import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { earningsRouteShow } from '@/views/accounting/earnings/routes';
import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { outgoingsRouteShow } from './outgoings/routes';

const Accounting = () => import(/* webpackChunkName: "accounting" */ './Accounting.vue');

export function accountingRouteShow(locale): IRouteConfig {
  return {
    children: [
      batchRouteShow(locale),
      earningsRouteShow(locale),
      outgoingsRouteShow(locale),
    ],
    component: Accounting,
    heading: true,
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: String(i18n(locale).tc('accounting.model', 1)),
    },
    name: 'accounting',
    navItem: true,
    path: '/accounting',
  };
};
