import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { deliveryRouteShow } from '@/views/sales/delivery/routes';
import { i18n } from '@/locales/i18n';
import { invoiceRouteShow } from './invoice/routes';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';

const Sales = () => import(/* webpackChunkName: "sales" */ './Sales.vue');

export function salesRouteShow(locale): IRouteConfig {
  return {
    children: [
      deliveryRouteShow(locale),
      invoiceRouteShow(locale),
    ],
    component: Sales,
    heading: true,
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: String(i18n(locale).tc('sales.model', 1)),
    },
    name: 'sales',
    navItem: true,
    path: '/sales',
  };
};
