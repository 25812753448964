


















/**
 * Headings are used as the titles of each major section of a page in the
 * interface. For example, templates generally use headings as their title.
 * Heading element provides an option to change the level of the heading.
 */
import Vue, { VueConstructor } from 'vue';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'BaseHeading',

  props: {
    heading: {
      type: String,
      default: '',
    },

    isEditable: {
      type: Boolean,
      default: false,
    },

    /**
     * The heading level used for the heading.
     * `h1, h2, h3, h4, h5, h6`
     */
    level: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      blockContentRerendering: false,
      headingUntouched: true,
    };
  },

  watch: {
    heading() {
      if (this.isEditable) {
        if (!this.blockContentRerendering) {
          if (utIsEmpty(this.heading.trim())) {
            this.emitUpdate(this.$t('common.property.untitled'));
          } else {
            this.$refs.headingText.textContent = this.heading;
          }
        }

        this.headingUntouched = this.$refs.headingText.textContent.trim() === this.$t('common.property.untitled');
      }
    },
  },

  methods: {
    emitUpdate(newHeading: string): void {
      this.$emit('update', newHeading);
    },

    insertText(event): void {
      if (event.clipboardData && event.clipboardData.getData) {
        event.preventDefault();

        const content = (event.originalEvent || event).clipboardData.getData('text/plain');

        document.execCommand('insertText', false, content);
      }
    },

    onBlur(): void {
      this.blockContentRerendering = false;

      if (utIsEmpty(this.heading.trim())) {
        this.emitUpdate(this.$t('common.property.untitled'));
      }

      this.$emit('blur');
    },

    onFocus(): void {
      this.blockContentRerendering = true;
      if (this.$refs.headingText.textContent.trim() === this.$t('common.property.untitled')) {
        setTimeout(() => {
          document.execCommand('selectAll', false, null);
        }, 20);
      }

      this.$emit('focus');
    },

    triggerBlur(event: Event): void {
      const target = event.target as HTMLElement;

      target.blur();
    },

    update(event: Event): void {
      if (this.isEditable) {
        const target = event.target as HTMLElement;

        const updatedHeading = target.textContent.length <= 0 ? ' ' : target.textContent;

        this.emitUpdate(updatedHeading);

        this.headingUntouched = updatedHeading.trim() === this.$t('common.property.untitled');
      }
    },
  },

  mounted() {
    if (this.isEditable) {
      if (!this.blockContentRerendering) {
        if (utIsEmpty(this.heading.trim())) {
          this.emitUpdate(this.$t('common.property.untitled'));
        } else {
          this.$refs.headingText.textContent = this.heading;
        }
      }

      this.headingUntouched = this.$refs.headingText.textContent.trim() === this.$t('common.property.untitled');

      this.$refs['headingText'].addEventListener('paste', this.insertText);
    }
  },

  beforeDestroy(): void {
    this.$refs['headingText'].removeEventListener('paste', this.insertText);
  },
});
