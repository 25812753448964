export const visual = {
  model: 'Master Visual | Master Visuals',

  headerName: {
    rank: 'Rank',
    thumbnail: 'Thumbnail',
    name: 'Name',
    fileExtension: 'File Extension',
    filterApplied: 'Filter Applied',
    overrideMasterVisual: 'Override of Master Visual',
  },

  property: {
    coverage: 'Visual Coverage',
    coverageText: '{percentage}\u200a% of products have a visual applied.',
    coverageHasNoProductsText: 'There are no products in this campaign yet',
    noCampaign: 'No Campaign',
    empty: 'No Master Visuals in Campaign',
    pending: 'Visual Pending',
    overrideMasterVisual: 'Override Master Visual',
    resetToCampaignDefault: 'Reset to Campaign Default',

  },
};
