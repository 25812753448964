import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { namespace } from '@/store/index.definition';
import { Router } from '@/utils/router';

const Show = () => import(/* webpackChunkName: "notification" */ './NotificationListShow.vue');

/* PAGES */
export function notificationRouteShow(locale): IRouteConfig {
  return {
    children: [],
    component: Show,
    icon: {
      iconName: 'bell',
      iconFamily: 'fontawesome',
    },
    meta: {
      label: String(i18n(locale).tc('notification.model', 2)),
      module: namespace.notification,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'notification-list',
    path: '/notification-list',
  };
};
