export namespace Router {
  export enum NAVIGATION {
    ACCOUNTING = 'accounting',
    LOGISTIC = 'logistic',
    DEFINITIONS = 'definitions',
    SALES = 'SALES',
  }

  export enum ROUTE_TYPE {
    MODAL = 'modal',
    PAGE = 'page',
    TAB = 'tab',
  }
}
