import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { i18n } from '@/locales/i18n';
import { IAddress } from '@/typings/interface/IAddress';
import { ICustomer } from '@/typings/interface/ICustomer';
import { MAddress } from './MAddress';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';

const locale = localStorage.getItem('locale');

export class MCustomer extends Model implements ICustomer {
  static entity: TModel = MODEL.customer;

  static primaryKey = 'Id';

  Id: string;

  FirstName: string;

  LastName: string;

  Address: IAddress;

  CompanyName: string;

  PrefixTitle: string;

  SuffixTitle: string;

  Sex: string;

  Uid: string;

  Email: string;

  Phone: string;

  get asListData() {
    const address = MAddress.query().where('OwnerID', this.Id).first();

    return {
      name: this.LastName + ' ' + this.FirstName,
      address: (address) ? address.formatAddress : '',
      companyName: this.CompanyName,
      actions: this.Id,
    };
  }

  get name() {
    let name = `${this.LastName} ${this.FirstName}`;

    if (this.CompanyName) {
      name = `${name}, ${this.CompanyName}`;
    }

    return name;
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      FirstName: this.string(String(i18n(locale).t('common.property.untitled'))),

      LastName: this.string(''),

      CompanyName: this.string(''),

      PrefixTitle: this.string(''),

      SuffixTitle: this.string(''),

      Address: this.hasOne(
        MAddress,
        'OwnerID',
      ),

      Sex: this.string(''),

      Uid: this.string(''),

      Email: this.string(''),

      Phone: this.string(''),
    };
  }

  static async _create(): Promise<string> {
    const emptyCustomer = MCustomer.hydrate();

    const { response: { data: response } } = await MCustomer.api().post(
      Api.ENDPOINT.customer,
      emptyCustomer,
      {
        save: false,
      },
    );

    MCustomer.insert({
      data: {
        ...emptyCustomer,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    MCustomer.delete(Id);

    MCustomer.api().delete(
      `${Api.ENDPOINT.customer}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MCustomer.api().get(
      Api.ENDPOINT.customer,
    );
  };

  static _update(customer): void {
    MCustomer.api().put(
      `${Api.ENDPOINT.customer}/${encodeURIComponent(customer.Id)}`,
      customer.$toJson(),
      {
        save: false,
      },
    );
  };
}
