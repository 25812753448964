export const zIndex: {
  dropdown: number;
  label: number;
  modal: number;
  modalDropdown: number;
  modalLabel: number;
  modalTooltip: number;
  tooltip: number;
} = Object.freeze({
  dropdown: 30,
  label: 10,
  modal: 100,
  modalDropdown: 130,
  modalLabel: 110,
  modalTooltip: 120,
  notification: 140,
  tooltip: 20,
});
