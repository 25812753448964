import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { EventBus, EventBusEvent } from '@/utils/eventBus';
import { IAddress } from '@/typings/interface/IAddress';
import { IOrganization } from '@/typings/interface/IOrganization';
import { MAddress } from './MAddress';
import { MODEL, TModel } from '@/utils/models';
import { Model } from '@vuex-orm/core';
import { MSettings } from '@/models/MSettings';

export class MOrganization extends Model implements IOrganization {
  static entity: TModel = MODEL.organization;

  static primaryKey = 'Id';

  Id: string;

  AddressId: string;

  Address: IAddress;

  Email: string;

  InvoiceAddressId: string;

  InvoiceAddress: IAddress;

  Name: string;

  Owner: string;

  Phone: string;

  Uid: string;

  SettingsId: string;

  TaxId: string;

  Website: string;

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      AddressId: this.attr(''),

      Address: this.belongsTo(
        MAddress,
        'AddressId',
      ),

      Email: this.string(''),

      InvoiceAddressId: this.attr(''),

      InvoiceAddress: this.belongsTo(
        MAddress,
        'InvoiceAddressId',
      ),

      Name: this.string(''),

      Owner: this.attr(''),

      Phone: this.string(''),

      Uid: this.attr(null),

      SettingsId: this.attr(null),

      TaxId: this.attr(null),

      Website: this.string(''),
    };
  };

  static _delete(Id: string): void {
    MOrganization.delete(Id);

    MOrganization.api().delete(
      Api.ENDPOINT.organization,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MOrganization.api().get(
      Api.ENDPOINT.organization,
    );

    await MSettings._fetch();
  };

  static async _update(organization: MOrganization): Promise<any> {
    EventBus.$emit(EventBusEvent.CALL.update);

    await MOrganization.api().put(
      Api.ENDPOINT.organization,
      organization.$toJson(),
      {
        save: false,
      },
    );

    EventBus.$emit(EventBusEvent.CALL.updateEnd);
  };
}
