export type Views =
  'ATTRIBUTES' |
  'DELIVERYINVOICE' |
  'INVOICE' |
  'ORGANIZATION' |
  'PROFILE' |
  'PRODUCT' |
  'SETTINGS';

enum VIEW {
  ATTRIBUTE = 'ATTRIBUTES',
  DELIVERYINVOICE = 'DELIVERYINVOICE',
  INVOICE = 'INVOICE',
  ORGANIZATION = 'ORGANIZATION',
  PROFILE = 'PROFILE',
  PRODUCT = 'PRODUCT',
  SETTINGS = 'SETTINGS',
}

export { VIEW };
