import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { formatDate } from '@/utils/filters/format';
import { IBatch } from '@/typings/interface/IBatch';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';

export class MBatch extends Model implements IBatch {
  static entity: TModel = MODEL.batch;

  static primaryKey = 'Id';

  Id: string;

  BatchNumber: number;

  CreatedAt: string;

  Date: string;

  Entity: string;

  ProductID: string;

  ProductName: string;

  Quantity: number;

  get asListData() {
    return {
      batchNumber: `L-${this.BatchNumber}`,
      productName: this.ProductName,
      date: this.Date !== '' ? formatDate(this.Date) : '',
      entity: this.Entity,
      quantity: this.Quantity,
      actions: this.Id,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      CreatedAt: this.attr(null),

      BatchNumber: this.attr(0),

      Date: this.attr(''),

      Entity: this.string(''),

      ProductID: this.string(''),

      ProductName: this.string(''),

      Quantity: this.attr(0),
    };
  }

  static async _create(): Promise<string> {
    const emptyBatch = MBatch.hydrate();

    const { response: { data: response } } = await MBatch.api().post(
      Api.ENDPOINT.batch,
      emptyBatch,
      {
        save: false,
      },
    );

    MBatch.insert({
      data: {
        ...emptyBatch,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    MBatch.delete(Id);

    MBatch.api().delete(
      `${Api.ENDPOINT.batch}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MBatch.deleteAll();

    await MBatch.api().get(
      `${Api.ENDPOINT.batch}`,
    );
  };

  static async _getYears(): Promise<any> {
    const { response: { data: yearList } } = await MBatch.api().get(
      `${Api.ENDPOINT.batch}/years`,
      {
        save: false,
      },
    );

    return yearList;
  };

  static _update(batch): void {
    MBatch.api().put(
      `${Api.ENDPOINT.batch}/${encodeURIComponent(batch.Id)}`,
      batch.$toJson(),
      {
        save: false,
      },
    );
  };
}
