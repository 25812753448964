






import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor).extend({
  name: 'BaseAlignLeft',
});
