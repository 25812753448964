






import Vue, { VueConstructor } from 'vue';

/**
 * Used for all kind of spacing.
 */
export default (Vue as VueConstructor).extend({
  name: 'BaseRow',
});
