









import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseLoader',

  props: {
    /**
     *  Toggles visibility of <LoaderStyle>
     */
    loading: {
      type: Boolean,
      default: true,
    },
  },
});
