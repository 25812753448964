import { OPERATOR } from '@/typings/type/TOperator';

export const operator = {
  list: {
    [OPERATOR.EQUAL]: 'is',
    [OPERATOR.NOT_EQUAL]: 'is not',
    [OPERATOR.GREATER_THAN]: 'is greater than',
    [OPERATOR.LESS_THAN]: 'is less than',
  },

  model: 'Operator | Operators',
};
