export const invoice = {
  model: 'Invoice | Invoices',

  messages: {
    newDeliveryInvoice: 'Delivery invoice "{invoiceNumber}" is now available.',
  },

  property: {
    amountCarriedOver: 'Amount carried over',
    bank: 'Bank details',
    bic: 'BIC',
    canceled: 'Canceled',
    cashier: 'Cashier',
    cashId: 'Cash ID',
    customer: 'Customer',
    date: 'Date',
    datePaid: 'Payment received',
    deliveryPeriod: 'Delivery Period',
    description: 'Description',
    discount: 'Discount',
    discountPrice: 'Discount €',
    gross: 'gross',
    iban: 'IBAN',
    incl: 'incl.',
    net: 'net',
    number: 'No',
    page: 'Page',
    paid: 'Paid',
    quantity: 'Quantity',
    qty: 'Qty',
    receiver: 'Receiver',
    recipient: 'Recipient',
    recipientMail: 'Email Address',
    reversal: 'Reversal Invoice',
    single: 'Single',
    total: 'Total',
    useDifferentRecipient: 'different Recipient',
    vat: 'VAT',
    paymentMethod: 'Payment Method',
  },

  tab: {
    invoice: 'Invoices',
    deliveryInvoice: 'Delivery Invoices',
  },

  text: {
    invoiceEndText: 'Thank you for your purchase!',
    paymentReceived: 'Payment received on',
    paymentTransaction: 'Payment terms are {days} days after invoice receipt without any' +
      ' deduction.',
    reversalText: 'We take care of your refund as quickly as possible!',
  },

  type: {
    cash: 'Cash',
    creditcard: 'Creditcard',
    debitcard: 'Debitcard',
    transaction: 'Transaction',
  },
};
