export const invoice = {
  model: 'Rechnung | Rechnungen',

  messages: {
    newDeliveryInvoice: 'Lieferscheinrechnung "{invoiceNumber}" ist jetzt verfügbar.',
  },

  property: {
    amountCarriedOver: 'Übertrag',
    bank: 'Bankdetails',
    bic: 'BIC',
    canceled: 'storniert',
    cashier: 'Kassier',
    cashId: 'Kassen ID',
    customer: 'Kunde',
    date: 'Datum',
    datePaid: 'Zahlung erhalten',
    deliveryPeriod: 'Lieferperiode',
    description: 'Beschreibung',
    discount: 'Rabatt',
    gross: 'Brutto',
    iban: 'IBAN',
    incl: 'inkl.',
    net: 'Netto',
    number: 'Nr.',
    page: 'Seite',
    paid: 'bezahlt',
    quantity: 'Anzahl',
    qty: '#',
    receiver: 'Empfänger',
    recipient: 'Empfänger',
    recipientMail: 'Email Adresse',
    reversal: 'Stornorechnung',
    single: 'Einzel',
    total: 'Total',
    useDifferentRecipient: 'anderer Empfänger',
    vat: 'Ust',
    paymentMethod: 'Zahlungsmethode',
  },

  tab: {
    invoice: 'Rechnungen',
    deliveryInvoice: 'Lieferscheinrechnungen',
  },

  text: {
    invoiceEndText: 'Vielen Dank für Ihren Auftrag!',
    paymentReceived: 'Zahlung erhalten am',
    paymentTransaction: 'Zahlungsziel netto ohne Abzug 30 Tage nach Rechnungserhalt.',
    reversalText: 'Wir werden Ihre Rückerstattung so schnell wie möglich bearbeiten!',
  },

  type: {
    cash: 'Bar',
    creditcard: 'Kreditkarte',
    debitcard: 'Debitcard',
    transaction: 'Überweisung',
  },
};
