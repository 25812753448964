import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { i18n } from '@/locales/i18n';
import { ICategory } from '@/typings/interface/ICategory';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';

const locale = localStorage.getItem('locale');

export class MCategory extends Model implements ICategory {
  static entity: TModel = MODEL.category;

  static primaryKey = 'Id';

  Id: string;

  CategoryName: string;

  get asListData() {
    return {
      name: this.CategoryName,
      actions: this.Id,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      CategoryName: this.string(String(i18n(locale).tc('common.property.untitled'))),
    };
  }

  static async _create(): Promise<string> {
    const emptyCategory = MCategory.hydrate();

    const { response: { data: response } } = await MCategory.api().post(
      Api.ENDPOINT.category,
      emptyCategory,
      {
        save: false,
      },
    );

    MCategory.insert({
      data: {
        ...emptyCategory,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    MCategory.delete(Id);

    MCategory.api().delete(
      `${Api.ENDPOINT.category}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MCategory.api().get(
      Api.ENDPOINT.category,
    );
  };

  static _update(category): void {
    MCategory.api().put(
      `${Api.ENDPOINT.category}/${encodeURIComponent(category.Id)}`,
      category.$toJson(),
      {
        save: false,
      },
    );
  };
}
