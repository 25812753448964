// units are pixels (px)
export const space: {
  xxLarge: number;
  modal: number;
  xLarge: number;
  row: number;
  large: number;
  xMedium: number;
  medium: number;
  treeDepth: number;
  base: number;
  small: number;
  xSmall: number;
  xxSmall: number;
  xxxSmall: number;
  interSmall: number;
  xxxxSmall: number;
} = Object.freeze({
  xxLarge: 128,
  modal: 88,
  xLarge: 64,
  row: 56,
  large: 48,
  xMedium: 36,
  medium: 34,
  treeDepth: 30,
  base: 24,
  small: 16,
  xSmall: 14,
  xxSmall: 12,
  xxxSmall: 8,
  interSmall: 6,
  xxxxSmall: 4,
});
