import { common } from '@/locales/en/partials/_common';

export const handoff = {
  action: {
    delete: 'Delete Handoff',

    download: {
      productList: 'Download Product List',
      visuals: 'Download Visuals',
    },
    new: `${common.action.new} Handoff`,
    save: `${common.action.save} Handoff`,
    share: `${common.action.share} Handoff`,
    goToPage: 'Go to Handoff Page',
  },

  alert: {
    delete: 'Deleting the handoff will also delete all of its existing links.',
  },

  message: {
    empty: 'No products within this handoff.',
    notFound: 'This Handoff does not exist. It may have been deleted by its creator.',
    shareCurrent: 'Share current products and visuals of this campaign.',
  },

  model: 'Handoff | Handoffs',

  products: {
    unique: 'Unique Products',
    total: 'Total Products',
  },

  property: {
    date: 'Handoff Date',
    link: 'Link',
    name: 'Handoff Name',
    createdAt: 'Handoff created at ',
  },

  visuals: {
    applied: 'Applied Visuals',
    count: 'Visual files',
    size: 'Visual files size',
  },
};
