





















import Vue, { VueConstructor } from 'vue';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseTooltip',

  props: {
    message: {
      type: String,
      default: '',
    },

    position: {
      type: String,
      default: 'center',
    },

    forceHide: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hover: false,
    };
  },
});
