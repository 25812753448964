import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { MBatch } from '@/models/MBatch';
import { modes } from '@/utils/TModes';
import { namespace } from '@/store/index.definition';
import { RootState } from '@/store';
import { Route } from 'vue-router';
import { Router } from '@/utils/router';
import { Store } from 'vuex';

const ModalEdit = () => import(/* webpackChunkName: "batch" */ './modals/BatchEdit.vue');
const Show = () => import(/* webpackChunkName: "batch" */ './BatchListShow.vue');

/* MODALS */
export function batchRouteEdit(locale): IRouteConfig {
  return {
    component: ModalEdit,
    meta: {
      breadCrumb: {
        getter: (store: Store<RootState>, route: Route): string => (
          MBatch.find(route.params.ID).ProductName
        ),
        type: BREADCRUMB_TYPE.GETTER,
      },
      module: namespace.batch,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `batch${modes.EDIT}`,
    path: ':ID/edit',
    props: (route) => ({
      ID: route.params.ID,
      mode: modes.EDIT,
      routeBack: {
        name: 'batch-list',
      },
      showModal: true,
    }),
  };
};

export function batchRouteNew(locale): IRouteConfig {
  return {
    beforeEnter: async(to, from, next) => {
      to.params.ID = await MBatch._create();

      next();
    },
    component: ModalEdit,
    meta: {
      label: String(i18n(locale).tc('common.property.untitled')),
      module: namespace.batch,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `batch${modes.NEW}`,
    path: 'new',
    props: (route) => ({
      ID: route.params.ID,
      mode: modes.NEW,
      routeBack: {
        name: 'batch-list',
      },
      showModal: true,
    }),
  };
};

/* PAGES */
export function batchRouteShow(locale): IRouteConfig {
  return {
    children: [
      batchRouteEdit(locale),
      batchRouteNew(locale),
    ],
    component: Show,
    icon: {
      iconName: 'list-on-window',
      iconFamily: 'fontawesome',
    },
    meta: {
      label: String(i18n(locale).tc('batch.model', 2)),
      module: namespace.batch,
      navItem: Router.NAVIGATION.LOGISTIC,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'batch-list',
    navItem: true,
    path: '/accounting/batch-list',
    props: {
      showModal: false,
    },
  };
};
