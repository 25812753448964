export const entityType = {
  message: {
    noResult: 'No Einheiten gefunden',
  },

  model: 'Einheit | Einheiten',

  type: {
    bottle: 'Flasche',
    gram: 'Kilogramm',
    hour: 'Stunde | Stunden',
    litre: 'Liter',
    millimeter: 'Millimeter',
    piece: 'Stück | Stücke',
  },

  unit: {
    bottle: 'Fl.',
    flat: 'PA',
    gram: 'kg',
    hour: 'Std.',
    litre: 'l',
    millimeter: 'mm',
    piece: 'Stk.',
  },
};
