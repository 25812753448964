import VuexORM from '@vuex-orm/core';
import { MAccountNumber } from '@/models/MAccountNumber';
import { MAddress } from '@/models/MAddress';
import { MBatch } from '@/models/MBatch';
import { MCategory } from '@/models/MCategory';
import { MCustomer } from '@/models/MCustomer';
import { MCustomerProductPrice } from '@/models/MCustomerProductPrice';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { MEarning } from '@/models/MEarning';
import { MInvoice } from '@/models/MInvoice';
import { MMail } from '@/models/MMail';
import { MNotification } from '@/models/MNotification';
import { MOrganization } from '@/models/MOrganization';
import { MOutgoing } from '@/models/MOutgoing';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';
import { MSettings } from '@/models/MSettings';
import { MShoppingBag } from '@/models/MShoppingBag';
import { MTax } from '@/models/MTax';
import { MUser } from '@/models/MUser';

const vuexOrmDatabase = new VuexORM.Database();

vuexOrmDatabase.register(MAccountNumber);

vuexOrmDatabase.register(MAddress);

vuexOrmDatabase.register(MCategory);

vuexOrmDatabase.register(MBatch);

vuexOrmDatabase.register(MCustomer);

vuexOrmDatabase.register(MCustomerProductPrice);

vuexOrmDatabase.register(MInvoice);

vuexOrmDatabase.register(MDeliveryBag);

vuexOrmDatabase.register(MEarning);

vuexOrmDatabase.register(MMail);

vuexOrmDatabase.register(MNotification);

vuexOrmDatabase.register(MOrganization);

vuexOrmDatabase.register(MOutgoing);

vuexOrmDatabase.register(MProduct);

vuexOrmDatabase.register(MSales);

vuexOrmDatabase.register(MSettings);

vuexOrmDatabase.register(MShoppingBag);

vuexOrmDatabase.register(MTax);

vuexOrmDatabase.register(MUser);

export { vuexOrmDatabase };
