import { common } from '@/locales/en/partials/_common';

export const tax = {
  model: 'Tax | Taxes',

  action: {
    add: `${common.action.add} Tax`,
    delete: `${common.action.delete} Tax`,
  },

  alert: {
    delete: 'Do you want to delete the tax "{taxName}"?',
  },

  message: {
    noResult: 'No Tax found!',
  },

  property: {
    name: 'Tax Name',
  },
};
