export const user = {
  message: {
    currentPasswordError: 'Your current password was not entered correctly.',
  },

  password: {
    action: {
      change: 'Change Password',
      new: 'New Password',
    },

    notification: {
      success: 'Your password has been changed successfully!',
    },

    properties: {
      current: 'Current Password',
      repeat: 'Repeat New Password',
    },
  },

  validation: {
    allRequired: 'All fields are required.',
    match: 'The new passwords don\'t match.',
  },
};
