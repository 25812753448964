export enum MODEL {
  accountNumber = 'accountNumber',
  address = 'address',
  batch = 'batch',
  category = 'category',
  charge = 'charge',
  customer = 'customer',
  customerProductPrice = 'customerProductPrice',
  deliveryBag = 'deliveryBag',
  earning = 'earning',
  invoice = 'invoice',
  mail = 'mail',
  notification = 'notification',
  organization = 'organization',
  outgoing = 'outgoing',
  product = 'product',
  sales = 'sales',
  settings = 'settings',
  shoppingBag = 'shoppingBag',
  tax = 'tax',
  user = 'user',
}

export type TModel =
  MODEL.accountNumber |
  MODEL.address |
  MODEL.batch |
  MODEL.category |
  MODEL.charge |
  MODEL.customer |
  MODEL.customerProductPrice |
  MODEL.deliveryBag |
  MODEL.earning |
  MODEL.invoice |
  MODEL.mail |
  MODEL.notification |
  MODEL.organization |
  MODEL.outgoing |
  MODEL.product |
  MODEL.sales |
  MODEL.settings |
  MODEL.shoppingBag |
  MODEL.tax |
  MODEL.user;
