// units are pixels (px)
export const size: {
  borderRadius: number,
  buttonHeight: number,
  buttonIconSquare: number;
  contextMenuMinWidth: number;
  desktopAppContentMaxWidth: number;
  desktopStart: number;
  footerHeight: number;
  gridActionMenu: number;
  icon: number;
  iconLarge: number;
  iconSquare: number;
  inputChipHeight: number;
  inputFieldHeight: number;
  logo: number;
  navigation: number;
  phoneEnd: number;
  radioButton: number;
  tappableSquare: number;
} = Object.freeze({
  borderRadius: 4,
  buttonHeight: 24,
  buttonIconSquare: 24,
  contextMenuMinWidth: 120,
  desktopAppContentMaxWidth: 800,
  desktopStart: 1024,
  footerHeight: 64,
  gridActionMenu: 60,
  icon: 14,
  iconLarge: 32,
  iconSquare: 24,
  inputChipHeight: 26,
  inputFieldHeight: 52,
  logo: 50,
  navigation: 256,
  phoneEnd: 1023,
  profilePicture: 80,
  radioButton: 20,
  tappableSquare: 44,
});
