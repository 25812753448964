export const earning = {
  model: 'Earning | Earnings',

  message: {
    noResult: 'No earning created yet.',
  },

  property: {
    accountNumber: 'Account Number',
    bankCash: 'Bank/Cash Number',
    date: 'Booking Date',
    description: 'Description',
    grossAmount: 'Gross Amount',
    invoiceNumber: 'Invoice Number',
    name: 'Organization Name',
    netAmount: 'Net Amount',
    reversal: 'Reversal',
    slip: 'Slip',
    slipRange: 'Slip Range',
    tax: 'Tax',
    taxAmount: 'Tax Amount',
  },

  exportPdf: {
    accountNumber: 'Account',
    bankCash: 'Bank',
    date: 'Date',
    description: 'Description',
    grossAmount: 'Gross',
    invoice: 'Invoice',
    netAmount: 'Net',
    slip: 'Slip',
    tax: '%',
    taxAmount: 'Tax',
  },

  slipRange: {
    BK: 'BK',
    CS: 'CS',
    IRO: 'IRO',
    EE: 'EE',
  },
};
