import BaseAlert from '@/components/base/BaseAlert.vue';
import SweetAlert from 'sweetalert2/dist/sweetalert2.js';
import { i18n } from '@/locales/i18n';

const locale = localStorage.getItem('locale');

const defaultAlertOptions = {
  animation: false,

  buttonsStyling: false,

  cancelButtonAriaLabel: String(i18n(locale).tc('common.action.cancelButton')),

  cancelButtonText: String(i18n(locale).tc('common.action.cancelButton')),

  customClass: {
    actions: 'alert__actions',
    cancelButton: 'button',
    confirmButton: 'button',
    content: 'alert__content',
    container: 'alert-container',
    icon: 'alert__icon--hidden',
    popup: 'alert',
    title: 'alert__title--hidden',
  },

  reverseButtons: true,

  showCancelButton: true,

  target: 'main',

  width: '364px',
};

export const AlertConfirm = SweetAlert.mixin({
  ...defaultAlertOptions,

  confirmButtonAriaLabel: String(i18n(locale).tc('common.action.ok')),

  confirmButtonText: String(i18n(locale).tc('common.action.delete')),

  customClass: {
    ...defaultAlertOptions.customClass,
    cancelButton: 'button button--secondary',
    confirmButton: 'button button--primary',
  },
});

export const AlertDelete = SweetAlert.mixin({
  ...defaultAlertOptions,

  confirmButtonAriaLabel: String(i18n(locale).tc('common.action.delete')),

  confirmButtonText: String(i18n(locale).tc('common.action.delete')),

  customClass: {
    ...defaultAlertOptions.customClass,
    cancelButton: 'button button--secondary',
    confirmButton: 'button button--danger',
  },
});

export const AlertToast = SweetAlert.mixin({
  ...defaultAlertOptions,
  showCancelButton: false,
  showConfirmButton: false,
  toast: true,
  timer: 2000,
});

export const mixinAlert = {
  components: {
    BaseAlert,
  },

  methods: {
    async confirmAlertDelete(text: string): Promise<any> {
      return AlertDelete.fire({
        text: text,
      });
    },
  },
};
