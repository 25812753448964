export enum SELECTIONSTATE {
  CHECKED = 'CHECKED',
  INDETERMINATE = 'INDETERMINATE',
  UNCHECKED = 'UNCHECKED'
}

export type TSelectionState =
  SELECTIONSTATE.CHECKED |
  SELECTIONSTATE.INDETERMINATE |
  SELECTIONSTATE.UNCHECKED

