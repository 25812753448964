














import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import Vue, { VueConstructor } from 'vue';

/**
 * Used to initialize actions via an icon only. The `:focus` state is left as browser standard.
 */
export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseButtonIcon',

  components: {
    BaseSvgIcon,
  },

  props: {
    /**
     * Defines the emited action of the component ('click:[action]') as well as
     * which CSS class will additionally be applied (.button--[action])
     */
    action: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      required: true,
    },

    tabIndex: {
      type: Number,
      default: 0,
    },

    /**
     * Defines which SVG icon will be shown
     */
    type: {
      type: String,
      default: '',
    },

    /**
     * Defines the style variant of the component as well as
     * which CSS class will additionally be applied (.button--[variant])
     */
    variant: {
      type: String,
      default: '',
    },
  },

  computed: {
    buttonClassList() {
      const classes = [
        'button-icon',
      ];

      if (this.variant) {
        classes.push('button-icon--' + this.variant);
      }

      return classes;
    },
  },
});
