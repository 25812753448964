export const profile = {
  image: {
    alt: 'User Profile Image',
    upload: 'Upload Profile Image',
  },

  logout: 'Logout',

  model: 'Profile | Profiles',

  username: 'Username',
};
