import { GetterTree } from 'vuex';
import { http } from '@/http';
import { User } from '@/store/user/user.store.definition';

const getters = <GetterTree<User.State, any>> {
  [User.GETTER.accessToken]: (
    state: User.State,
  ) => {
    if (!state.isLoggedIn) {
      return null;
    }

    return state.accessToken;
  },

  [User.GETTER.activeOrganization]: (
    state: User.State,
  ) => {
    return state.activeOrganization;
  },

  [User.GETTER.isLoggedIn]: (
    state: User.State,
  ) => {
    return state.isLoggedIn;
  },

  [User.GETTER.mainOrganization]: (
    state: User.State,
  ) => {
    return state.mainOrganization;
  },

  [User.GETTER.profileImageURL]: (
    state: User.State,
  ) => {
    let profileImageURL;

    if (state.profileImageID) {
      profileImageURL = `${http.defaults.baseURL}${state.profileImageID}`;
    }

    return profileImageURL;
  },
};

export { getters };
