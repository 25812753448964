import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { namespace } from '@/store/index.definition';
import { Router } from '@/utils/router';

const Show = () => import(/* webpackChunkName: "delivery" */ './DeliveryShow.vue');

/* PAGES */
export function deliveryRouteShow(locale): IRouteConfig {
  return {
    children: [],
    component: Show,
    icon: {
      iconName: 'deliveries',
      iconFamily: '',
    },
    meta: {
      label: String(i18n(locale).tc('delivery.model', 2)),
      module: namespace.delivery,
      navItem: Router.NAVIGATION.SALES,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'deliveries',
    navItem: true,
    path: '/sales/deliveries',
    props: {
      showModal: false,
    },
  };
};
