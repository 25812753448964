export const sales = {
  model: 'Sale | Sales',

  entity: {
    bottle: 'bottle | bottles',
    flat: 'flat rate',
    gram: 'gram | grams',
    hour: 'hour | hours',
    litre: 'litre | liters',
    millimeter: 'millimeter | millimeters',
    piece: 'piece | pieces',
  },

  property: {
    callUp: 'Call Up',
    discount: 'Discount',
    flat: 'Flat',
    parking: 'Park',
    price: 'Price',
    quantity: 'Quantity',
  },
};
