import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { MCustomer } from '@/models/MCustomer';
import { MCustomerProductPrice } from '@/models/MCustomerProductPrice';
import { modes, TModes } from '@/utils/TModes';
import { namespace } from '@/store/index.definition';
import { Route } from 'vue-router';
import { router } from '@/router';
import { Router } from '@/utils/router';
import { VIEW } from '@/utils/views';

const Edit = () => import(/* webpackChunkName: "customer" */ './CustomerEdit.vue');
const Show = () => import(/* webpackChunkName: "customer" */ './CustomerShow.vue');
const TabProducts = () => import(/* webpackChunkName: "customer" */ './tabs/CustomerProducts.vue');
const TabAttributes = () => import(/* webpackChunkName: "customer" */ './tabs/CustomerAttributes.vue');

/* TABS */

export function customerTabAttributes(
  mode: TModes,
  locale: string,
): IRouteConfig {
  return {
    component: TabAttributes,
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: String(i18n(locale).tc('customer.tab.attributes', 2)),
      module: namespace.customer,
      type: Router.ROUTE_TYPE.TAB,
    },
    name: `customer${mode}-${VIEW.ATTRIBUTE}`,
    path: 'attributes',
    props: {
      heading: 'customer.tab.attributes',
      mode: mode,
    },
  };
}

export function customerTabProducts(
  mode: TModes,
  locale: string,
): IRouteConfig {
  return {
    component: TabProducts,
    meta: {
      breadCrumb: {
        type: BREADCRUMB_TYPE.DISABLED,
      },
      label: String(i18n(locale).t('customer.tab.products')),
      module: namespace.customer,
      type: Router.ROUTE_TYPE.TAB,
    },
    name: `customer${mode}-${VIEW.PRODUCT}`,
    path: 'products',
    props: {
      heading: 'customer.tab.products',
      mode: mode,
    },
  };
}

/* PAGES */
export function customerRouteEdit(locale): IRouteConfig {
  return {
    beforeEnter: async(to, from, next) => {
      await MCustomerProductPrice._fetch(to.params.ID);

      next();
    },
    children: [
      customerTabAttributes(modes.EDIT, locale),
      customerTabProducts(modes.EDIT, locale),
    ],
    component: Edit,
    meta: {
      breadCrumb: {
        getter: (store, route: Route): string => (
          MCustomer.find(route.params.ID).name
        ),
        type: BREADCRUMB_TYPE.GETTER,
      },
      module: namespace.customer,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: `customer${modes.EDIT}`,
    path: '/definitions/customers/edit/:ID',
    props: (route) => ({
      ID: route.params.ID,
      mode: modes.EDIT,
    }),
  };
};

export function customerRouteNew(locale): IRouteConfig {
  return {
    beforeEnter: async(to, from, next) => {
      to.params.ID = await MCustomer._create();

      to.name = customerTabAttributes(modes.EDIT, locale).name;

      next();
    },
    children: [
      customerTabProducts(modes.NEW, locale),
      customerTabAttributes(modes.NEW, locale),
    ],
    component: Edit,
    meta: {
      label: String(i18n(locale).tc('common.property.untitled')),
      module: namespace.customer,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: `customer${modes.NEW}`,
    path: '/definitions/customers/new',
    props: (route) => ({
      ID: route.params.ID,
      mode: modes.NEW,
    }),
  };
};

export function customerRouteShow(locale): IRouteConfig {
  return {
    children: [
      customerRouteEdit(locale),
      customerRouteNew(locale),
    ],
    component: Show,
    icon: {
      iconName: 'customers',
      iconFamily: 'fontawesome',
    },
    meta: {
      label: String(i18n(locale).tc('customer.model', 2)),
      module: namespace.customer,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'customers',
    navItem: true,
    path: '/definitions/customers',
  };
};
