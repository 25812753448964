export const filter = {
  action: {
    orWhere: 'Or Where …',
  },

  attribute: {
    noOptions: 'No attribute added yet',
    noResult: 'No attribute found',
  },

  block: {
    or: 'Or where all of the following conditions are met',
    where: 'Where all of the following conditions are met',
  },

  model: 'Filter | Filters',

  operator: {
    noOptions: 'No operator found',
    noResult: 'No operator found',
  },

  value: {
    new: 'Add this as new value',
  },
};
