import App from '@/App.vue';
import Logger from '@/utils/logger';
import Login from '@/Login.vue';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { isNil as _isNil } from 'lodash';
import { Api } from '@/store/endpoints';
import { authorize } from '@/authorize';
import { AxiosConfig } from '@/http/axiosConfig';
import { EventBus } from '@/utils/eventBus';
import { HTTP, http } from '@/http';
import { i18n } from '@/locales/i18n';
import { loadWebfont } from '@/utils/webFontLoader';
import { MAccountNumber } from './models/MAccountNumber';
import { MBatch } from '@/models/MBatch';
import { MCategory } from '@/models/MCategory';
import { MCustomer } from '@/models/MCustomer';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { MEarning } from '@/models/MEarning';
import { MInvoice } from '@/models/MInvoice';
import { mixinNotification } from '@/components/mixins/notification';
import { MNotification } from '@/models/MNotification';
import { MOrganization } from '@/models/MOrganization';
import { MOutgoing } from '@/models/MOutgoing';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';
import { MSettings } from '@/models/MSettings';
import { MShoppingBag } from '@/models/MShoppingBag';
import { MTax } from '@/models/MTax';
import { MUser } from './models/MUser';
import { router } from '@/router';
import { store } from '@/store';
import 'normalize.css';

Vue.use(VueI18n);

Vue.prototype.$logger = Logger;

Vue.prototype.$bus = EventBus;

Vue.config.productionTip = false;

Vue.mixin(mixinNotification);

authorize().then(
  async({ token, status }) => {
    loadWebfont();
    if (status !== HTTP.OK) {
      new Vue({
        render: h => h(Login),
      }).$mount('#app');
    } else {
      if (!_isNil(token)) {
        const credentials = btoa(process.env.VUE_APP_USER + ':' + process.env.VUE_APP_PASSWORD);
        const basicAuth = 'Basic ' + credentials;

        http.defaults.headers.common.Authorization = basicAuth; // `Bearer ${token}`;

        AxiosConfig.headers.common.Authorization = basicAuth; // `Bearer ${token}`;
      }

      const frmnStore = store(AxiosConfig);

      const user = await MUser._loadUser();

      const isProduction: boolean = (process.env.NODE_ENV === 'production');

      const currentOrganization: string = (isProduction)
        ? user.ActiveOrganization.ID
        : process.env.VUE_APP_DEFAULT_ORGANIZATION_ID;

      Api.setEndpoints(currentOrganization);

      await Promise.all(
        [
          MOrganization._fetch(),
          MCategory._fetch(),
          MProduct._fetch(),
          MTax._fetch(),
          MCustomer._fetch(),
        ],
      );

      await Promise.all(
        [
          MBatch._fetch(),
          MAccountNumber._fetch(),
          MOutgoing._fetch(),
          MDeliveryBag._fetch(),
          MInvoice._fetch(),
          MNotification._fetch(),
          MSales._fetch(),
          MShoppingBag._fetch(),
        ],
      );

      const settings = MSettings.query().first();

      const locale = i18n(settings.Language);

      localStorage.setItem('locale', locale.locale);

      new Vue({
        i18n: locale,
        store: frmnStore,
        router: router(settings.Language),
        render: h => h(App),
      }).$mount('#app');
    }
  },
);
