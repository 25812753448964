import { BREADCRUMB_TYPE } from '@/typings/interface/IBreadCrumb';
import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { MAccountNumber } from '@/models/MAccountNumber';
import { modes } from '@/utils/TModes';
import { namespace } from '@/store/index.definition';
import { RootState } from '@/store';
import { Route } from 'vue-router';
import { Router } from '@/utils/router';
import { Store } from 'vuex';

const ModalEdit = () => import(/* webpackChunkName: "definitions" */ './modals/AccountNumberEdit.vue');
const Show = () => import(/* webpackChunkName: "definitions" */ './AccountNumberShow.vue');

/* MODALS */
export function accountNumberRouteEdit(locale): IRouteConfig {
  return {
    component: ModalEdit,
    meta: {
      breadCrumb: {
        getter: (store: Store<RootState>, route: Route): string => (
          MAccountNumber.find(route.params.ID).Name
        ),
        type: BREADCRUMB_TYPE.GETTER,
      },
      module: namespace.accountNumber,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `accountNumber${modes.EDIT}`,
    path: ':ID/edit',
    props: (route) => ({
      ID: route.params.ID,
      mode: modes.EDIT,
      routeBack: {
        name: 'accountNumber',
      },
      showModal: true,
    }),
  };
};

export function accountNumberRouteNew(locale): IRouteConfig {
  return {
    beforeEnter: async(to, from, next) => {
      to.params.ID = await MAccountNumber._create();

      next();
    },
    component: ModalEdit,
    meta: {
      label: String(i18n(locale).tc('common.property.untitled')),
      module: namespace.accountNumber,
      type: Router.ROUTE_TYPE.MODAL,
    },
    name: `accountNumber${modes.NEW}`,
    path: 'new',
    props: (route) => ({
      ID: route.params.ID,
      mode: modes.NEW,
      routeBack: {
        name: 'accountNumber',
      },
      showModal: true,
    }),
  };
};

/* PAGES */
export function accountNumberRouteShow(locale): IRouteConfig {
  return {
    children: [
      accountNumberRouteEdit(locale),
      accountNumberRouteNew(locale),
    ],
    component: Show,
    icon: {
      iconFamily: '',
      iconName: 'accounting',
    },
    meta: {
      label: String(i18n(locale).tc('accountNumber.model', 2)),
      module: namespace.accountNumber,
      navItem: Router.NAVIGATION.ACCOUNTING,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'accountNumber',
    navItem: true,
    path: '/definitions/account-number',
    props: {
      showModal: false,
    },
  };
};
