export const sales = {
  model: 'Sale | Sales',

  entity: {
    bottle: 'Flasche | Flaschen',
    flat: 'Pauschale',
    gram: 'Gramm | Gramm',
    hour: 'Stunde | Stunden',
    litre: 'Liter | Liter',
    millimeter: 'Millimeter | Millimeter',
    piece: 'Stück | Stück',
  },

  property: {
    callUp: 'Aufrufen',
    discount: 'Rabatt',
    flat: 'Pauschal',
    parking: 'Parken',
    price: 'Preis',
    quantity: 'Anzahl',
  },
};
