export interface IBreadCrumb {
  label: string,
  link?: string,
  type: BREADCRUMB_TYPE,
}

export interface IBreadCrumbImage {
  label: string,
  link?: string,
  type: BREADCRUMB_TYPE.IMAGE,
  source: string,
}

export enum BREADCRUMB_TYPE {
  DISABLED,
  GETTER,
  IMAGE,
  PLAIN,
  OVERRIDE,
}

export type TBreadCrumb = IBreadCrumb | IBreadCrumbImage;
