import { accounting } from '@/locales/de/partials/_accounting';
import { accountNumber } from '@/locales/de/partials/_accountNumber';
import { address } from '@/locales/de/partials/_address';
import { batch } from '@/locales/de/partials/_batch';
import { category } from '@/locales/de/partials/_category';
import { common } from '@/locales/de/partials/_common';
import { customer } from '@/locales/de/partials/_customer';
import { date } from '@/locales/de/partials/_date';
import { definition } from '@/locales/de/partials/_definition';
import { delivery } from '@/locales/de/partials/_delivery';
import { displayType } from '@/locales/de/partials/_displayType';
import { earning } from '@/locales/de/partials/_earning';
import { entityType } from '@/locales/de/partials/_entityType';
import { image } from '@/locales/de/partials/_image';
import { income } from '@/locales/de/partials/_income';
import { invoice } from '@/locales/de/partials/_invoice';
import { logistic } from '@/locales/de/partials/_logistics';
import { mailing } from '@/locales/de/partials/_mailings';
import { navigation } from '@/locales/de/partials/_navigation';
import { notification } from '@/locales/de/partials/_notification';
import { organization } from '@/locales/de/partials/_organization';
import { outgoing } from '@/locales/de/partials/_outgoing';
import { product } from '@/locales/de/partials/_product';
import { profile } from '@/locales/de/partials/_profile';
import { reports } from '@/locales/de/partials/_reports';
import { sales } from '@/locales/de/partials/_sales';
import { settings } from '@/locales/de/partials/_settings';
import { shoppingBag } from '@/locales/de/partials/_shoppingBag';
import { success } from '@/locales/de/partials/_success';
import { tax } from '@/locales/de/partials/_tax';
import { user } from '@/locales/de/partials/_user';
import { validation } from '@/locales/de/partials/_validation';

export const de = {
  accounting,

  accountNumber,

  address,

  batch,

  entityType,

  category,

  common,

  customer,

  date,

  definition,

  delivery,

  displayType,

  earning,

  image,

  income,

  invoice,

  logistic,

  mailing,

  navigation,

  notification,

  organization,

  outgoing,

  product,

  profile,

  reports,

  sales,

  settings,

  shoppingBag,

  success,

  tax,

  user,

  validation,
};
