
































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import Vue, { VueConstructor } from 'vue';
import { http, HTTP } from '@/http';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor<Vue>).extend({
  components: {
    BaseButtonText,
    BaseInputField,
    BaseRow,
    BaseSvgIcon,
  },

  data() {
    return {
      error: false,
      errorMessage: '',
      isLoading: false,
      username: '',
      password: '',
    };
  },

  methods: {
    async login(): Promise<any> {
      this.isLoading = true;

      try {
        const response = await http.post(
          `${process.env.VUE_APP_AUTHORITY_URL}/user/login`,
          {
            username: this.username,
            password: this.password,
          },
        );

        localStorage.setItem('farmin_token', response.data.access_token);

        localStorage.setItem('farmin_refresh_token', response.data.refresh_token);

        window.location.href = '/';

        this.isLoading = false;
      } catch (e) {
        this.errorMessage = e.response.data.message;

        this.error = true;

        this.isLoading = false;

        setTimeout(() => {
          this.error = false;
        }, 5000);
      }
    },
  },
});
