







































































import BaseAlignLeft from '@/components/base/BaseAlignLeft.vue';
import BaseAlignRight from '@/components/base/BaseAlignRight.vue';
import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';
import Vue, { VueConstructor } from 'vue';
import { EventBusEvent } from '@/utils/eventBus';

export default (Vue as VueConstructor).extend({
  name: 'BaseModal',

  components: {
    BaseAlignLeft,
    BaseAlignRight,
    BaseButtonText,
    BaseRow,
    BreadCrumbs,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },

    withControlls: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    hasActionBar(): boolean {
      return this.$slots.actionBar;
    },

    hasBreadCrumbs(): boolean {
      return this.$slots.breadCrumbs;
    },

    hasVisual(): boolean {
      return this.$slots.visual;
    },

    modalClasses(): object {
      return {
        'modal--visual': this.hasVisual,
        'modal--small': this.small,
      };
    },

    modalContainerClasses(): object {
      return {
        'modal-container--action-bar': this.hasActionBar,
      };
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler() {
        if (this.visible) {
          this.$bus.$emit(EventBusEvent.MODAL.opened);
        } else {
          this.close();
        }
      },
    },
  },

  methods: {
    checkClickTarget(event): void {
      if (event.target.classList.contains('modal__mask')) {
        this.close();
      }
    },

    close(): void {
      this.$emit('close');
    },
  },

  created(): void {
    const keyCodeEsc = 27;

    document.addEventListener(
      'keyup', (event) => {
        if (event.keyCode === keyCodeEsc) {
          this.close();
        }
      },
    );
  },

  beforeMount(): void {
    window.addEventListener(
      'click',
      this.checkClickTarget,
    );
  },

  beforeDestroy(): void {
    window.removeEventListener(
      'click',
      this.checkClickTarget,
    );

    this.$bus.$emit(EventBusEvent.MODAL.closed);
  },
});
