import { i18n } from '@/locales/i18n';

const locale = localStorage.getItem('locale');

export enum SlipRange {
  BK = 'BK',
  CS = 'CS',
  IRO = 'IRO',
  EE = 'EE',
}

export const slipRangeList = [
  {
    Id: SlipRange.BK,
    Name: String(i18n(locale).t(`outgoing.slipRange.${SlipRange.BK}`)),
  },
  {
    Id: SlipRange.CS,
    Name: String(i18n(locale).t(`outgoing.slipRange.${SlipRange.CS}`)),
  },
  {
    Id: SlipRange.IRO,
    Name: String(i18n(locale).t(`outgoing.slipRange.${SlipRange.IRO}`)),
  },
  {
    Id: SlipRange.EE,
    Name: String(i18n(locale).t(`outgoing.slipRange.${SlipRange.EE}`)),
  },
];
