









import Vue, { VueConstructor } from 'vue';
import { utIsEmpty } from '@/utils/empty';

const req = require.context('../../assets/icons', true, /^\.\/.*\.svg$/);

/**
 * Icons are used to visually communicate core parts of the product and
 * available actions. They can act as wayfinding tools to help users more
 * easily understand where they are in the product.
 */
export default (Vue as VueConstructor<Vue>).extend({
  name: 'BaseSvgIcon',

  props: {
    /**
     * The HTML5 element tag of the SVG icon wrapper
     */
    element: {
      type: String,
      default: 'div',
    },

    /**
     * The fill color of the SVG icon.
     */
    fill: {
      type: String,
      default: '',
    },

    /**
     * The name of the icon to display.
     */
    name: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      default: '',
    },
  },

  computed: {
    svg() {
      if (!utIsEmpty(this.name)) {
        let type = '';

        if (!utIsEmpty(this.type)) {
          type = this.type + '/';
        }

        const svg = req('./' + type + this.name + '.svg');

        if (utIsEmpty(this.fill)) {
          return svg;
        } else {
          return svg.replace(/^<svg /, `<svg style="fill: ${this.fill}" `);
        }
      } else {
        return '';
      }
    },
  },
});
