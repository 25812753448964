import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { endOfDay, format } from 'date-fns';
import { i18n } from '@/locales/i18n';
import { IEarning } from '@/typings/interface/IEarning';
import { MAccountNumber } from '@/models/MAccountNumber';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';
import { MSales } from '@/models/MSales';
import { slipRangeList } from '@/utils/outgoing';
import { utIsEmpty } from '@/utils/empty';

const locale = localStorage.getItem('locale');

export class MEarning extends Model implements IEarning {
  static entity: TModel = MODEL.earning;

  static primaryKey = 'Id';

  Id: string;

  AccountNumberID: string;

  BankCashID: number;

  Date: string;

  Description: string;

  GrossAmount: string;

  InvoiceNumber: string;

  SlipNumber: number;

  SlipRange: string;

  Tax: number;

  get asEarningData() {
    const account = this.AccountNumberID;

    const accountNumber = MAccountNumber.query().where((accountNumber) => {
      return Number(accountNumber.AccountingNumber) === Number(this.AccountNumberID);
    }).get();

    const bankCash = this.BankCashID;

    let slip = this.SlipRange;

    slipRangeList.forEach(slipRange => {
      if (slipRange.Id === this.SlipRange) {
        slip = slipRange.Name;
      }
    });

    return {
      accountNumber: this.AccountNumberID,
      accountNumberName: accountNumber[0].Name,
      account: `${account}`,
      bankCash: this.BankCashID,
      bank: `${bankCash}`,
      actions: this.Id,
      description: this.Description,
      slip: `${slip}-${this.SlipNumber}`,
      invoiceNumber: this.InvoiceNumber,
      tax: `${this.Tax} %`,
      date: utIsEmpty(this.Date) ? '' : format(new Date(this.Date), 'yyyy-MM-dd'),
      grossAmount: MSales.formatedPrice(this.GrossAmount),
      taxAmount: MSales.formatedPrice((((Number(this.GrossAmount)) / (100 + Number(this.Tax))) * Number(this.Tax))),
      netAmount: MSales.formatedPrice(((Number(this.GrossAmount)) - (((Number(this.GrossAmount)) / (100 + Number(this.Tax))) * Number(this.Tax)))),
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      AccountNumberID: this.string(''),

      BankCashID: this.string(''),

      Date: this.string(endOfDay(new Date()).toISOString()),

      Description: this.string(i18n(locale).tc('common.property.description')),

      GrossAmount: this.string(''),

      InvoiceNumber: this.string(''),

      SlipNumber: this.string(''),

      SlipRange: this.string(''),

      Tax: this.number(0),
    };
  };

  static async _create(earning: IEarning): Promise<string> {
    const emptyEarning = MEarning.hydrate(earning);

    const { response: { data: response } } = await MEarning.api().post(
      Api.ENDPOINT.earning,
      emptyEarning,
      {
        save: false,
      },
    );

    MEarning.insert({
      data: {
        ...emptyEarning,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static async _fetch(year: string = ''): Promise<any> {
    await MEarning.deleteAll();

    await MEarning.api().get(
      `${Api.ENDPOINT.earning}/date/${encodeURIComponent(year)}`,
    );
  };

  static async _getEarningYears(): Promise<any> {
    const { response: { data: yearList } } = await MEarning.api().get(
      `${Api.ENDPOINT.earning}/years`,
      {
        save: false,
      },
    );

    return yearList;
  };

  static async _getLastSlipNumber(year: string, slip: string): Promise<any> {
    const { response: { data: slipNumber } } = await MEarning.api().get(
      `${Api.ENDPOINT.earning}/slipNumber/${year}/${slip}`,
      {
        save: false,
      },
    );

    return slipNumber;
  };
}
