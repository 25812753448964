export const salesLocationAttribute = {
  alert: {
    delete: 'Deleting the sales location attribute "{attributeName}" will remove it from every' +
      ' sales location.',
  },

  isDefault: 'Attribute is assigned to every sales location',
  isOptional: 'Attribute is optional',
  model: 'Sales Location Attribute | Sales Location Attributes',
  noResult: 'You have not created any sales location attributes yet',
  requirement: 'Requirement',
};
