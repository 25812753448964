import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { EventBus, EventBusEvent } from '@/utils/eventBus';
import { http } from '@/http';
import { i18n } from '@/locales/i18n';
import { ISettings } from '@/typings/interface/ISettings';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';
import { MOrganization } from '@/models/MOrganization';
import { PIC_SIZE } from '@/utils/imageSize';
import { utIsEmpty } from '@/utils/empty';

const locale = localStorage.getItem('locale');

export class MSettings extends Model implements ISettings {
  static entity: TModel = MODEL.settings;

  static primaryKey = 'Id';

  Id: string;

  Bank: string;

  Bic: string;

  Currency: string;

  Generalized: boolean;

  Iban: string;

  InvoiceEndText: string;

  ProductList: string;

  LogoAltText: string;

  Language: string;

  LogoUrl: string;

  StationeryAltText: string;

  StationeryUrl: string;

  StationeryPrint: boolean;

  TermOfPayment: string;

  organizationImageURL(
    imageType: string = 'Logo',
    imageSize: PIC_SIZE = PIC_SIZE.L192,
  ) {
    const imageUrl = (imageType === 'Logo')
      ? 'LogoUrl'
      : 'StationeryUrl';

    if (!this[imageUrl]) {
      return '';
    }

    return `${Api.HOST}/api/${this[imageUrl]}?imageType=${imageType}&size=${imageSize}`;
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      Bank: this.attr(''),

      Bic: this.attr(''),

      Currency: this.attr(''),

      Generalized: this.attr(false),

      InvoiceEndText: this.attr(i18n(locale).tc('invoice.text.invoiceEndText', 1)),

      Iban: this.attr(''),

      ProductList: this.attr('List'),

      Language: this.attr('de'),

      LogoAltText: this.attr(''),

      LogoUrl: this.attr(''),

      StationeryAltText: this.attr(''),

      StationeryUrl: this.attr(''),

      StationeryPrint: this.attr(false),

      TermOfPayment: this.attr('30'),

      UseBankDetails: this.attr(false),
    };
  };

  static async _fetch(): Promise<any> {
    const organization = MOrganization.query().first();

    await MSettings.api().get(
      `${Api.ENDPOINT.organization}/settings/${encodeURIComponent(organization.SettingsId)}`,
    );
  };

  static async _update(settings: MSettings): Promise<any> {
    EventBus.$emit(EventBusEvent.CALL.update);

    await MSettings.api().put(
      `${Api.ENDPOINT.organization}/settings/${encodeURIComponent(settings.Id)}`,
      settings.$toJson(),
    );

    EventBus.$emit(EventBusEvent.CALL.updateEnd);
  };

  static async _deleteImage(imageType: string): Promise<any> {
    const organization = MOrganization.query().first();
    const settings = MSettings.find(organization.SettingsId);

    const imageUrl = (imageType === 'Logo')
      ? 'LogoUrl'
      : 'StationeryUrl';

    const { response: { status } } = await MSettings.api().delete(
      `${Api.HOST}/api/${settings[imageUrl]}/?imageType=${imageType}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );

    if (status === 204) {
      if (imageType === 'Logo') {
        MSettings.update({
          where: organization.SettingsId,
          data: {
            LogoAltText: '',
            LogoUrl: '',
          },
        });
      } else {
        MSettings.update({
          where: organization.SettingsId,
          data: {
            StationeryAltText: '',
            StationeryUrl: '',
          },
        });
      }
    }
  };

  static async _uploadImage(file: File, imageType: string): Promise<any> {
    const organization = MOrganization.query().first();
    const settings = MSettings.find(organization.SettingsId);

    const formData = new FormData();

    formData.append(
      'file',
      file,
      file.name,
    );

    const imageUrl = (imageType === 'Logo')
      ? 'LogoUrl'
      : 'StationeryUrl';

    if (!utIsEmpty(settings[imageUrl])) {
      await MSettings._deleteImage(imageType);
    }

    await MSettings.api().post(
      `${Api.ENDPOINT.organization}/settings/${encodeURIComponent(settings.Id)}/image?imageType=${imageType}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': http.defaults.headers.common.Authorization,
        },
      },
    );
  };
}
