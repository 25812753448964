import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { formatDate } from '@/utils/filters/format';
import { IDeliveryBag } from '@/typings/interface/IDeliveryBag';
import { MCustomer } from '@/models/MCustomer';
import { MCustomerProductPrice } from '@/models/MCustomerProductPrice';
import { MInvoice } from '@/models/MInvoice';
import { MODEL, TModel } from '@/utils/models';
import { Model } from '@vuex-orm/core';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';

export class MDeliveryBag extends Model implements IDeliveryBag {
  static entity: TModel = MODEL.deliveryBag;

  static primaryKey = 'Id';

  Id: string;

  Current: boolean;

  CustomerID: string;

  Date: string;

  DeliveryNumber: string;

  InvoiceID: string;

  Sales: Array<MSales>;

  get asListData() {
    const customer = MCustomer.find(this.CustomerID);

    return {
      customer: customer,
      customerName: customer?.name,
      date: formatDate(this.Date),
      deliveryNumber: this.DeliveryNumber,
      invoice: (this.InvoiceID) ? MInvoice.query().withAllRecursive().find(this.InvoiceID) : null,
      invoiceNumber: (this.InvoiceID) ? MInvoice.find(this.InvoiceID)?.InvoiceNumber : '',
      actions: this.Id,
    };
  }

  get toApi() {
    return {
      Id: this.Id,
      Current: this.Current,
      CustomerID: this.CustomerID,
      Date: this.Date,
      DeliveryNumber: this.DeliveryNumber,
      InvoiceID: this.InvoiceID,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      Current: this.attr(false),

      CustomerID: this.string(''),

      Date: this.string(''),

      DeliveryNumber: this.string(''),

      InvoiceID: this.attr(''),

      Sales: this.hasMany(
        MSales,
        'ShoppingBagID',
      ),
    };
  }

  static async updateCustomer(
    deliveryBagId: string,
    customerId: string,
  ): Promise<any> {
    await MDeliveryBag.update({
      Id: deliveryBagId,
      CustomerID: customerId,
    });

    const deliveryBag = MDeliveryBag.query().withAllRecursive().find(deliveryBagId);

    await MDeliveryBag._update(deliveryBag);

    for (let index = 0; index < deliveryBag.Sales.length; index++) {
      const product = MProduct.find(deliveryBag.Sales[index].ProductID);
      let singlePrice = Number(product.Price);

      const customerPrice = MCustomerProductPrice
        .query()
        .where('CustomerID', customerId)
        .where('ProductID', product.Id)
        .first();

      if (customerPrice) {
        singlePrice = Number(customerPrice.Price);
      }

      const currentSale = deliveryBag.Sales[index];

      const price = currentSale.Quantity * singlePrice;
      let discountPrice = (price / 100 * (100 - Number(currentSale.Discount))).toFixed(2);

      if (currentSale.Flat) {
        discountPrice = currentSale.Price;
      }

      await MSales.update({
        Id: currentSale.Id,
        Price: discountPrice,
        SinglePrice: singlePrice,
      });

      const sale = MSales.find(currentSale.Id);

      await MSales._update(sale);
    }
  };

  static async _create(): Promise<string> {
    const ongoingNumber = await MDeliveryBag._getOngoingNumber();

    const emptyBag = MDeliveryBag.hydrate({
      Current: true,
      DeliveryNumber: ongoingNumber,
      Date: new Date().toISOString(),
    });

    const { response: { data: response } } = await MDeliveryBag.api().post(
      Api.ENDPOINT.deliveryBag,
      emptyBag,
      {
        save: false,
      },
    );

    await MDeliveryBag.insert({
      data: {
        ...emptyBag,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    const deliveryBag = MDeliveryBag.query().withAllRecursive().find(Id);

    MDeliveryBag.delete(Id);

    MDeliveryBag.api().delete(
      `${Api.ENDPOINT.deliveryBag}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );

    deliveryBag.Sales.forEach(sale => {
      MSales._delete(sale.Id);
    });
  };

  static async _fetch(): Promise<any> {
    await MDeliveryBag.api().get(
      `${Api.ENDPOINT.deliveryBag}`,
    );
  };

  static async _getOngoingNumber(): Promise<any> {
    const { response: { data: deliveryNumber } } = await MDeliveryBag.api().get(
      `${Api.ENDPOINT.deliveryBag}/ongoingNumber`,
    );

    return deliveryNumber;
  };

  static _update(deliveryBag): void {
    MDeliveryBag.api().put(
      `${Api.ENDPOINT.deliveryBag}/${encodeURIComponent(deliveryBag.Id)}`,
      deliveryBag.toApi,
      {
        save: false,
      },
    );
  };

  static async _getDeliveryYears(): Promise<any> {
    const { response: { data: yearList } } = await MDeliveryBag.api().get(
      `${Api.ENDPOINT.deliveryBag}/years`,
      {
        save: false,
      },
    );

    return yearList;
  };
}
