export const common = {
  action: {
    add: 'Hinzufügen',
    bon: 'Bon',
    addOption: 'Option hinzufügen',
    cancel: 'Stornieren',
    cancelButton: 'Abbrechen',
    changePassword: 'Passwort ändern',
    delete: 'Löschen',
    done: 'Fertig',
    duplicate: 'Duplizieren',
    edit: 'Bearbeiten',
    exportPdf: 'Pdf Export',
    logout: 'Logout',
    new: 'Neue',
    ok: 'OK',
    pdf: 'Pdf',
    openContextMenu: 'Öffne Menu',
    remove: 'Entfernen',
    replace: 'Ersetzen',
    reversal: 'Storno',
    save: 'Speichern',
    share: 'Teilen',
    upload: 'Datei hochladen',
  },

  error: {
    notSaved: 'Die aktuelle Seite wurde in der Zwischenzeit aktualisiert. Versuchen Sie es nochmal.',
  },

  gender: {
    male: 'männlich',
    femail: 'weiblich',
  },

  layout: {
    grid: 'Grid',
    list: 'Liste',
  },

  property: {
    account: 'Konto',
    address: 'Adresse',
    all: 'Alle',
    amount: 'Anzahl',
    and: 'und',
    breadcrumb: 'Breadcrumb',
    by: 'by',
    connector: 'Connector',
    createdAt: 'Created at',
    date: 'Datum',
    day: 'Tag | Tage',
    deliveryAddress: 'Lieferadresse',
    description: 'Beschreibung',
    eMail: 'Emailadresse',
    empty: 'leer',
    invoiceAddress: 'Rechnungsadresse',
    logo: 'Organization Logo',
    month: 'Monat | Monate',
    name: 'Name',
    no: 'Nein',
    none: 'Nichts',
    now: 'Jetzt',
    of: 'of',
    password: 'Passwort',
    per: 'per',
    piecesAbbreviation: 'Stk | Stk',
    search: 'Suche...',
    send: 'Email senden',
    today: 'Heute',
    typeToSearch: 'Klicke um zu Suchen …',
    untitled: 'Untitled',
    username: 'Username',
    value: 'Wert | Werte',
    viaEmail: 'Via Email',
    week: 'Woche | Wochen',
    workspace: 'Arbeitsplatz',
    year: 'Jahr | Jahre',
    yes: 'Ja',
  },

  required: 'Erforderlich',

  select: {
    title: 'Optionen',

    dropdown: {
      newOption: 'Als neue Option hinzufügen',
      noOptions: 'Keine Option vorhanden',
      noResult: 'Kein Resultat',
      title: 'Wähle eine Option',
    },
  },
};
