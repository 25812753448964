import { accounting } from '@/locales/en/partials/_accounting';
import { accountNumber } from '@/locales/en/partials/_accountNumber';
import { address } from '@/locales/en/partials/_address';
import { area } from '@/locales/en/partials/_area';
import { attribute } from '@/locales/en/partials/_attribute';
import { batch } from '@/locales/en/partials/_batch';
import { category } from '@/locales/en/partials/_category';
import { common } from '@/locales/en/partials/_common';
import { customer } from '@/locales/en/partials/_customer';
import { date } from '@/locales/en/partials/_date';
import { definition } from '@/locales/en/partials/_definition';
import { delivery } from '@/locales/en/partials/_delivery';
import { displayType } from '@/locales/en/partials/_displayType';
import { distributionCenter } from '@/locales/en/partials/_distributionCenter';
import { earning } from '@/locales/en/partials/_earning';
import { entityType } from '@/locales/en/partials/_entityType';
import { filter } from '@/locales/en/partials/_filter';
import { handoff } from '@/locales/en/partials/_handoff';
import { hardware } from '@/locales/en/partials/_hardware';
import { hardwareAttribute } from '@/locales/en/partials/_hardwareAttribute';
import { hardwareType } from '@/locales/en/partials/_hardwareType';
import { image } from '@/locales/en/partials/_image';
import { income } from '@/locales/en/partials/_income';
import { invoice } from '@/locales/en/partials/_invoice';
import { logistic } from '@/locales/en/partials/_logistics';
import { mailing } from '@/locales/en/partials/_mailings';
import { navigation } from '@/locales/en/partials/_navigation';
import { nonHardwareProduct } from '@/locales/en/partials/_nonHardwareProduct';
import { notification } from '@/locales/en/partials/_notification';
import { operator } from '@/locales/en/partials/_operator';
import { organization } from '@/locales/en/partials/_organization';
import { outgoing } from '@/locales/en/partials/_outgoing';
import { product } from '@/locales/en/partials/_product';
import { profile } from '@/locales/en/partials/_profile';
import { reports } from '@/locales/en/partials/_reports';
import { sales } from '@/locales/en/partials/_sales';
import { salesLocationAttribute } from '@/locales/en/partials/_salesLocationAttribute';
import { settings } from '@/locales/en/partials/_settings';
import { shoppingBag } from '@/locales/en/partials/_shoppingBag';
import { success } from '@/locales/en/partials/_success';
import { supplier } from '@/locales/en/partials/_supplier';
import { tax } from '@/locales/en/partials/_tax';
import { user } from '@/locales/en/partials/_user';
import { validation } from '@/locales/en/partials/_validation';
import { visual } from '@/locales/en/partials/_visual';
import { visualMerchandisingGuide } from '@/locales/en/partials/_visualMerchandisingGuide';
import { visualVariant } from '@/locales/en/partials/_visualVariant';

export const en = {
  accounting,

  accountNumber,

  address,

  area,

  attribute,

  batch,

  entityType,

  category,

  common,

  customer,

  date,

  definition,

  delivery,

  displayType,

  earning,

  distributionCenter,

  filter,

  handoff,

  hardware,

  hardwareAttribute,

  hardwareType,

  image,

  income,

  invoice,

  logistic,

  mailing,

  navigation,

  nonHardwareProduct,

  notification,

  operator,

  organization,

  outgoing,

  product,

  profile,

  reports,

  sales,

  salesLocationAttribute,

  settings,

  shoppingBag,

  success,

  supplier,

  tax,

  user,

  validation,

  visual,

  visualMerchandisingGuide,

  visualVariant,
};
