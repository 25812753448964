export const accountNumber = {
  accounting: {
    earnings: 'Earnings',
    expenses: 'Expenses',
  },

  accountType: {
    bank: 'Bank',
    cash: 'Cash',
    effectless: 'Effectless',
    normal: 'Normal',
  },

  model: 'Account Number | Account Numbers',

  alert: {
    delete: 'Do you want to delete the account number "{name}"?',
  },

  noResult: 'No account numbers created yet.',

  property: {
    accounting: 'Accounting',
    accountingNumber: 'Accounting Number',
    accountType: 'Account Type',
    name: 'Description',
    tax: 'Tax',
    taxType: 'Tax Type',
  },

  taxType: {
    icpurchase: 'IC Purchase',
    normal: 'Normal Account',
    nosales: 'No Sales',
    reversecharge: 'Reverse Charge',
  },
};
