import logger from '@/utils/logger';
import { Api } from '@/store/endpoints';
import { http, HTTP } from '@/http';

export const AxiosConfig = {
  /**
   * Default Base URL
   */
  baseURL: Api.HOST,

  /**
   * Default URL
   */
  url: '/',

  /**
   * Default Method
   */
  method: 'get',

  /**
   * Default Headers
   */
  headers: {
    Accept: 'application/json',
    common: {
      Authorization: '',
    },
    'Content-Type': 'application/json',
  },

  /**
   * Default Data
   */
  data: {},

  /**
   * Default Timout
   */
  timeout: 0,

  /**
   * Default With Credentials Flag
   */
  withCredentials: false,

  /**
   * Default Response Type
   */
  responseType: 'json',

  /**
   * Default Response Encoding
   */
  responseEncoding: 'utf8',

  /**
   * Default Validate Status Method
   * @param {number} status
   */
  validateStatus(status) {
    return status >= 200 && status < 300; // default
  },

  /**
   * Default Max Redirects
   */
  maxRedirects: 5,

  /**
   * Default Socket Path
   */
  socketPath: null,

  /**
   * Default Proxy
   */
  proxy: {},

  /**
   * Default on Response
   * @param {object} response
   */
  onResponse({ data: response }) {
    return response;
  },

  /**
   * On 401 Unauthorised
   * @param {object} error
   */
  onUnauthorised(error) {
    logger.error(error);

    if (process.env.NODE_ENV !== 'development') {
      logger.info('Redirect to logout page');

      http.get('/account/userinfo');
    }
  },

  /**
   * On 404 Not Found
   * @param {object} error
   */
  onNotFound(error) {
    logger.error(error);
  },

  /**
   * On 500 Server Error
   * @param {object} error
   */
  onServerError(error) {
    logger.error(error);
  },

  /**
   * On Generic Error
   * @param {object} error
   */
  onGenericError(error) {
    logger.error(error);
  },

  /**
   * On Laravel Validation Error (Or 422 Error).
   * @param {object} error
   */
  onValidationError(error) {
    logger.error(error);
  },

  /**
   * Default on Error
   * @param {object} error
   */
  onError(error) {
    switch (error.response.status) {
      case HTTP.NOT_AUTHORIZED:
        this.onUnauthorised(error);

        break;

      case HTTP.NOT_FOUND:
        this.onNotFound(error);

        break;

      case HTTP.UNPROCESSABLE_ENTITY:
        this.onValidationError(error);

        break;

      case HTTP.INTERNAL_SERVER_ERROR:
        this.onServerError(error);

        break;

      default:
        this.onGenericError(error);

        break;
    }

    return Promise.reject(error);
  },
};
