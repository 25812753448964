import { common } from '@/locales/en/partials/_common';

export const attribute = {
  action: {
    add: `${common.action.add} Attribute`,
    choose: 'Choose Attributes',
  },

  grid: {
    header: 'Attribute Name',
  },

  model: 'Attribute | Attributes',

  noResult: 'No attributes created yet',

  required: 'Attribute is required',

  showOrigin: 'Show Attribute Origins',
};
