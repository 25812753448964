import { ActionContext, ActionTree } from 'vuex';
import { http } from '@/http';
import { IFile } from '@/typings/interface/IFile';
import { IUser } from '@/typings/interface/IUser';
import { MUTATION } from '@/typings/interface/IRootState';
import { RootState } from '@/store';
import { User } from '@/store/user/user.store.definition';

const actions: ActionTree<User.State, RootState> = {
  async [User.ACTION.setProfileImage](
    context: ActionContext<User.State, RootState>,
    payload: {
      fileList: FileList,
    },
  ): Promise<any> {
    context.commit(
      User.MUTATION.loading,
      true,
    );

    const { fileList } = payload;

    const formData = new FormData();

    formData.append(
      'file',
      fileList[0],
    );

    const url = `/api/Identity/${context.state.userID}/profile-image/`;

    const { data: response } = await http.post(url,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const profileImage: IFile = response;

    context.commit(
      User.MUTATION.setProfileImage,
      {
        profileImage,
        url,
      },
    );

    context.commit(
      User.MUTATION.loading,
      false,
    );
  },
};

export { actions };
