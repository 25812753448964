import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { endOfDay, format } from 'date-fns';
import { i18n } from '@/locales/i18n';
import { IOutgoing } from '@/typings/interface/IOutgoing';
import { MAccountNumber } from '@/models/MAccountNumber';
import { MODEL, TModel } from '@/utils/models';
import { Model } from '@vuex-orm/core';
import { slipRangeList } from '@/utils/outgoing';
import { utIsEmpty } from '@/utils/empty';

const locale = localStorage.getItem('locale');

export class MOutgoing extends Model implements IOutgoing {
  static entity: TModel = MODEL.outgoing;

  static primaryKey = 'Id';

  Id: string;

  AccountNumberID: string;

  BankCashID: number;

  Date: string;

  Description: string;

  GrossAmount: string;

  InvoiceNumber: string;

  SlipNumber: number;

  SlipRange: string;

  Tax: number;

  get asJson() {
    const account = MAccountNumber.find(this.AccountNumberID)?.AccountingNumber;

    const accountNumberName = MAccountNumber.find(this.AccountNumberID)?.Name;

    const bankCash = MAccountNumber.find(this.BankCashID)?.AccountingNumber;

    const tax = Number(Number(this.GrossAmount) / (100 + Number(this.Tax)) * Number(this.Tax)).toFixed(2);

    let slip = this.SlipRange;

    slipRangeList.forEach(slipRange => {
      if (slipRange.Id === this.SlipRange) {
        slip = slipRange.Name;
      }
    });

    return {
      account: `${account}`,
      accountNumberName: accountNumberName,
      bank: `${bankCash}`,
      description: this.Description,
      slip: `${slip}-${this.SlipNumber}`,
      invoiceNumber: this.InvoiceNumber,
      tax: `${this.Tax} %`,
      taxNumber: `${tax} €`,
      date: utIsEmpty(this.Date) ? '' : format(new Date(this.Date), 'yyyy-MM-dd'),
      grossAmount: `${this.GrossAmount} €`,
      actions: this.Id,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      AccountNumberID: this.string(''),

      BankCashID: this.string(''),

      Date: this.string(endOfDay(new Date()).toISOString()),

      Description: this.string(i18n(locale).t('common.property.description')),

      GrossAmount: this.string(''),

      InvoiceNumber: this.string(''),

      SlipNumber: this.string(''),

      SlipRange: this.string(''),

      Tax: this.number(0),
    };
  };

  static async _create(): Promise<string> {
    const emptyOutgoing = MOutgoing.hydrate();

    const { response: { data: response } } = await MOutgoing.api().post(
      Api.ENDPOINT.outgoing,
      emptyOutgoing,
      {
        save: false,
      },
    );

    MOutgoing.insert({
      data: {
        ...emptyOutgoing,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    MOutgoing.delete(Id);

    MOutgoing.api().delete(
      `${Api.ENDPOINT.outgoing}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );
  };

  static async _fetch(): Promise<any> {
    await MOutgoing.deleteAll();

    await MOutgoing.api().get(
      `${Api.ENDPOINT.outgoing}`,
    );
  };

  static _update(outgoing): void {
    MOutgoing.api().put(
      `${Api.ENDPOINT.outgoing}/${encodeURIComponent(outgoing.Id)}`,
      outgoing.$toJson(),
      {
        save: false,
      },
    );
  };

  static async _getOutgoingYears(): Promise<any> {
    const { response: { data: yearList } } = await MOutgoing.api().get(
      `${Api.ENDPOINT.outgoing}/years`,
      {
        save: false,
      },
    );

    return yearList;
  };
}
