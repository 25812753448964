export const visualVariant = {
  alert: {
    criteriaUpdate: 'Changing the visual variant criteria will remove all current visual variants.',
  },

  model: 'Visual Variant | Visual Variants',

  property: {
    criteria: 'Visual Variant Criteria',
    pending: 'Variant Pending',
  },
};
