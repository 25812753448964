




























































































































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseContextMenu from '@/components/base/BaseContextMenu.vue';
import BaseFlyOut from '@/components/base/BaseFlyOut.vue';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseLoader from '@/components/base/BaseLoader.vue';
import Navigation from '@/components/Navigation.vue';
import Notifications from '@/components/Notifications.vue';
import uuid4 from 'uuid/v4';
import Vue, { VueConstructor } from 'vue';
import { CONTEXT_NAME } from '@/utils/contextName';
import { EventBusEvent } from '@/utils/eventBus';
import { format } from 'date-fns';
import { IBaseGridActionListItem } from '@/components/base/grid/typings/interface/IBaseGridActionListItem';
import { invoiceRouteEdit } from '@/views/sales/invoice/routes';
import { IOrganization } from '@/typings/interface/IOrganization';
import { MDeliveryBag } from '@/models/MDeliveryBag';
import { MInvoice } from '@/models/MInvoice';
import { MNotification } from '@/models/MNotification';
import { MOrganization } from '@/models/MOrganization';
import { MUser } from '@/models/MUser';
import { NOTIFICATIONS } from '@/utils/notifications';
import {
  organizationTabProfile,
  profileRouteShow,
} from '@/views/profile/routes';
import { utIsEmpty } from '@/utils/empty';

interface IVuexBinding {
  organization: IOrganization,
  organizationIsLoading: boolean,
}

export default (Vue as VueConstructor<Vue & IVuexBinding>).extend({
  components: {
    BaseButtonText,
    BaseContextMenu,
    BaseFlyOut,
    BaseImage,
    BaseLoader,
    Navigation,
    Notifications,
  },

  data() {
    return {
      callLoading: false,
      collapsed: false,
      componentUID: '',
      CONTEXT_NAME,
      darkMode: false,
      isLoading: true,
      modalIsOpen: false,
      organizationTabProfile,
      profileRouteShow,
      scrollingContainerRef: null,
      transitionName: 'fade',
    };
  },

  computed: {
    actionList(): Array<IBaseGridActionListItem> {
      return [
        {
          event: 'navigateToProfile',
          icon: 'customer',
          label: 'profile.model',
          name: 'navigateToProfile',
        },
        {
          event: 'logout',
          icon: {
            iconName: 'sign-out',
            iconFamily: 'fontawesome',
          },
          label: 'profile.logout',
          name: 'logout',
        },
      ];
    },

    appClasses() {
      return {
        'theme--dark': this.darkMode,
        'app--collapsed': this.collapsed,
      };
    },

    contentClassList() {
      return {
        'content--not-scrollable': this.modalIsOpen,
      };
    },

    currentOrganization(): MOrganization {
      return MOrganization.query().first();
    },

    currentUser(): MUser {
      return MUser.query().first();
    },

    isLoggedIn(): boolean {
      if (this.currentUser) {
        return this.currentUser.IsLoggedIn;
      }

      return false;
    },

    loadingClass() {
      return {
        'call--loading': this.callLoading,
      };
    },

    notificationCount(): number {
      return MNotification.query().where('New', true).count();
    },

    notificationList(): Array<any> {
      const notificationList = MNotification.all()
        .filter(notification => notification.New !== false).map(
          notification => notification.asListData,
        );

      notificationList.push({
        class: 'notification--all',
        content: '',
        message: String(this.$tc('notification.property.all')),
        new: false,
        type: '',
        path: '/notification-list',
        actions: '',
      });

      return notificationList;
    },

    profileImageIsPresent(): boolean {
      if (this.currentUser) {
        if (!utIsEmpty(this.currentUser.ProfileImageID)) {
          return true;
        }
      }

      return false;
    },

    profileImageURL(): string {
      if (this.currentUser) {
        return this.currentUser.userImageURL();
      }

      return '';
    },
  },

  watch: {
    async isLoading(): Promise<any> {
      await this.$nextTick();

      this.scrollingContainerRef = this.$refs.content;
    },
  },

  methods: {
    async checkForDeliveryInvoicesToCreate(): Promise<any> {
      let month = Number(format(new Date(), 'M'));

      if (month === 1) {
        month = 12;
      } else {
        month -= 1;
      }

      const deliveryBagList = MDeliveryBag
        .query()
        .withAllRecursive()
        .where('InvoiceID', '')
        .where('Date', value => {
          return (new Date(value).getMonth() + 1) === month;
        })
        .orderBy('CustomerID')
        .orderBy('Date', 'asc')
        .get();

      const groupedDeliveries = {};

      deliveryBagList.forEach(deliveryBag => {
        if (groupedDeliveries[`${deliveryBag.CustomerID}`] === undefined) {
          groupedDeliveries[`${deliveryBag.CustomerID}`] = [
            deliveryBag,
          ];
        } else {
          groupedDeliveries[`${deliveryBag.CustomerID}`].push(
            deliveryBag,
          );
        }
      });

      const groupedCustomerDeliveries = Object.values(groupedDeliveries);

      let ongoingNumber = Number(await MInvoice._getOngoingNumber());

      let index = 0;

      for (const groupedCustomerDelivery of groupedCustomerDeliveries) {
        const invoice = await MInvoice._createDeliveryInvoice(
            groupedCustomerDelivery as Array<MDeliveryBag>,
            '',
            ongoingNumber);

        ongoingNumber++;

        await MNotification._create({
          Id: uuid4(),
          Content: invoice.Id,
          Message: this.$t('invoice.messages.newDeliveryInvoice', {
            invoiceNumber: invoice.asListData.invoiceNumber,
          }),
          New: true,
          Path: `/sales/invoice/delivery-invoice/${invoice.Id}`,
          Type: 'invoice',
        });

        const interval = (index + 1) * 300;

        setTimeout(
          () => {
            this.$bus.$emit(
              EventBusEvent.NOTIFICATION.sent,
              this.$t('invoice.messages.newDeliveryInvoice', {
                invoiceNumber: invoice.asListData.invoiceNumber,
              }),
            );
          },
          interval,
        );

        index++;
      }

      /* await Promise.all(groupedCustomerDeliveries.map(
        async(groupedCustomerDelivery: Array<MDeliveryBag>, index: number) => {
          const invoice = await MInvoice._createDeliveryInvoice(
            groupedCustomerDelivery,
            '',
            ongoingNumber);

          await MNotification._create({
            Id: uuid4(),
            Content: invoice.Id,
            Message: this.$t('invoice.messages.newDeliveryInvoice', {
              invoiceNumber: invoice.asListData.invoiceNumber,
            }),
            New: true,
            Path: `/sales/invoice/delivery-invoice/${invoice.Id}`,
            Type: 'invoice',
          });

          const interval = (index + 1) * 300;

          setTimeout(
            () => {
              this.$bus.$emit(
                EventBusEvent.NOTIFICATION.sent,
                this.$t('invoice.messages.newDeliveryInvoice', {
                  invoiceNumber: invoice.asListData.invoiceNumber,
                }),
              );
            },
            interval,
          );
        })); */
    },

    collapse(): void {
      this.collapsed = !this.collapsed;
    },

    getRoute(notification): any {
      if (notification.type === NOTIFICATIONS.INVOICE) {
        return {
          name: invoiceRouteEdit(this.$i18n.locale).name,

          params: {
            invoiceID: notification.content,
          },
        };
      }

      return {
        path: notification.path,
      };
    },

    navigateToProfile(): void {
      this.$router.push({
        name: organizationTabProfile(this.$i18n.locale).name,
      });
    },

    profileAction(event): void {
      if (event === 'navigateToProfile') {
        this.navigateToProfile();
      }

      if (event === 'logout') {
        this.$router.push({
          path: '/account/logout',
        });
      }
    },

    updateNotification(notification): void {
      if (notification.class !== 'notification--all') {
        MNotification.update({
          where: notification.actions,
          data: {
            New: false,
          },
        });

        const newNotification = MNotification.find(notification.actions);

        MNotification._update(newNotification);
      }

      this.$router.push(this.getRoute(notification));
    },
  },

  async created(): Promise<any> {
    // TODO: more user friendly way to switch themes
    if (process.env.VUE_APP_VADER_DID_NOTHING_WRONG) {
      this.darkMode = true;
    }
  },

  async beforeMount(): Promise<any> {
    const navigation = localStorage.getItem('navigation');

    if (navigation === 'collapsed') {
      this.collapsed = true;
    }

    this.$bus.$on(
      EventBusEvent.HTTP.abort,
      async() => {
        await this.$store.dispatch('abort');
      },
    );

    this.$bus.$on(
      EventBusEvent.HTTP.error,
      async(payload: any) => {
        await this.$store.dispatch('error', payload);
      },
    );

    this.$bus.$on(
      EventBusEvent.HTTP.info,
      async(payload: any) => {
        await this.$store.dispatch('info', payload);
      },
    );

    this.$bus.$on(
      EventBusEvent.MODAL.closed,
      () => {
        this.modalIsOpen = false;
      },
    );

    this.$bus.$on(
      EventBusEvent.MODAL.opened,
      () => {
        this.modalIsOpen = true;
      },
    );

    this.$bus.$on(
      EventBusEvent.CALL.update,
      () => {
        this.callLoading = true;
      },
    );

    this.$bus.$on(
      EventBusEvent.CALL.updateEnd,
      () => {
        this.callLoading = false;
      },
    );

    await this.checkForDeliveryInvoicesToCreate();
  },

  beforeDestroy(): void {
    this.$bus.$off(EventBusEvent.HTTP.abort);

    this.$bus.$off(EventBusEvent.HTTP.error);

    this.$bus.$off(EventBusEvent.HTTP.info);

    this.$bus.$off(EventBusEvent.MODAL.closed);

    this.$bus.$off(EventBusEvent.MODAL.opened);

    this.$bus.$off(EventBusEvent.CALL.update);

    this.$bus.$off(EventBusEvent.CALL.updateEnd);
  },
});
