import { common } from '@/locales/en/partials/_common';

export const area = {
  action: {
    add: `${common.action.add} Area`,
    addChild: `${common.action.add} Sub-Area`,
    new: `${common.action.new} Area`,
    newChild: `${common.action.new} Sub-Area`,
  },

  message: {
    delete: 'Deleting the area "{areaName}" will remove it from every sales location and hardware.',
    empty: 'There are no areas in your organization yet.',
  },

  model: 'Area | Areas',

  noResult: 'You have not created any areas yet',
};
