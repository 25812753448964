export const shoppingBag = {
  action: {
    add: 'Zum Warenkorb hinzufügen',
    bon: 'Bon',
    new: 'Neuer Warenkorb',
    pay: 'Zahlen',
    pdf: 'PDF',
    quickPrint: 'Schnelldruck',
  },

  alert: {
    delete: 'Willst du wirklich den Warenkorb löschen?',
  },

  cashDesk: 'Kassa',

  model: 'Warenkorb | Warenkörbe',

  noResult: 'Es wurden noch keine Warenkörbe erstellt',

  property: {
    absolute: 'Absolut',
    additional: 'Zusätzlicher Text',
    article: 'Service/Artikel',
    chargeNumber: 'Chargennumber',
    clear: 'Leeren',
    entity: 'Einheit',
    given: 'Gegeben',
    productName: 'Produktname',
    return: 'Zurück',
    selectCustomer: 'Wähle einen Kunden',
    total: 'Total',
    quantity: 'Anzahl',
  },

  shortProp: {
    discount: 'Rabatt',
    price: 'Preis',
    quantity: '#',
    tax: '%',
  },
};
