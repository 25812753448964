import { IFile } from '@/typings/interface/IFile';
import { IUser } from '@/typings/interface/IUser';
import { MutationTree } from 'vuex';
import { User } from '@/store/user/user.store.definition';

const mutations = <MutationTree<User.State>>{
  [User.MUTATION.setProfileImage](
    state: User.State,
    payload: {
      profileImage: IFile,
      url: string,
    },
  ) {
    const {
      profileImage,
      url,
    } = payload;

    state.profileImageID = url + profileImage.ID;

    state.profileImageName = profileImage.name;
  },

  [User.MUTATION.loading](
    state: User.State,
    loading: boolean,
  ) {
    state.loading = loading;
  },
};

export { mutations };
