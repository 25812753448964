import { i18n } from '@/locales/i18n';
import { IRouteConfig } from '@/typings/interface/IRouteConfig';
import { namespace } from '@/store/index.definition';
import { Router } from '@/utils/router';

const Show = () => import(/* webpackChunkName: "success" */ './SuccessShow.vue');

/* PAGES */
export function successRouteShow(locale): IRouteConfig {
  return {
    children: [],
    component: Show,
    icon: {
      iconName: 'earningList',
      iconFamily: '',
    },
    meta: {
      label: String(i18n(locale).tc('success.model', 2)),
      module: namespace.success,
      type: Router.ROUTE_TYPE.PAGE,
    },
    name: 'success',
    navItem: true,
    path: '/reports/success',
    props: {
      showModal: false,
    },
  };
};
