export const hardwareAttribute = {
  alert: {
    delete: 'Deleting the hardware attribute "{attributeName}" will remove it from every hardware and hardware type.',
  },

  message: {
    helperText: 'Define the hardware supporting the product.',
  },

  model: 'Hardware Attribute | Hardware Attributes',

};
