import { IUserOrganization } from '@/typings/interface/IUserOrganization';

export namespace User {
  export interface State {
    accessToken: string,
    activeOrganization: object,
    email: string,
    fullName: string,
    isLoggedIn: boolean,
    loading: boolean,
    mainCountry: object,
    mainOrganization: object,
    profileImageID: string,
    profileImageName: string,
    userID: string,
    userInfoOrganizationList: Array<IUserOrganization>,
    userName: string,
  }

  export enum ACTION {
    changePassword = 'USER__CHANGE_PASSWORD',
    loadUser = 'USER__LOAD_USER',
    setProfileImage = 'USER__SET_PROFILE_IMAGE',
  }

  export enum MUTATION {
    loading = 'USER__LOADING',
    set = 'USER__SET',
    setProfileImage = 'USER__SET_PROFIL',
  }

  export enum GETTER {
    accessToken = 'USER__GET_ACCESS_TOKEN',
    activeOrganization = 'USER__GET_ACTIVE_ORGANIZATION',
    isLoggedIn = 'USER__GET_IS_LOGGED_IN',
    mainOrganization = 'USER__GET_MAIN_ORGANIZATION',
    profileImageURL = 'USER__GET_PROFILE_IMAGE_URL',
  }
}
