import { common } from '@/locales/en/partials/_common';

export const shoppingBag = {
  action: {
    add: `${common.action.add} to Shopping Bag`,
    bon: 'Bon',
    new: `${common.action.new} Shopping Bag`,
    pay: 'Payment',
    pdf: 'PDF',
    quickPrint: 'Quick Print',
  },

  alert: {
    delete: 'Do you want to delete this Shopping Bag?',
  },

  cashDesk: 'Cash Desk',

  model: 'Shopping Bag | Shopping Bags',

  noResult: 'You have not created any charges yet',

  property: {
    absolute: 'Absolute',
    additional: 'Additional Text',
    article: 'Service/Article',
    chargeNumber: 'Charge Number',
    clear: 'Clear',
    entity: 'Entity',
    given: 'Given',
    productName: 'Product Name',
    return: 'Return',
    selectCustomer: 'Select a Customer',
    total: 'Total',
    quantity: 'Quantity',
  },

  shortProp: {
    discount: 'Disc',
    price: 'Price',
    quantity: 'Qty',
    tax: 'VAT',
  },
};
