export enum OPERATOR {
  EQUAL = 0,
  NOT_EQUAL = 1,
  GREATER_THAN = 2,
  LESS_THAN = 3,
}

export enum OPERATOR_NUMBER {
  EQUAL = OPERATOR.EQUAL,
  NOT_EQUAL = OPERATOR.NOT_EQUAL,
  GREATER_THAN = OPERATOR.GREATER_THAN,
  LESS_THAN = OPERATOR.LESS_THAN,
}

export enum OPERATOR_SELECT {
  EQUAL = OPERATOR.EQUAL,
  NOT_EQUAL = OPERATOR.NOT_EQUAL,
}

export enum OPERATOR_TEXT {
  EQUAL = OPERATOR.EQUAL,
  NOT_EQUAL = OPERATOR.NOT_EQUAL,
}
