export namespace Api {
  export const HOST: string = process.env.VUE_APP_WEB_API_URL;

  export const VERSION = {
    default: '',
    v01: '',
    v02: '',
  };

  type TEndpoint = 'accountNumber' |
    'batch' |
    'category' |
    'charge' |
    'customer' |
    'earning' |
    'invoice' |
    'deliveryBag' |
    'mail' |
    'notification' |
    'tax' |
    'product' |
    'organization' |
    'outgoing' |
    'sales' |
    'shoppingBag' |
    'user';

  export const ENDPOINT: Record<TEndpoint, string> = {
    accountNumber: '',
    batch: '',
    category: '',
    charge: '',
    customer: '',
    deliveryBag: '',
    earning: '',
    invoice: '',
    mail: '',
    notification: '',
    tax: '',
    product: '',
    organization: '',
    outgoing: '',
    sales: '',
    shoppingBag: '',
    user: '',
  };

  export const setEndpoints = (organizationID: string): void => {
    Api.VERSION.default = `/api/${organizationID}`;

    Api.VERSION.v01 = `/api/${organizationID}`;

    Api.ENDPOINT.accountNumber = `${Api.VERSION.default}/account-number`;

    Api.ENDPOINT.batch = `${Api.VERSION.default}/batch-list`;

    Api.ENDPOINT.category = `${Api.VERSION.default}/category`;

    Api.ENDPOINT.charge = `${Api.VERSION.default}/charge`;

    Api.ENDPOINT.customer = `${Api.VERSION.default}/customer`;

    Api.ENDPOINT.earning = `${Api.VERSION.default}/earnings`;

    Api.ENDPOINT.invoice = `${Api.VERSION.default}/invoice`;

    Api.ENDPOINT.deliveryBag = `${Api.VERSION.default}/delivery-bag`;

    Api.ENDPOINT.mail = `${Api.VERSION.default}/send-mail`;

    Api.ENDPOINT.notification = `${Api.VERSION.default}/notifications`;

    Api.ENDPOINT.tax = `${Api.VERSION.default}/tax`;

    Api.ENDPOINT.product = `${Api.VERSION.default}/product`;

    Api.ENDPOINT.organization = Api.VERSION.default;

    Api.ENDPOINT.outgoing = `${Api.VERSION.default}/outgoing`;

    Api.ENDPOINT.sales = `${Api.VERSION.default}/sales`;

    Api.ENDPOINT.shoppingBag = `${Api.VERSION.default}/shopping-bag`;

    Api.ENDPOINT.user = '/account';
  };
}
