export const common = {
  action: {
    add: 'Add',
    bon: 'Bon',
    addOption: 'Add Option',
    cancel: 'Cancel',
    cancelButton: 'Cancel',
    changePassword: 'Change Password',
    delete: 'Delete',
    done: 'Done',
    duplicate: 'Duplicate',
    edit: 'Edit',
    exportPdf: 'Pdf Export',
    logout: 'Logout',
    new: 'New',
    ok: 'OK',
    pdf: 'Pdf',
    openContextMenu: 'Open Menu',
    rankHigher: 'Rank Higher',
    rankLower: 'Rank Lower',
    remove: 'Remove',
    replace: 'Replace',
    reversal: 'Reversal',
    save: 'Save',
    share: 'Share',
    upload: 'Upload File',
  },

  error: {
    notSaved: 'The page you were on has been updated in the meantime. Your changes have been lost. Please try again.',
  },

  gender: {
    male: 'male',
    female: 'female',
  },

  layout: {
    grid: 'Grid',
    list: 'List',
  },

  link: {
    copy: 'Copy Link',
    create: 'Create Link',
    delete: 'Delete Link',
    renew: 'Renew Link',
    visit: 'Visit Link',
  },

  property: {
    account: 'Account',
    address: 'Address',
    all: 'All',
    amount: 'Amount',
    and: 'and',
    breadcrumb: 'Breadcrumb',
    by: 'by',
    connector: 'Connector',
    createdAt: 'Created at',
    date: 'Date',
    day: 'Day | Days',
    deliveryAddress: 'Delivery Address',
    description: 'Description',
    eMail: 'Email address',
    empty: 'empty',
    invoiceAddress: 'Invoice Address',
    logo: 'Organization Logo',
    month: 'Month | Months',
    name: 'Name',
    no: 'No',
    none: 'None',
    now: 'Now',
    of: 'of',
    password: 'Password',
    per: 'per',
    piecesAbbreviation: 'pc. | pcs.',
    pointInTime: 'Point in Time',
    search: 'Search...',
    send: 'Send Mail',
    specific: 'Specific',
    today: 'Today',
    typeToSearch: 'Type to search …',
    untitled: 'Untitled',
    username: 'Username',
    value: 'Value | Values',
    viaEmail: 'Via Email',
    week: 'Week | Weeks',
    workspace: 'Workspace',
    year: 'Year | Years',
    yes: 'Yes',
  },

  required: 'Required',

  select: {
    title: 'Options',

    dropdown: {
      newOption: 'Add this as new option',
      noOptions: 'No option added yet',
      noResult: 'No result found',
      title: 'Select an option',
    },
  },
};
