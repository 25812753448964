export const tax = {
  model: 'Steuersatz | Steuersätze',

  action: {
    add: 'Steuersatz hinzufügen',
    delete: 'Steuersatz löschen',
  },

  alert: {
    delete: 'Willst du den Steuersatz "{taxName}" wirklich löschen?',
  },

  message: {
    noResult: 'Keine Steuersätze gefunden',
  },

  property: {
    name: 'Steuersatz Name',
  },
};
