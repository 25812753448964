export const organization = {
  model: 'Organisation | Organisationen',

  property: {
    address: 'Adresse',
    email: 'Email Adresse',
    endText: 'Rechnungsschlusstext',
    generalized: 'Pauschaliert',
    images: 'Organisationsbild | Organisationsbilder',
    invoiceAddress: 'Rechnungsdresse',
    language: 'Sprache',
    logo: 'Logo',
    name: 'Organisationsname',
    owner: 'Inhaber',
    phone: 'Telefon',
    productGrid: 'Produkt Layout',
    sameAsAddress: 'Gleich wie Organisationsadresse',
    stationery: 'Briefpapier',
    stationeryUsePrint: 'Briefpapier drucken',
    uid: 'UID Nummer',
    website: 'Website',
  },

  tab: {
    organization: 'Organisation',
    profile: 'Benutzerkonto',
    settings: 'Einstellungen',
  },
};
