import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { IShoppingBag } from '@/typings/interface/IShoppingBag';
import { MCustomer } from '@/models/MCustomer';
import { MCustomerProductPrice } from '@/models/MCustomerProductPrice';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';
import { MProduct } from '@/models/MProduct';
import { MSales } from '@/models/MSales';

export class MShoppingBag extends Model implements IShoppingBag {
  static entity: TModel = MODEL.shoppingBag;

  static primaryKey = 'Id';

  Id: string;

  AdditionalText: string;

  CustomerID: string;

  Current: boolean;

  InvoiceID: string;

  UseCustomerPriceList: boolean;

  Sales: Array<MSales>;

  get asListData() {
    const customer = MCustomer.find(this.CustomerID);

    return {
      additionalText: this.AdditionalText,
      customer: customer,
      actions: this.Id,
    };
  }

  get toApi() {
    return {
      Id: this.Id,
      AdditionalText: this.AdditionalText,
      CustomerID: this.CustomerID,
      InvoiceID: this.InvoiceID,
      UseCustomerPriceList: this.UseCustomerPriceList,
    };
  }

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      AdditionalText: this.string(''),

      CustomerID: this.string(''),

      Current: this.boolean(false),

      InvoiceID: this.attr(''),

      UseCustomerPriceList: this.boolean(false),

      Sales: this.hasMany(
        MSales,
        'ShoppingBagID',
      ),
    };
  }

  static async updateCustomer(
    shoppingBagId: string,
    customerId: string,
    useCustomerPriceList: boolean,
  ): Promise<any> {
    await MShoppingBag.update({
      Id: shoppingBagId,
      CustomerID: customerId,
      UseCustomerPriceList: useCustomerPriceList,
    });

    const shoppingBag = MShoppingBag.query().withAllRecursive().find(shoppingBagId);

    await MShoppingBag._update(shoppingBag);

    for (let index = 0; index < shoppingBag.Sales.length; index++) {
      const product = MProduct.find(shoppingBag.Sales[index].ProductID);
      let singlePrice = Number(product.Price);

      if (useCustomerPriceList) {
        const customerPrice = MCustomerProductPrice
          .query()
          .where('CustomerID', customerId)
          .where('ProductID', product.Id)
          .first();

        if (customerPrice) {
          singlePrice = Number(customerPrice.Price);
        }
      }

      const currentSale = shoppingBag.Sales[index];

      const price = currentSale.Quantity * singlePrice;
      let discountPrice = (price / 100 * (100 - Number(currentSale.Discount))).toFixed(2);

      if (currentSale.Flat) {
        discountPrice = currentSale.Price;
      }

      await MSales.update({
        Id: currentSale.Id,
        Price: discountPrice,
        SinglePrice: singlePrice,
      });

      const sale = MSales.find(currentSale.Id);

      await MSales._update(sale);
    }
  };

  static async _create(): Promise<string> {
    const emptyBag = MShoppingBag.hydrate(
      {
        Current: true,
      },
    );

    const { response: { data: response } } = await MShoppingBag.api().post(
      Api.ENDPOINT.shoppingBag,
      emptyBag,
      {
        save: false,
      },
    );

    await MShoppingBag.insert({
      data: {
        ...emptyBag,
        Id: response.Id,
      },
    });

    return response.Id;
  };

  static _delete(Id: string): void {
    const shoppingBag = MShoppingBag.query().withAllRecursive().find(Id);

    MShoppingBag.delete(Id);

    MShoppingBag.api().delete(
      `${Api.ENDPOINT.shoppingBag}/${encodeURIComponent(Id)}`,
      {
        delete: null, // This just fixes "TypeError: can't convert null to object"
      },
    );

    shoppingBag.Sales.forEach(sale => {
      MSales._delete(sale.Id);
    });
  };

  static async _fetch(): Promise<any> {
    await MShoppingBag.api().get(
      Api.ENDPOINT.shoppingBag,
    );
  };

  static _update(shoppingBag): void {
    MShoppingBag.api().put(
      `${Api.ENDPOINT.shoppingBag}/${encodeURIComponent(shoppingBag.Id)}`,
      shoppingBag.toApi,
      {
        save: false,
      },
    );
  };
}
