





























import BaseImagePlaceholder from '@/components/base/BaseImagePlaceholder.vue';
import Vue, { VueConstructor } from 'vue';
import { directiveLazyLoad } from '@/directives/lazyLoad';
import { http } from '@/http';
import { mixinComponentUID } from '@/components/mixins/componentUID';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'BaseImage',

  components: {
    BaseImagePlaceholder,
  },

  directives: {
    lazyload: directiveLazyLoad,
  },

  mixins: [
    mixinComponentUID,
  ],

  props: {
    alt: {
      type: String,
      required: true,
    },

    height: {
      type: String,
      default: null,
    },

    objectFit: {
      type: String,
      default: 'none',
    },

    source: {
      type: String,
      default: null,
    },

    width: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      url: '',
    };
  },

  computed: {
    classList(): Object {
      return {
        [`image--${this.objectFit}`]: this.objectFit !== 'none',
      };
    },

    renderPlaceholder(): Boolean {
      return !!this.$slots['placeholder'] || utIsEmpty(this.source);
    },
  },

  methods: {
    async sourceUrl(): Promise<any> {
      // Fetch the image.

      const response = await fetch(this.source, {
        headers: {
          Authorization: http.defaults.headers.common.Authorization,
        },
      });

      // Create an object URL from the data.
      const blob = await response.blob();

      this.url = URL.createObjectURL(blob);
    },
  },

  mounted() {
    this.sourceUrl();
  },
});
