import uuid4 from 'uuid/v4';
import { Api } from '@/store/endpoints';
import { ICustomerProductPrice } from '@/typings/interface/ICustomer';
import { Model } from '@vuex-orm/core';
import { MODEL, TModel } from '@/utils/models';

export class MCustomerProductPrice extends Model implements ICustomerProductPrice {
  static entity: TModel = MODEL.customerProductPrice;

  static primaryKey = 'Id';

  Id: string;

  CustomerID: string;

  Price: string;

  ProductID: string;

  static fields() {
    return {
      Id: this.attr(() => uuid4()),

      CustomerID: this.string(''),

      Price: this.string(''),

      ProductID: this.string(''),
    };
  }

  static async _fetch(customerID): Promise<any> {
    await MCustomerProductPrice.api().get(
      `${Api.ENDPOINT.customer}/${encodeURIComponent(customerID)}/price-list`,
      {
        dataTransformer: (response) => {
          response.data.map((customerProductPrice: MCustomerProductPrice) => {
            customerProductPrice.CustomerID = customerID;
          });

          return response.data;
        },
      },
    );
  };

  static _update(customerProductPrice): void {
    let price = null;

    if (customerProductPrice.Price !== '0.00' && customerProductPrice.Price !== '0') {
      price = customerProductPrice.Price;
    }

    MCustomerProductPrice.api().put(
      `${Api.ENDPOINT.customer}/${encodeURIComponent(customerProductPrice.CustomerID)}/price-list/${encodeURIComponent(customerProductPrice.Id)}`,
      {
        ...customerProductPrice.$toJson(),
        Price: price,
      },
      {
        save: false,
      },
    );

    if (customerProductPrice.Price === '0.00' || customerProductPrice.Price === '0') {
      MCustomerProductPrice.delete(customerProductPrice.Id);
    }
  };
}
