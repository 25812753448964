















































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseImage from '@/components/base/BaseImage.vue';
import BaseSvgIcon from '@/components/base/BaseSvgIcon.vue';
import {
  BREADCRUMB_TYPE,
  IBreadCrumb,
  IBreadCrumbImage,
  TBreadCrumb,
} from '@/typings/interface/IBreadCrumb';
import { VISUAL_SIZE } from '@/utils/imageSize';

export default {
  name: 'BreadCrumbs',

  components: {
    BaseButtonText,
    BaseImage,
    BaseSvgIcon,
  },

  data() {
    return {
      BREADCRUMB_TYPE,
      localBreadCrumbList: [] as Array<TBreadCrumb>,
    };
  },

  watch: {
    '$route'() {
      if (this.$route.params.updateBreadCrumbs) {
        this.setBreadCrumbList();
      }
    },
  },

  methods: {
    isBreadCrumbDefault(breadCrumb: IBreadCrumb): boolean {
      return breadCrumb.type !== BREADCRUMB_TYPE.IMAGE && breadCrumb.type !== BREADCRUMB_TYPE.OVERRIDE;
    },

    isBreadCrumbImage(breadCrumb: IBreadCrumb): boolean {
      return breadCrumb.type === BREADCRUMB_TYPE.IMAGE;
    },

    isBreadCrumbOverride(breadCrumb: IBreadCrumb): boolean {
      return breadCrumb.type === BREADCRUMB_TYPE.OVERRIDE;
    },

    redirect(breadCrumb, index): void {
      if (breadCrumb.link && index < (this.localBreadCrumbList.length - 1)) {
        this.$router.push(breadCrumb.link);

        this.setBreadCrumbList();
      }
    },

    setBreadCrumbList(): void {
      this.localBreadCrumbList = [];

      this.$route.matched.map(
        (route) => {
          const routeBreadCrumb = route.meta.breadCrumb;

          const breadCrumbType = (routeBreadCrumb)
            ? route.meta.breadCrumb.type
            : BREADCRUMB_TYPE.PLAIN;

          let breadCrumb: IBreadCrumb;
          let breadCrumbImage: IBreadCrumbImage;
          let breadCrumbRoute;
          let breadCrumbGetter;
          let currentImage;
          let link;

          switch (breadCrumbType) {
            case BREADCRUMB_TYPE.OVERRIDE:
              breadCrumbRoute = routeBreadCrumb.route(this.$store, this.$route);

              if (route.meta.label) {
                this.localBreadCrumbList.push(
                  {
                    label: breadCrumbRoute.label,
                    name: breadCrumbRoute.route.name,
                    params: breadCrumbRoute.params,
                    type: BREADCRUMB_TYPE.OVERRIDE,
                    query: breadCrumbRoute.query,
                  },
                );

                this.localBreadCrumbList.push(
                  {
                    label: route.meta.label,
                    type: BREADCRUMB_TYPE.PLAIN,
                  },
                );
              } else {
                this.localBreadCrumbList.push(
                  {
                    label: breadCrumbRoute.label,
                    params: breadCrumbRoute.params,
                    type: BREADCRUMB_TYPE.OVERRIDE,
                    query: breadCrumbRoute.query,
                  },
                );
              }

              break;

            case BREADCRUMB_TYPE.DISABLED:
              break;

            case BREADCRUMB_TYPE.GETTER:
              breadCrumbGetter = routeBreadCrumb.getter(this.$store, this.$route);

              if (Array.isArray(breadCrumbGetter)) {
                breadCrumbGetter.forEach(
                  element => {
                    const link = (element?.link)
                      ? element.link
                      : routeBreadCrumb.getLink(element.ID);

                    this.localBreadCrumbList.push(
                      {
                        label: element.name,
                        link: link,
                        type: BREADCRUMB_TYPE.PLAIN,
                      },
                    );

                    if (this.localBreadCrumbList.length > 5) {
                      this.localBreadCrumbList.shift();
                    }
                  },
                );

                if (route.meta.label) {
                  this.localBreadCrumbList.push(
                    {
                      label: route.meta.label,
                      type: BREADCRUMB_TYPE.PLAIN,
                    },
                  );
                }
              } else {
                const link = (route.meta.breadCrumb.routeBack)
                  ? route.meta.breadCrumb.routeBack(this.$route)
                  : route.path;

                const breadCrumb: IBreadCrumb = {
                  label: breadCrumbGetter,
                  link: link,
                  type: BREADCRUMB_TYPE.GETTER,
                };

                this.localBreadCrumbList.push(breadCrumb);
              }

              break;

            case BREADCRUMB_TYPE.PLAIN:
              link = route.path;

              if (routeBreadCrumb) {
                link = (this.$route.meta.breadCrumb.routeBack)
                  ? this.$route.meta.breadCrumb.routeBack(this.$route)
                  : route.path;
              }

              breadCrumb = {
                label: route.meta.label,
                link: link,
                type: BREADCRUMB_TYPE.PLAIN,
              };

              this.localBreadCrumbList.push(breadCrumb);

              break;

            case BREADCRUMB_TYPE.IMAGE:
              currentImage = routeBreadCrumb.getter(this.$store, this.$route);

              breadCrumbImage = {
                label: currentImage.name,
                source: currentImage.imageURL(VISUAL_SIZE.L384),
                type: routeBreadCrumb.type,
              };

              this.localBreadCrumbList.push(breadCrumbImage);

              break;
          }
        },
      );
    },
  },

  mounted(): void {
    this.setBreadCrumbList();
  },
};
