import { common } from '@/locales/en/partials/_common';

export const product = {
  model: 'Product | Products',

  action: {
    add: `${common.action.add} Product`,
    delete: `${common.action.delete} Product`,
  },

  alert: {
    delete: 'Do you want to delete the product "{productName}"?',
  },

  message: {
    empty: 'No products are yet to be produced in this campaign.',
  },

  property: {
    articleNumber: 'Article Number',
    category: 'Category',
    ean: 'EAN/BarCode',
    unit: 'Unit',
    name: 'Product Name',
    pending: 'Image pending...',
    price: 'Price',
    tax: 'Tax',
  },
};
